<template>
    <div v-if="app.self.isOdw">
        <div class="selector-filters-header">
            <h3>Période</h3>
        </div>

        <div class="sectionPeriodes">
            <!--Sélection des périodes-->
            <select class="selectPeriod form-control"
                    id="selectYear"
                    v-model="app.calculatorFilters.selectedYear"
                    @change="onAnneeClick">
                <!--selecteur des années qui call onAnneeClick à chaque changement-->
                <option class="optionPeriod" v-bind:value="-1">Choix de l'ann&eacute;e</option>
                <option v-for="periode in this.annees" v-bind:value="periode.year" :key="periode.year">{{periode.year}}</option>
            </select>
            <select class="selectPeriod form-control"
                    id="selectPeriode"
                    :disabled="app.calculatorFilters.selectedYear === -1"
                    v-model="app.calculatorFilters.idPeriode"
                    @change="onPeriodeClick">
                <!--Selecteur des périodes qui call onPeriodeClick à chaque changement-->
                <option class="optionPeriod"
                        :disabled="app.calculatorFilters.selectedYears != -1"
                        :value="-1">Choix de la p&eacute;riode</option>
                <option v-for="periode in this.periodes" v-bind:value="periode.id" :key="periode.id">{{periode.label}}</option>
            </select>
        </div>
    </div>
</template>

<script>
    import { store, mutations } from "../store/store";
    import { gammeMutations } from "../store/gamme";
    import { dataFinanceMutations } from "../store/dataFinance";
    import { fetchApi } from "@/utils";

    export default {
        name: "PeriodFilter",
        data() {
            return {
                app: store
            }
        },
        computed: {
            annees() {
                return store.perioFourni.annees;
            },
            periodes() {
                return store.perioFourni.periodes;
            }
        },
        methods: {
            onAnneeClick() { //Changement d'année
                store.calculatorFilters.idPeriode = -1 //La période revient à -1
                if (store.calculatorFilters.selectedYear != -1) { //Si l'année selectionnée est != de -1 ("choix de l'année" à pour index -1)
                    mutations.setPeriodesByYear(store.calculatorFilters.selectedYear).then(() => {
                        this.onPeriodeClick();
                    }); //On met en place les périodes
                }
            },
            onPeriodeClick() {
                fetchApi("fournisseur/authorizations?supplierGuid=" + store.self.guid + "&idPeriod=" + store.calculatorFilters.idPeriode, {}, "sdc")
                    .then(response => {
                        store.self.contractStatus = response.data.ContractStatusText;
                        store.self.periodAccess = response.data.MapsPeriodAccess;
                        store.self.contractPeriodLabel = response.data.ContractPeriodLabel;
                    })
                if (store.calculatorFilters.idPeriode != -1) { //si la période selectionnée est différente de "Choix de la période"
                    gammeMutations.initGammes().then(() => { //On initie les gammes
                        dataFinanceMutations.loadDataFinance(); //On rafraichi les chiffres
                    });
                } else {
                    store.gammes = []; //Sinon on enlève les gammes
                }
            },
        }
    }
</script>

<style scoped>
    .sectionPeriodes {
        width: 100%;
        vertical-align: middle;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;
    }

    .sectionPeriodes select {
        width: 100%;
    }
</style>