<template>
    <div class="">
        <div class="header">
            <h2>Part de marché</h2>
        </div>
        <div class="content indicateurs pdm-charts d-flex justify-content-around mt-2 mb-4">
            <div>
                <div class="graph">
                    <canvas width="250" id="graph-dor-pdm"></canvas>
                </div>
                <div class="text-center"><strong>CA Potentiel sur mon Marché</strong></div>
            </div>
            <div>
                <div class="graph">
                    <canvas width="250" id="graph-dor-pdm-pf"></canvas>
                </div>
                <div class="text-center"><strong>CA Potentiel sur mes Clients</strong></div>
            </div>
        </div>
        <div class="selector-filters-header">
            <h2>Panier Moyen <font-awesome-icon class="ml-1" icon="info-circle"
                    style="font-size: 0.6em; vertical-align: top;" @click="displayInfosPM = true" /></h2>
        </div>
        <div class="pm-charts mt-2 mb-4">
            <div class="graph">
                <canvas height="350" id="graph-dor-pm"></canvas>
            </div>
        </div>
        <Modal :open="displayInfosPM" @close="displayInfosPM = false" title="Informations">
            <dl>
                <dt>CA marché</dt>
                <dd>Mon CA + CA concurrents</dd>
                <dt>Panier Moyen marché sur mes clients: </dt>
                <dd>CA marché sur mes clients / Nb PDV clients facturés par le marché (cumulés)</dd>
                <dt>Panier Moyen Concurrents sur mes clients</dt>
                <dd>CA concurrents sur mes clients / Nb PDV clients facturés par mes concurrents (cumulés)</dd>
                <dt>Mon Panier Moyen</dt>
                <dd>Mon CA / Nb de mes clients</dd>
                <dt>Panier Moyen PDV sur mes clients</dt>
                <dd>CA marché sur mes clients / Nb de mes clients</dd>
            </dl>
        </Modal>
    </div>
</template>

<script>
import { store } from "../store/store";
import Chart from "chart.js/auto";

import { getRandomColor } from "@/utils";

import Modal from "@/components/Modal";

export default {
    name: 'DorIndicateurs',
    components: { Modal },
    data() {
        return {
            app: store,
            displayInfosPM: false,
            charts: [],
            mounted: false
        }
    },
    mounted() {
        this.mounted = true;
        this.initCharts();
    },
    watch: {
        indicateurs() {
            this.initCharts();
        }
    },
    computed: {
        indicateurs() {
            return this.app.dorDatas.indicateurs;
        },
        pdmData() {
            const data = store.dorDatas.indicateurs;
            if (!data) return {};
            const labelFilter = function (context) {
                return Number(context.raw).toLocaleString("fr-FR", { minimumFractionDigits: 0 }) + " €";
            };
            return {
                data: {
                    labels: ['CA Potentiel', 'Mon CA'],
                    datasets: [
                        {
                            label: "Chiffres d'affaire",
                            data: [data.CaTotal - data.CaSelf, data.CaSelf],
                            backgroundColor: ['#dee2e6', getRandomColor(1)]
                        },
                    ],
                    styling: {
                        borderWidth: 0
                    }
                },
                tooltipLabelCallback: labelFilter,
                type: "doughnut",
            };
        },
        pdmPFData() {
            const data = store.dorDatas.indicateurs;
            if (!data) return {};
            const labelFilter = function (context) {
                return Number(context.raw).toLocaleString("fr-FR", { minimumFractionDigits: 0 }) + " €";
            };
            return {
                data: {
                    labels: ['CA Potentiel sur mes clients', 'Mon CA'],
                    datasets: [
                        {
                            label: "Chiffres d'affaire",
                            data: [data.CaTotalPF - data.CaSelf, data.CaSelf],
                            backgroundColor: ['#dee2e6', getRandomColor(1)]
                        },
                    ],
                    styling: {
                        borderWidth: 0
                    }
                },
                tooltipLabelCallback: labelFilter,
                type: "doughnut",
            };
        },
        pmData() {
            const data = store.dorDatas.indicateurs;
            if (!data || Object.keys(data) <= 0) return {};
            const labelFilter = function (context) {
                return Number(context.raw).toLocaleString("fr-FR", { minimumFractionDigits: 0 }) + " €";
            };
            return {
                data: {
                    labels: ['Panier Moyen Marché sur mes clients',
                        'Panier Moyen Concurrents sur mes clients',
                        'Mon Panier Moyen',
                        'Panier Moyen PDV sur mes clients'],
                    datasets: [
                        {
                            label: "Panier moyen",
                            data: [data.PmMarchePF || 0, data.PmExceptSelfPF || 0, data.PmSelf || 0, data.PmPdvPF || 0],
                            backgroundColor: [getRandomColor(4), '#dee2e6', getRandomColor(1), getRandomColor(9)]
                        },
                    ],
                    styling: {
                        borderWidth: 0
                    }
                },
                tooltipLabelCallback: labelFilter,
                scales: {
                    y: {
                        ticks: {
                            beginAtZero: true,
                            callback: (value) => {
                                return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value || 0);
                            }
                        }
                    }
                },
                type: "bar"
            };

        }
    },
    methods: {
        initCharts() {
            if (!this.mounted) return;
            this.initChart("graph-dor-pdm", this.pdmData);
            this.initChart("graph-dor-pdm-pf", this.pdmPFData);
            this.initChart("graph-dor-pm", this.pmData);
        },
        // eslint-disable-next-line
        initChart(chartId, { type, data, tooltipLabelCallback, scales = {}, displayLegend = false }) {
            const canvas = document.getElementById(chartId);
            if (this.charts[chartId]) {
                this.charts[chartId].destroy();
                this.charts[chartId] = null;
            }
            if (!data) return;

            this.charts[chartId] = new Chart(canvas, {
                type: type,
                data,
                options: {
                    scales,
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: displayLegend,
                            position: "bottom"
                        },
                        tooltip: {
                            callbacks: {
                                label: tooltipLabelCallback
                            }
                        }
                    }
                }

            });
        }
    },
}
</script>

