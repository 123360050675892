<template>
    <div id="classification" v-if="app.self.isOdw">
        <h3>Classification</h3>
        <div id="classification-choice">
            <label>
                <input type="radio"
                       :value="-1"
                       v-model="app.calculatorFilters.isSpecialiste"
                       class="radio-cmem"
                       @change="classificationChanged()" />
                Tous
            </label>
            <label>
                <input type="radio"
                       :value="0"
                       v-model="app.calculatorFilters.isSpecialiste"
                       class="radio-cmem"
                       @change="classificationChanged()" />
                Généraliste
            </label>
            <label>
                <input type="radio"
                       :value="1"
                       v-model="app.calculatorFilters.isSpecialiste"
                       class="radio-cmem"
                       @change="classificationChanged()" />
                Spécialiste (CA > 35% dans une famille)
            </label>
        </div>
    </div>
</template>

<script>
    import { store } from "../store/store";
    import { dataFinanceMutations } from "../store/dataFinance";

    export default {
        name: "ClassificationFilter",
        props: {
            open: Boolean
        },
        data() {
            return {
                app: store
            }
        },
        methods: {
            classificationChanged() {
                if (store.calculatorFilters.isSpecialiste == 1) {
                    const frsFamily = store.families.find(f => f.Label == store.self.family)
                    if (frsFamily)
                        store.calculatorFilters.families = [frsFamily.Id]
                } else {
                    store.calculatorFilters.families = []
                }
                dataFinanceMutations.loadDataFinance();
            },
        }
    }
</script>

<style scoped>
    #classification-choice {
        display: flex;
        flex-direction: column;
    }
</style>