<template>
    <div class="app cdm-container">
        <MenuCdm></MenuCdm>
        <router-view></router-view>
    </div>
</template>

<script>
    import { store, mutations } from "../components/store/store";
    import MenuCdm from '../components/cdm/MenuCdm.vue';


    export default {
        name: 'ChefDeMarche',
        components: {MenuCdm},
        data: function() {
            return {
                store,
                mutations,
            };
        },
        methods: {
        },
        mounted() {
            if (this.store.self.isAdmin === 0) {
                const login = new URL(window.location.href).searchParams.get("login");
                const email = new URL(window.location.href).searchParams.get("email");
                const autologinHash = new URL(window.location.href).searchParams.get("autologinHash");
                window.location.href = `/?autologinHash=${autologinHash}&login=${login}&email=${email}&odw=1`;
            }
        }
    }
</script>

<style>
    .cdm-container {
        height: auto;
        display: flex;
        overflow-y: hidden;
    }
    h1 {
        color: var(--primary);
        text-align: center;
        font-size: 2.5rem;
    }
</style>