<script>
    import { store, mutations } from "./store/store";

    export default {
        name: 'fournisseurPopup',
        data() {
            return {
                app: store,
                listFrs: [],
                search: "",
            };
        },
        mounted() {
            this.listFrs = this.app.dataFournisseurs;
        },
        methods: {
            switchDisplayPopup() {
                mutations.switchFilters("messagePopupFournisseur");
            },

            // Au changement de valeur 
            onChange() {
                this.$emit("input", this.search);

                this.filterResults();
            },

            // Moteur de recherche
            filterResults() {
                if (this.search.length == 0) {
                    this.listFrs = [];
                }
                else {
                    this.listFrs = this.app.dataFournisseurs.filter(frs => {
                        let reg = new RegExp(`.*${this.search}.*`, "i");
                        return reg.test(frs.Name);
                    })

                }
            },

            // Substitution
            substitute(guid) {
                store.calculatorFilters.idPeriode = -1;
                mutations.substitute(guid);
                this.switchDisplayPopup();
            }
        }
    };
</script>

<template>
    <div class="popup" id="supplierPopup" v-if="app.dataDisplay.popupMessageFournisseur">
        <div class="popup-content">
            <h3>Sélection Fournisseur</h3>
            <hr />
            <a href="#" class="close" v-on:click="switchDisplayPopup()">X</a>
            <form class="form-inline">
                <input class="form-control" 
                       type="search" 
                       placeholder="Rechercher un fournisseur..."
                       v-model="search"
                       @input="onChange"
                       >
            </form>
            <ul class="list-group">
                <li class="list-group-item" v-for="fournisseur in listFrs" :key="fournisseur.Guid" v-on:click="substitute(fournisseur.Guid)">{{ fournisseur.Name }} <span class="float-right badge badge-success" v-if="app.self.isAdmin">{{ fournisseur.Guid }}</span></li>
            </ul>
        </div>
    </div>

</template>

<style>
    #supplierPopup.popup {
        position: absolute;
        z-index: 999999;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(155, 155, 155, 0.8);
    }

        #supplierPopup.popup > .popup-content {
            margin: 70px auto;
            padding: 20px;
            background: #fff;
            border-radius: 5px;
            width: 40%;
            height: 70%;
            position: relative;
        }

            #supplierPopup.popup > .popup-content > h3 {
                color: var(--primary);
                font-weight: 100;
            }

            #supplierPopup.popup > .popup-content > p {
                padding: 0;
            }

            #supplierPopup.popup > .popup-content > a.close {
                position: absolute;
                top: 20px;
                right: 30px;
                transition: all 200ms;
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
                color: #000000;
            }

            #supplierPopup.popup > .popup-content > form > input {
                width: 100%;
                margin-bottom: 10px;
            }


            #supplierPopup.popup > .popup-content > ul {
                overflow-y: scroll;
                scrollbar-width: none;
                height: 75%;
            }

                #supplierPopup.popup > .popup-content > ul > li {
                    cursor: pointer;
                }
</style>
