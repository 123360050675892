<template>
    <div v-if="app.self.isOdw && app.calculatorFilters.isClient == 1">
        <h3>Recherche autour d'un point</h3>
        <div class="input-container">
            <button class="btn btn-cmem" @click="toggleSelectPointDistance" v-if="!app.calculatorFilters.pdvDistance.selecting">Rechercher autour d'un point</button>
            <button class="btn btn-warning" @click="toggleSelectPointDistance" v-else>Annuler la recherche</button>
            <div class="mt-3 input-group">
                <input class="form-control"
                       type="number"
                       min="1"
                       @change="updateDistance"
                       v-show="app.calculatorFilters.pdvDistance.latlng != null"
                       v-model="app.calculatorFilters.pdvDistance.distance" />
                <div class="input-group-append">
                    <button class="btn btn-danger"
                            @click="deleteDistanceFilter"
                            v-show="app.calculatorFilters.pdvDistance.latlng != null">
                        X
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from "../store/store";
    import { dataFinanceMutations } from "../store/dataFinance";

    export default {
        name: "PdvDistanceFilter",
        data() {
            return {
                app: store,
            }
        },
        methods: {
            updateDistance() {
                dataFinanceMutations.loadDataFinance()
            },
            deleteDistanceFilter() {
                store.calculatorFilters.pdvDistance.latlng = null;
                dataFinanceMutations.loadDataFinance()
            },
            toggleSelectPointDistance() {
                store.calculatorFilters.pdvDistance.selecting = !store.calculatorFilters.pdvDistance.selecting;
                if (store.calculatorFilters.pdvDistance.selecting)
                    document.getElementById("map").classList.add("selecting")
                else
                    document.getElementById("map").classList.remove("selecting")
            },
        }
    }
</script>

<style scoped>
    .input-container {
    }
</style>
