import { createRouter, createWebHistory } from "vue-router";

// Maps
import Home from "./pages/Maps/Home";
import Maps from "./pages/Maps/Maps";
import Results from "./pages/Maps/Results";
import Detailed from "./pages/Maps/Detailed";
import External from "./pages/Maps/External";

// Results
import ResultsTable from "./components/Results/Table";
import ResultsCharts from "./components/Results/Graphs";

// Detailed
import AdvsDor from "./components/Detailed/AdvsDor";
import DorIndicateurs from "./components/Detailed/DorIndicateurs.vue";
import DorAnalyseClient from "./components/Detailed/DorAnalyseClient.vue";
//import DorSpecialistesAnalyse from "./components/Detailed/DorSpecialistesAnalyse.vue";
import DorEvolutionCa from "./components/Detailed/DorEvolutionCa";
import DorDistribution from "./components/Detailed/DorDistribution";

//CDM
import ChefDeMarche from "./pages/ChefDeMarche.vue";
import Frs from "./components/cdm/Frs.vue";
import Association from "./components/cdm/Association.vue";
import Dashboard from "./components/cdm/Dashboard.vue";
import ValidationAcces from "./components/cdm/ValidationAcces.vue";
import Users from "./components/cdm/Users.vue";

// External
import Prosperence from "./components/External/Prosperence/Prosperence.vue";

const routes = [
  {
    id: 1,
    title: "CMEM Maps",
    path: "/",
    icone: "fas fa-map-marker-alt",
    component: Home,
    name: "Maps",
    children: [
      {
        path: "",
        name: "Carto",
        component: Maps,
      },
      {
        path: "zones/:indicator",
        name: "Zones",
        component: Maps,
      },
      {
        path: "results",
        //name: "Resultats",
        component: Results,
        children: [
          {
            path: "",
            name: "results-table",
            component: ResultsTable,
          },
          {
            path: "charts",
            name: "results-charts",
            component: ResultsCharts,
          },
        ],
      },
      {
        path: "external",
        //name: "External",
        component: External,
        children: [
          {
            path: "marche",
            name: "marche",
            component: Prosperence,
          },
        ],
      },
      {
        path: "detailed",
        component: Detailed,
        children: [
          {
            path: "",
            name: "detailed-advs",
            component: AdvsDor,
          },
          {
            path: "indicators",
            name: "detailed-indicators",
            component: DorIndicateurs,
          },
          {
            path: "client",
            name: "detailed-client",
            component: DorAnalyseClient,
          },
          //{
          //    path: "specialist",
          //    name: "detailed-spe",
          //    component: DorSpecialistesAnalyse
          //},
          {
            path: "evolution",
            name: "detailed-evo",
            component: DorEvolutionCa,
          },
          {
            path: "distribution",
            name: "detailed-distrib",
            component: DorDistribution,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: "Panel chef de marché",
    path: "/chefdemarche",
    icone: "fas fa-tachometer-alt",
    component: ChefDeMarche,
    name: "CDM",
    children: [
      {
        id: 5,
        title: "Dashboard",
        path: "",
        name: "CDM",
        icone: "fas fa-tachometer-alt",
        component: Dashboard,
      },
      {
        id: 3,
        title: "Etats fournisseurs",
        path: "etatfrs",
        name: "EtatFrs",
        icone: "fas fa-file-invoice",
        component: Frs,
      },
      {
        id: 4,
        title: "Associations manuelles",
        path: "association",
        name: "Associations",
        icone: "fas fa-layer-group",
        component: Association,
      },
      {
        id: 6,
        title: "Validation accès",
        path: "validation",
        name: "Validation",
        icone: "fas fa-tasks",
        component: ValidationAcces,
      },
      {
        id: 7,
        title: "Mes Utilisateurs",
        path: "users",
        name: "users",
        icone: "fas fa-user",
        component: Users,
      },
    ],
  },
];

const router = createRouter({ routes, history: createWebHistory() });

export default router;
