import { store } from "./store";
import { fetchApi } from "@/utils";

export const tranchesCaMutations = {
    // Récupère en base les tranches de CA
    initTranchesCa() {
        fetchApi("trancheCa", {}, "maps")
            .then(response => {
                store.dataAdherents.tranchesCa = response.data.map(tranche => ({
                    Id: tranche.Id,
                    Libelle: tranche.Libelle,
                }))
            })
    },
};