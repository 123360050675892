////import Vue from "vue";
import { store } from "./store";
import L from 'leaflet';
import { fetchApi } from "@/utils";

export const advMutations = {

    // Initie les commerciaux 
    initAdvs() {
        //this.resetMapAdv();
        return new Promise((resolve, reject) => {
            fetchApi("commerciaux?guid=" + store.self.guid, {}, "sdc")
                .then(response => {
                    store.self.commerciaux = this.populateAdvs(response.data);
                    resolve();
                })
                .catch(reject)
        });
    },


    // Met les commerciaux au bon format
    populateAdvs(commerciaux) {
        var returnArray = [];
        commerciaux.forEach(commercial => {
            var newCommercial = {
                Id: commercial.Id,
                Civility: commercial.Civility,
                FirstName: store.isDemo ? "Jean" : commercial.FirstName,
                LastName: store.isDemo ? "Dupont" : commercial.LastName,
                PhoneNumber: store.isDemo ? "06 00 00 00 00" : commercial.PhoneNumber,
                FaxNumber: store.isDemo ? "06 00 00 00 00" : commercial.FaxNumber, 
                PortablePhoneNumber: store.isDemo ? "06 00 00 00 00" : commercial.PortablePhoneNumber,
                Email: store.isDemo ? "abcdefg@hij.fr" : commercial.Email,
                Departements: commercial.Departements,
                DepartementsToDisplay: [],
            };
            newCommercial.Departements.forEach(departement => {
                var depFound = store.dataMap.departements.find(e => e.IdNational == departement);
                if (depFound != undefined) {
                    newCommercial.DepartementsToDisplay.push(
                        {
                            IdNational: depFound.IdNational,
                            Data: L.geoJson(
                                depFound.RawData,
                                {
                                    style: store.mapParams.departementAdvs
                                }
                            )
                        }
                    )
                }
            });

            returnArray.push(newCommercial);
        });

        return returnArray;
    },
}