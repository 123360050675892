<template>
    <div id="commerciaux" v-if="(app.self.isSupplier == 1 || app.self.isAdmin == 1) && app.calculatorFilters.isClient == 1 && (!app.self.isOdw || !app.self.isDepFilter)">
        <h3>Mes commerciaux</h3>
        <div>
            <multiselect v-model="selectedAdvs"
                         :options="app.self.commerciaux.filter(c => c.DepartementsToDisplay.length > 0)"
                         trackBy="Id"
                         :custom-label="c => (c.LastName + ' ' + c.FirstName + ' - ' + c.Email)"
                         :closeOnSelect="false"
                         :searchable="true"
                         :multiple="true"
                         placeholder="Choisissez un/des commerciaux"
                         selectLabel="Appuyez sur Entrée pour sélectionner"
                         selectedLabel="Sélectionné"
                         deselectLabel="Appuyez sur Entrée pour enlever"
                         @open="selectOpen = true"
                         @close="selectOpen = false; advsChanged();"
                         @input="advsChanged"></multiselect>
        </div>
    </div>
</template>

<script>
    import { store } from "../store/store"
    import { pointDeVenteMutations } from "../store/pointDeVente";
    import { dataFinanceMutations } from "../store/dataFinance";

    export default {
        name: "AdvsFilter",
        props: {
            open: Boolean
        },
        data() {
            return {
                app: store,
                selectedAdvs: [],
                selectOpen: false,
            }
        },
        watch: {
            "app.calculatorFilters.advs": {
                handler() {
                    this.selectedAdvs = this.app.calculatorFilters.advs
                        .map(advId => {
                            return this.app.self.commerciaux.find(c => c.Id == advId);
                        })
                        .filter(c => c != undefined);
                }
            }
        },
        methods: {
            compareAdvs() {
                let equal = true;
                if (this.app.calculatorFilters.advs.length !== this.selectedAdvs.length) return false;
                for (let i = 0; i < this.selectedAdvs.length; i++) {
                    if (this.app.calculatorFilters.advs.indexOf(this.selectedAdvs[i].Id) === -1) equal = false;
                }
                return equal;
            },
            advsChanged() {
                if (this.compareAdvs() || this.selectOpen) return;
                this.app.calculatorFilters.advs = this.selectedAdvs.map(c => c.Id);

                store.breadcrumb.Region = {
                    Nom: "Zone des commerciaux",
                    Id: -1,
                    Bounds: ""
                };

                store.breadcrumb.Departement = {
                    Nom: "",
                    Id: 0,
                    Bounds: null
                };

                // on centre la carte sur la France
                store.dataMap.map.flyTo([46.7890461, 1.7448433], 6);

                if (store.self.isOdw == 1) {
                    dataFinanceMutations.loadDataFinance();
                }
                else {
                    pointDeVenteMutations.loadPdv();
                }
            },
        }
    }
</script>

<style></style>