<script>

    export default {
        name: 'Modal',
        props: {
            open: Boolean,
            title: String
        },
    };
</script>

<template>
    <div class="popup" v-if="open" @click.self="$emit('close')">
        <div class="popup-content">
            <h3>{{title}}</h3>
            <hr />
            <a href="#" class="close" v-on:click="$emit('close')">X</a>
            <slot></slot>
        </div>
    </div>
</template>

<style scoped>
    .popup {
        position: fixed;
        z-index: 999999;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(155, 155, 155, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }

        .popup > .popup-content {
            margin: 70px auto;
            padding: 20px;
            background: #fff;
            border-radius: 5px;
            width: 30%;
            max-height: 70%;
            position: relative;
            overflow-y: auto;
        }
        .popup.md-modal > .popup-content {
            width: 40%;
        }

        .popup.lg-modal > .popup-content {
            height: 80%;
            width: 55%;
            min-width: 1100px;
        }

            .popup > .popup-content > h3 {
                color: var(--primary);
                font-weight: 100;
            }

            .popup > .popup-content > p {
                padding: 0;
            }

            .popup > .popup-content > a.close {
                position: absolute;
                top: 20px;
                right: 30px;
                transition: all 200ms;
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
                color: #000000;
            }
</style>
