// import Vue from "vue";
import { VueApp as Vue } from "@/main.js";
import { store, mutations } from "./components/store/store";

const cache = {};

export function fetchApiWithCache(url, params = {}, scope = "none") {
  return new Promise((resolve, reject) => {
    const key = url + JSON.stringify(params);
    if (cache[key]) {
      return resolve(cache[key]);
    }

    return fetchApi(url, params, scope)
      .then((response) => {
        cache[key] = response;
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fetchApi(url, params = {}, scope = "none") {
  return new Promise((resolve, reject) => {
    mutations.displayLoader(true);
    Vue.axios
      .get(store.rootApi + url, {
        headers: { Authorization: "Bearer " + store.self.token },
        params,
      })
      .then((response) => {
        mutations.updateLogs(response, scope);
        resolve(response);
      })
      .catch((err) => {
        console.error(err);
        if (err.response && err.response.status === 401)
          document.location.reload();
        var log = {
          status: err.response.status,
          statusText: err,
          config: {
            method: "get",
            timeout: 0,
            url: store.rootApi + url,
          },
        };

        mutations.updateLogs(log, scope);
        reject(err);
      })
      .finally(() => {
        mutations.displayLoader(false);
      });
  });
}

export function postApi(url, formData, scope = "none") {
  return new Promise((resolve, reject) => {
    mutations.displayLoader(true);
    Vue.axios
      .post(store.rootApi + url, formData, {
        headers: { Authorization: "Bearer " + store.self.token },
      })
      .then((response) => {
        mutations.updateLogs(response, scope);
        resolve(response);
      })
      .catch((err) => {
        console.error(err);
        if (err.response && err.response.status === 401)
          document.location.reload();
        var log = {
          status: err.response.status,
          statusText: err,
          config: {
            method: "post",
            timeout: 0,
            url: store.rootApi + url,
          },
        };

        mutations.updateLogs(log, scope);
        reject(err);
      })
      .finally(() => {
        mutations.displayLoader(false);
      });
  });
}

// Retourne une couleur parmi le tableau ci-dessous
export function getRandomColor(i) {
  //var colors = ["#e8f5e9", "#c8e6c9", "#a5d6a7", "#81c784", "#66bb6a", "#4caf50", "#43a047", "#388e3c", "#2e7d32", "#1b5e20", "#b9f6ca", "#69f0ae", "#00e676", "#00c853", "#dce775", "#d4e157", "#cddc39"];
  var colors = [
    "#50E3C2",
    "#63CEB6",
    "#82ECD4",
    "#A4F6E4",
    "#BCFFF0",
    "#C9F8ED",
    "#E4FFF9",
    "#00C599",
    "#5CAA98",
    "#7CC4B4",
    "#95BBB2",
    "#B1CEC7",
  ];
  return colors[i % colors.length];
}
//Renvoie un tableau de couleurs aléatoires
export function getRandomColorTab() {
  //var colors = ["#e8f5e9", "#c8e6c9", "#a5d6a7", "#81c784", "#66bb6a", "#4caf50", "#43a047", "#388e3c", "#2e7d32", "#1b5e20", "#b9f6ca", "#69f0ae", "#00e676", "#00c853", "#dce775", "#d4e157", "#cddc39"];
  var colors = [
    "#50E3C2",
    "#63CEB6",
    "#82ECD4",
    "#A4F6E4",
    "#BCFFF0",
    "#C9F8ED",
    "#E4FFF9",
    "#00C599",
    "#5CAA98",
    "#7CC4B4",
    "#95BBB2",
    "#B1CEC7",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
}
