<template>
    <div class="frs-container">
        <div class="frs-datas">
            <h1>Validation accès ODW3</h1>
        </div>
        <div class="card shadow mb-4">
            <!-- Card Header - Dropdown -->
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h5>Liste des fournisseurs</h5>
            </div>
            <!-- Card Body -->
            <div class="pt-2 pb-2 pl-5 pr-5">
                <input type="text" class="form-control mb-5" v-model="frsFilter" placeholder="Nom du fournisseur"/>
                <div v-for="row of sortedFrsInfos" :key="row.FrsGuid" class="d-flex justify-content-between mb-4 frs-info">
                    <div v-if="frsData(row.FrsGuid)" class="d-flex flex-column">
                        <h3>{{frsData(row.FrsGuid) ? frsData(row.FrsGuid).Name : ""}}</h3>
                        <div v-if="row.IsLnL">Est Ligne et Lumières</div>
                        <div v-if="row.State">
                            L'accès à l'ODW3 a été <span v-if="!row.State">dé-</span>validé par <strong>{{row.UserFirstName}} {{row.UserLastName}}</strong> ({{row.UserEmail}})<br />
                            Le {{formatNb(new Date(row.Date).getDate())}}/{{formatNb(new Date(row.Date).getMonth() + 1)}}/{{formatNb(new Date(row.Date).getFullYear())}}
                            <br />
                            Commentaire: {{row.Comment}}
                        </div>
                        <div v-else>
                            L'accès à l'ODW3 n'a pas encore été validé
                        </div>
                    </div>
                    <div>
                        <button v-if="row.State" class="btn btn-danger" @click="selection(row)">Dé-valider</button>
                        <button v-else class="btn btn-success" @click="selection(row)">Valider</button>
                    </div>
                </div>
            </div>
        </div>
        <Modal :title="validated ? 'Dé-validation de l\'accès ODW3 au fournisseur' : 'Validation de l\'accès ODW3 au fournisseur'" :open="modalOpen" @close="modalOpen = false">
            <form @submit.prevent="validateSupplier" class="form">
                <div v-if="selectedFrs && !selectedFrs.State">
                    <div class="form-row">
                        <div class="form-group col">
                            <input type="text" v-model="validationContactUserFirstname" class="form-control" placeholder="Prénom" required />
                            <small v-if="selectedFrs && selectedFrs.Contact && selectedFrs.Contact.UserFirstname">Prénom par défaut: <strong>{{selectedFrs.Contact.UserFirstname}}</strong></small>
                        </div>
                        <div class="form-group col">
                            <input type="text" v-model="validationContactUserLastname" class="form-control" placeholder="Nom" required />
                            <small v-if="selectedFrs && selectedFrs.Contact && selectedFrs.Contact.UserFirstname">Nom par défaut: <strong>{{selectedFrs.Contact.UserLastname}}</strong></small>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="email" v-model="validationContactEmail" class="form-control" placeholder="Mail de contact" required />
                        <small v-if="selectedFrs && selectedFrs.Contact && selectedFrs.Contact.Mail">Mail par défaut: <strong>{{selectedFrs.Contact.Mail}}</strong></small>
                    </div>
                    <hr />
                </div>
                <textarea rows="5" v-model="validationComment" placeholder="Commentaire (facultatif)" class="form-control mb-4"></textarea>
                <input type="submit" value="Sauvegarder" class="form-control btn btn-success" />
            </form>
        </Modal>
    </div>
</template>

<script>
    import { store, mutations } from "../store/store";
    import { fetchApi } from "@/utils";
    //import { cdmValidationMutations } from "../store/cdmValidation";

    import Modal from "../Modal.vue";

    export default {
        name: 'ValidationAcces',
        components: {
            Modal
        },
        data() {
            return {
                store,
                mutations,
                frsInfos: [],
                modalOpen: false,
                selectedFrs: null,
                validationComment: "",
                validationContactEmail: "",
                validationContactUserFirstname: "",
                validationContactUserLastname: "",
                frsFilter: ""
            };
        },
        watch: {
            "store.calculatorFilters.idPeriode": function () {
                this.getValidationData();
            }
        },
        methods: {
            formatNb(n) {
                return (n < 10 ? "0" : "") + n;
            },
            frsData(frsGuid) {
                return this.store.dataFournisseurs.find((frs) => frs.Guid === frsGuid);
            },
            selection(row) {
                if (row !== null) {
                    this.selectedFrs = row;
                    this.validationComment = row.Comment;
                    this.validationContactEmail = row.ContactEmail ? row.ContactEmail : (row.Contact && row.Contact.Mail ? row.Contact.Mail : "");
                    this.validationContactUserFirstname = row.Contact && row.Contact.UserFirstname ? row.Contact.UserFirstname : "";
                    this.validationContactUserLastname = row.Contact && row.Contact.UserLastname ? row.Contact.UserLastname : "";
                    this.modalOpen = true;
                }
            },

            getValidationData() {
                const path = "cdm/validation/all";
                fetchApi(`${path}?idPeriode=${this.store.calculatorFilters.idPeriode}`)
                    .then(res => {
                        const { data } = res;
                        this.frsInfos = data;
                    })
            },

            // Valide ou dé-valide un fournisseur
            validateSupplier() {
                if (this.selectedFrs) {
                const path = "cdm/validation/update";
                const status = this.selectedFrs.State ? 0 : 1; // Toggle the state
                    fetchApi(`${path}?frsGuid=${this.selectedFrs.FrsGuid}&status=${status}&comment=${this.validationComment}&email=${this.validationContactEmail}&firstname=${this.validationContactUserFirstname}&lastname=${this.validationContactUserLastname}&idPeriode=${this.store.calculatorFilters.idPeriode}`)
                        .then(() => {
                            this.getValidationData();
                        })
                        .finally(() => {
                            this.modalOpen = false;
                        });
                }
            }
        },
        computed: {
            validated() {
                return this.selectedFrs && this.selectedFrs.State;
            },
            sortedFrsInfos() {
                if (this.store.dataFournisseurs.length > 0) {
                    return this.frsInfos
                        .slice()
                        .sort((a, b) => {
                            let frsA = this.frsData(a.FrsGuid)
                            let frsB = this.frsData(b.FrsGuid)
                            if (!frsA || !frsB) return 0;
                            return frsA.Name < frsB.Name ? -1 : 1
                        })
                        .filter(row => {
                        if (this.frsFilter.length === 0) return true;

                        let reg = new RegExp(`.*${this.frsFilter}.*`, "i");
                        return reg.test(this.frsData(row.FrsGuid).Name);
                    });
                }
                return this.frsInfos
            }
        },
        mounted() {
            this.mutations.initFournisseurs().then(() => {
                this.getValidationData();
            });
        }
    }
</script>

<style scoped>

    span {
        padding: 0;
    }

    h1 {
        color: var(--primary);
        text-align: center;
        font-size: 2.5rem;
        font-weight: 100;
    }

    h3 {
        margin: 0;
        margin-bottom: 4px;
        font-size: 1.3em;
    }

    h5 {
        color: var(--primary);
        font-weight: 100;
        margin: 0;
    }

    .frs-container {
        padding: 12px 42px;
        width: calc(100% - 250px);
        height: 94vh;
        overflow-y: scroll;
        box-sizing: border-box;
        background-color: #F3F3F3;
    }

    .frs-info {
        padding-bottom: 12px;
        border-bottom: 1px solid #ccc;
    }
</style>
