<template>
    <div class="input-group autocomplete">
        <input type="text" class="form-control" 
               placeholder="Rechercher par raison sociale un point de vente, un groupement..."
               v-model="search"
               @input="onChange"
        >
        <div class="autocomplete-items" v-if="isDisplayed">
            <div v-for="pdv in results" :key="pdv.Id" v-on:click="onPdvClick(pdv.Id)" v-bind:class="{ active: app.calculatorFilters.pdvs.indexOf(pdv.Id) !== -1 }">
                <div class="media">
                    <img class="mr-3" v-bind:src="pdv.Logo" v-bind:alt="pdv.NomGroupement">
                    <div class="media-body">
                        <h4 class="media-heading">{{ pdv.Nom }}</h4>

                        <span class="float-right badge badge-success" v-if="app.self.isAdmin">{{ pdv.Id }}</span>
                        
                        <span class="badge badge-secondary">{{ pdv.NomGroupement }}</span> <small>{{ pdv.Adresse }} - {{ pdv.CodePostal }} - {{ pdv.Ville }}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store, mutations } from "./store/store";

    export default {
        name: 'autocomplete',
        props: {
            items: {
                type: Array,
                required: false,
                default: () => []
            },
        },
        data() {
            return {
                search: "",
                isDisplayed: false,
                app: store
            };
        },
        methods: {
            // Au changement de valeur 
            onChange() {
                this.$emit("input", this.search);

                if (this.search.length > 2)
                    this.isDisplayed = true;
                else
                    this.isDisplayed = false;
            },
            // Au clic sur un PdV
            onPdvClick(guid) {
                const index = store.calculatorFilters.pdvs.indexOf(guid);
                if (index !== -1)
                    store.calculatorFilters.pdvs.splice(index, 1);
                else
                    store.calculatorFilters.pdvs.push(guid);
            },
            // Clic à l'exterieur du bloc de recherche
            handleClickOutside(evt) {
                if (!this.$el.contains(evt.target)) {
                    this.isDisplayed = false;
                }
            }
        },
        computed: {
            results() {
                var results = this.items.filter(item => {
                    return item.Nom.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
                });

                var resultsGrp = this.items.filter(item => {
                    return item.NomGroupement.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
                });

                var _results = mutations.getUnique([...results, ...resultsGrp], "Id").splice(0, 9);

                if (_results.length != 0) {
                    return _results;
                }
                else {
                    return [{
                        Nom: "Pas de résultat",
                        NomGroupement: "404",
                        Logo: this.app.rootApi + "logo/nologo.bmp",
                        Adresse: "Not Found",
                        CodePostal: "Tôôô !!!",
                        Ville: "Désolé, nous n'avons rien trouvé qui correspond à votre recherche...",
                        Id: "0"
                    }];
                }
            },
        },
        mounted() {
            document.addEventListener("click", this.handleClickOutside);
        },
        destroyed() {
            document.removeEventListener("click", this.handleClickOutside);
        }
    };
</script>

<style>
/* AUTOCOMPLETE */
.autocomplete-items {
    position: absolute;
    z-index: 9999 !important;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
    max-height: 400px;
    overflow-y: scroll;
    border-bottom: solid px #d4d4d4;
}

    .autocomplete-items > div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
    }

        .autocomplete-items > div:hover {
            background: #dddddd;
        }

        .autocomplete-items > div > div.media > img {
            max-width: 50px;
        }

        .autocomplete-items > div > div.media > div.media-body > h4 {
            /*font-size: 18px;*/
            margin-bottom: 5px;
        }

/*        .autocomplete-items > div > div.media > div.media-body > small:first-of-type {
            font-size: 12px;
        }
*/
        .autocomplete-items > div.active {
            border-left: solid 5px var(--primary);
        }
</style>
