<template>
    <Modal :title="data && (data.Commercial.FirstName + ' ' + data.Commercial.LastName)" :open="data != null"
        @close="$emit('close')" class="lg-modal">
        <div>
            <span><strong>{{ formatNumberEur(data.CA)}}</strong> de chiffre d'affaire</span><br />
            <span><strong>{{ data.NbPdv }}</strong> points de vente facturés</span>
            <h3>Part de marché</h3>
            <div class="pdm-charts d-flex justify-content-around mt-2 mb-4">
                <div>
                    <div class="graph">
                        <canvas width="200" id="graph-adv-popup-pdm-self"></canvas>
                    </div>
                    <div class="text-center"><strong>Part de Marché Fournisseur</strong> <font-awesome-icon class="ml-1"
                            icon="info-circle" @click="displayInfos1 = true" /></div>
                </div>
                <div>
                    <div class="graph">
                        <canvas width="200" id="graph-adv-popup-pdm"></canvas>
                    </div>
                    <div class="text-center"><strong>Part de Marché</strong> <font-awesome-icon class="ml-1"
                            icon="info-circle" @click="displayInfos2 = true" /></div>
                </div>
                <div>
                    <div class="graph">
                        <canvas width="200" id="graph-adv-popup-pdm-pf"></canvas>
                    </div>
                    <div class="text-center"><strong>Part de Marché sur mes clients</strong> <font-awesome-icon class="ml-1"
                            icon="info-circle" @click="displayInfos3 = true" /></div>
                </div>
            </div>
            <h3>Taux de Pénétration <font-awesome-icon class="ml-1" icon="info-circle"
                    style="font-size: 0.6em; vertical-align: top;" @click="displayInfosTP = true" /></h3>
            <div class="tp-charts mt-2 mb-4">
                <div class="graph">
                    <canvas id="graph-adv-popup-tp"></canvas>
                </div>
            </div>
            <h3>Panier Moyen <font-awesome-icon class="ml-1" icon="info-circle"
                    style="font-size: 0.6em; vertical-align: top;" @click="displayInfosPM = true" /></h3>
            <div class="pm-charts mt-2 mb-4">
                <div class="graph">
                    <canvas height="350" id="graph-adv-popup-pm"></canvas>
                </div>
            </div>
        </div>
        <Modal class="md-modal" :open="displayInfos1 || displayInfos2 || displayInfos3 || displayInfosTP || displayInfosPM"
            @close="displayInfos1 = false;
            displayInfos2 = false;
            displayInfos3 = false;
            displayInfosTP = false;
            displayInfosPM = false;" title="Informations">
            <div v-if="displayInfos1">
                CA commercial / CA fournisseur
            </div>
            <div v-else-if="displayInfos2">
                CA commercial / CA concurrents
            </div>
            <div v-else-if="displayInfos3">
                CA commercial / CA concurrents sur mes PDV clients
            </div>
            <div v-else-if="displayInfosTP">
                <dl>
                    <dt>Marché</dt>
                    <dd>Moi + mes concurrents</dd>
                    <dt>Taux moyen marché</dt>
                    <dd>Nb PDV facturés (distincts) par le marché / Nb PDV CMEM</dd>
                    <dt>Mon taux</dt>
                    <dd>Nb PDV clients / Nb PDV facturés (distincts) par le marché</dd>
                    <dt>Mon taux CMEM</dt>
                    <dd>Nb PDV clients / Nb PDV CMEM</dd>
                </dl>
            </div>
            <div v-else-if="displayInfosPM">
                <dl>
                    <dt>Marché</dt>
                    <dd>Moi + mes concurrents</dd>
                    <dt>Panier moyen marché</dt>
                    <dd>CA marché / Nb PDV facturés (cumulés) par le marché</dd>
                    <dt>Panier moyen marché sur mes clients</dt>
                    <dd>CA marché sur mes clients / Nb PDV facturés (cumulés) par le marché sur mes clients</dd>
                    <dt>Panier moyen concurrents</dt>
                    <dd>CA concurrents / Nb PDV facturés (cumulés) par mes concurrents</dd>
                    <dt>Panier moyen concurrents sur mes clients</dt>
                    <dd>CA concurrents sur mes clients / Nb PDV facturés (cumulés) par mes concurrents sur mes clients</dd>
                    <dt>Mon panier moyen</dt>
                    <dd>Mon CA / Nb PDV clients</dd>
                    <dt>Panier moyen PDV</dt>
                    <dd>CA marché / Nb PDV facturés (distincts) par le marché</dd>
                    <dt>Panier moyen PDV sur mes clients</dt>
                    <dd>CA marché sur mes clients / Nb PDV facturés (distincts) par le marché sur mes clients</dd>
                </dl>
            </div>
        </Modal>
    </Modal>
</template>

<script>
//import Chart from 'chart.js';
import Modal from "../Modal.vue";
import Chart from "chart.js/auto";

import { getRandomColor } from "@/utils";

export default {
    name: 'DorAdvPopup',
    components: {
        Modal
    },
    props: ["data"],
    data() {
        return {
            selectedRow: null,
            displayInfos1: false,
            displayInfos2: false,
            displayInfos3: false,
            displayInfosTP: false,
            displayInfosPM: false,
            charts: []
        }
    },
    mounted() {
        this.initCharts();
    },
    computed: {
        pdmSelfData() {
            const data = this.data;
            if (!data) return {};
            const labelFilter = function (context) {
                return parseFloat(context.raw).toFixed(2) + " %";
                // return " " + data.labels[tooltipItem.index] + " : " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toFixed(2) + " %";
            };
            return {
                data: {
                    labels: ['Reste Fournisseur', 'Ma part'],
                    datasets: [
                        {
                            label: "Proportion des chiffres d'affaire",
                            data: [100 - data.PdmFrs, data.PdmFrs],
                            backgroundColor: ['#dee2e6', getRandomColor(1)]
                        },
                    ],
                    styling: {
                        borderWidth: 0
                    }
                },
                tooltipLabelCallback: labelFilter,
                type: "doughnut",
            };
        },
        pdmData() {
            const data = this.data;
            if (!data) return {};
            //const labelFilter = function (tooltipItem, data) {
            //    return " " + data.labels[tooltipItem.index] + " : " + Number(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).toLocaleString("fr-FR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + " €";
            //};
            const labelFilter = function (context) {
                return parseFloat(context.raw).toFixed(2) + " %";
                // return " " + data.labels[tooltipItem.index] + " : " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toFixed(2) + " %";
            };
            return {
                data: {
                    labels: ['Concurrents sur ma zone', 'Ma part'],
                    datasets: [
                        {
                            label: "Proportion des chiffres d'affaire",
                            data: [100 - data.PdmCMEM, data.PdmCMEM],
                            backgroundColor: ['#dee2e6', getRandomColor(1)]
                        },
                    ],
                    styling: {
                        borderWidth: 0
                    }
                },
                tooltipLabelCallback: labelFilter,
                type: "doughnut",
            };
        },
        pdmDataPF() {
            const data = this.data;
            if (!data) return {};
            const labelFilter = function (context) {
                return parseFloat(context.raw).toFixed(2) + " %";
                // return " " + data.labels[tooltipItem.index] + " : " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toFixed(2) + " %";
            };
            return {
                data: {
                    labels: ['Concurrents sur mes clients', 'Ma part'],
                    datasets: [
                        {
                            label: "Proportion des chiffres d'affaire",
                            data: [100 - data.PdmPdvFiltered, data.PdmPdvFiltered],
                            backgroundColor: ['#dee2e6', getRandomColor(1)]
                        },
                    ],
                    styling: {
                        borderWidth: 0
                    }
                },
                tooltipLabelCallback: labelFilter,
                type: "doughnut",
            };
        },
        tpData() {
            const data = this.data;
            if (!data) return {};
            const labelFilter = function (context) {
                return parseFloat(context.raw).toFixed(2) + " %";
                // return " " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toFixed(2) + " %";
            };
            return {
                data: {
                    labels: ['Taux moyen marché', 'Mon taux', 'Mon taux CMEM'],
                    datasets: [
                        {
                            label: "Taux de pénétration",
                            data: [data.TpMoyen, data.Tp, data.TpCMEM],
                            backgroundColor: ['#dee2e6', getRandomColor(1), getRandomColor(4)]
                        },
                    ],
                    styling: {
                        borderWidth: 0
                    }
                },
                tooltipLabelCallback: labelFilter,
                scales: {
                    x: {
                        ticks: {
                            beginAtZero: true,
                            max: 100
                        }
                    }
                },
                options: {
                    indexAxis: "y",
                },
                type: "bar"
            };
        },
        pmData() {
            const data = this.data;
            if (!data) return {};
            const labelFilter = function (context) {
                return Number(context.raw).toLocaleString("fr-FR", { minimumFractionDigits: 0 }) + " €";
                // return " " + Number(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).toLocaleString("fr-FR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + " €";
            };
            return {
                data: {
                    labels: ['Panier Moyen Marché',
                        'Panier Moyen Marché sur mes clients',
                        'Panier Moyen Concurrents',
                        'Panier Moyen Concurrents sur mes clients',
                        'Mon Panier Moyen',
                        'Panier Moyen PDV',
                        'Panier Moyen PDV sur mes clients'],
                    datasets: [
                        {
                            label: "Panier moyen",
                            data: [data.PmMarche, data.PmMarchePdvFiltered, data.Pm, data.PmConcurrents, data.PmConcurrentsPdvFiltered, data.PmPDV, data.PmPDVPdvFiltered],
                            backgroundColor: [getRandomColor(4), getRandomColor(4), '#dee2e6', '#dee2e6', getRandomColor(1), getRandomColor(9), getRandomColor(9)]
                        },
                    ],
                    styling: {
                        borderWidth: 0
                    }
                },
                tooltipLabelCallback: labelFilter,
                scales: {
                    y: {
                        ticks: {
                            beginAtZero: true,
                        }
                    }
                },
                type: "bar"
            };

        }
    },
    watch: {
        data() {
            this.initCharts();
        }
    },
    methods: {
        initCharts() {
            this.initChart("graph-adv-popup-pdm-self", this.pdmSelfData);
            this.initChart("graph-adv-popup-pdm", this.pdmData);
            this.initChart("graph-adv-popup-pdm-pf", this.pdmDataPF);
            this.initChart("graph-adv-popup-tp", this.tpData);
            this.initChart("graph-adv-popup-pm", this.pmData);
        },
        initChart(chartId, { type, data, tooltipLabelCallback, scales = {}, displayLegend = false, options = {} }) {
            const canvas = document.getElementById(chartId);
            if (this.charts[chartId]) {
                this.charts[chartId].destroy();
                this.charts[chartId] = null;
            }

            this.charts[chartId] = new Chart(canvas, {
                type: type,
                data,
                options: {
                    ...options,
                    scales,
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: displayLegend,
                            position: "bottom"
                        },
                        tooltip: {
                            callbacks: {
                                label: tooltipLabelCallback
                            }
                        }
                    }
                }

            });
        },
        formatNumberEur(number) {
            return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(number);
        }
    },
}
</script>

<style>
.pdm-charts .graph {
    /*        width: 20%;*/
}
</style>
