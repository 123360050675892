////import Vue from "vue";
import { store } from "./store";
//import { dataFinanceMutations } from "./dataFinance";
import { fetchApi } from "@/utils";

export const gammeMutations = {

    // Initialisation des gammes au chargement de la page 
    initGammes() {
        store.gammes = [];
        return new Promise((resolve, reject) => {
            if (store.calculatorFilters.idPeriode == -1) {
                resolve();
                return;
            }
            fetchApi("gammes/supplierguid?frsGuid=" + store.self.guid + "&idPeriode=" + store.calculatorFilters.idPeriode, {}, "drone")
                .then((response) => {
                    let gammeList = response.data.map(gamme => ({
                        Id: gamme.Id,
                        Famille: gamme.Name,
                    }));

                    store.calculatorFilters.gammes = []
                    store.gammes = gammeList.filter(el => {
                        let res;
                        if (gammeList.findIndex(_el => _el.Id !== 0 && _el.Id !== 20) === -1) // S'il n'existe de gamme != 0 et != 20
                            res = el.Id === 0 // On prend uniquement CA TOTAL
                        else res = el.Id !== 0 && el.Id !== 20 // Sinon on prend toutes gammes réelles
                        return res;
                    })
                    store.calculatorFilters.gammes = store.gammes.length > 0 ? [store.gammes[0].Id] : [0]
                    resolve()
                })
                .catch(reject);
        })
    },
};