<template>
    <div v-if="!app.self.isOdw && !app.self.isSupplier">
        <div class="input-container">
            <div class="checkbox-container">
                <input id="checkbox-rep" class="checkbox-cmem" type="checkbox" @change="toggleAdhREP"
                    :checked="app.calculatorFilters.pdvDistance.latlng != null" />
                <label class="form-check-label" for="checkbox-rep">
                    Trouver un point de collecte proche
                </label>
            </div>
            <div class="mt-3 input-group">
                <label v-show="app.calculatorFilters.pdvDistance.latlng != null">
                    Distance (km)<br />
                    <input class="form-control" type="number" min="1" @change="updateDistance"
                        v-show="app.calculatorFilters.pdvDistance.latlng != null"
                        v-model="app.calculatorFilters.pdvDistance.distance" />
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store/store";
import { pointDeVenteMutations } from "../store/pointDeVente";

export default {
    name: "AdhREPFilter",
    data() {
        return {
            app: store,
        }
    },
    mounted() {
        this.toggleAdhREP();
    },
    methods: {
        toggleAdhREP() {
            if (store.calculatorFilters.pdvDistance.latlng)
                store.calculatorFilters.pdvDistance.latlng = null;
            else if (store.self.GPS[0] && store.self.GPS[1])
                store.calculatorFilters.pdvDistance.latlng = {
                    lat: store.self.GPS[0],
                    lng: store.self.GPS[1]
                };

            pointDeVenteMutations.loadPdv();
        },
        updateDistance() {
            pointDeVenteMutations.loadPdv();
        },
    }
}
</script>

<style scoped>
.checkbox-container {
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
}
</style>
