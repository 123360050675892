<template>
    <div class="maps-page-container">
        <ul class="tab-nav white-box" v-if="$route.path.startsWith('/zones')">
            <li :class="{active: $route.path === '/zones/pdm'}">
                <router-link   :to="{ path: '/zones/pdm', query: $route.query }">
                    <div class="icon">
                        <font-awesome-icon icon="chart-pie" />
                    </div>
                    Part de marché
                </router-link>
            </li>
            <li :class="{active: $route.path === '/zones/tp'}">
                <router-link   :to="{ path: '/zones/tp', query: $route.query }">
                    <div class="icon">
                        <font-awesome-icon icon="arrow-right" />
                    </div>
                    Taux de pénétration
                </router-link>
            </li>
            <li :class="{active: $route.path === '/zones/pm'}">
                <router-link   :to="{ path: '/zones/pm', query: $route.query }">
                    <div class="icon">
                        <font-awesome-icon icon="shopping-basket" />
                    </div>
                    Panier moyen
                </router-link>
            </li>
        </ul>
        <div class="carto-container" :class="{'non-odw': !app.self.isOdw}">
            <div v-if="app.self.isOdw" class="white-box results-container">
                <!-- Résultats financiers -->
                <MasterSelectorResultatsFinanciers v-if="$route.path == '/'" />
                <ZoneResults v-if="$route.path.startsWith('/zones')" />
                <!-- Indicateurs -->
            </div>
            <div class="white-box map-container">
                <div class="header">
                    <h2>CARTOGRAPHIE DES RESULTATS</h2>
                </div>
                <nav aria-label="breadcrumb" data-v-step="2" v-if="!$route.path.startsWith('/zones')">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item" v-on:click="resetMap('cmem', 0)"><a href="#">CMEM</a></li>
                        <li class="breadcrumb-item" v-on:click="resetMap('pays', 0)" v-if="this.app.breadcrumb.Pays.Id !== 0"><a href="#">{{ this.app.breadcrumb.Pays.Nom }}</a></li>
                        <li class="breadcrumb-item" v-on:click="resetMap('region', app.breadcrumb.Region.Bounds)" v-if="this.app.breadcrumb.Region.Id !== 0"><a href="#">{{ this.app.breadcrumb.Region.Nom }}</a></li>
                        <!--<li class="breadcrumb-item" v-on:click="resetMap('departement', app.breadcrumb.Departement.Bounds)" v-if="this.app.breadcrumb.Departement.Id !== 0"><a href="#">{{ this.app.breadcrumb.Departement.Nom }}</a></li>-->
                        <!--<li class="breadcrumb-item" v-on:click="resetMap('ville', breadcrumb.Ville.Id, breadcrumb.Ville.Bounds)" v-if="breadcrumb.Ville.Id != 0"><a href="#">{{ this.app.breadcrumb.Ville.Nom }}</a></li>-->
                    </ol>
                </nav>
                <div id="map" class="map" data-v-step="6">
                    <button id="buttonImprimer" class="buttonPrint leaflet-bar leaflet-bar" title="Imprimer la carte" v-on:click="printMap">
                        <i class="fas fa-print icone"></i>
                    </button>
                    <button id="buttonBurger" class="buttonBurger" v-on:click="printMenu">
                        <div>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store, mutations } from "../../components/store/store";
    import { dataFinanceMutations } from "../../components/store/dataFinance";
    import 'leaflet/dist/leaflet.css';
    import { render, initMap } from '../../components/store/map';
    import '@fortawesome/fontawesome-free/css/all.css'
    import '@fortawesome/fontawesome-free/js/all.js'
    import imageLogo from '../../assets/images/logocmem.jpg'
    import { jsPDF } from "jspdf"; //Permet la création de pdf
    import '../../assets/css/fontCalibri.js';
    import 'jspdf-autotable'; //Permet la création de tables dans le pdf
    import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter' //Permet de faire un screen de la map

    import MasterSelectorResultatsFinanciers from "../../components/Graphs/MasterSelectorResultatsFinanciers"
    import ZoneResults from "../../components/ZoneResults";
    //import MasterSelectorProjections from "../../components/Graphs/MasterSelectorProjections"
    export default {
        name: 'Maps',
        components: {
            MasterSelectorResultatsFinanciers,
            ZoneResults
            //MasterSelectorProjections
        },
        data() {
            return {
                app: store,
                canRender: true,
                shouldRender: false
            }
        },
        mounted() {
            if (this.$route.path.startsWith("/zones") && this.app.financier.details.loaded != this.app.self.guid) {
                dataFinanceMutations.loadDataFinanceDetails();
            }
            initMap();
            this.debouncedRender();
        },
        computed: {
            // Données qu'utilise la carte pour afficher les éléments correctement
            // => Si une donnée change, la carte doit se rafficher
            mapDatas() {
                return {
                    dataLoaded: store.dataMap.dataLoaded,
                    selectedRegionId: store.breadcrumb ? store.breadcrumb.Region ? store.breadcrumb.Region.Id : null : null,
                    selectedAdvs: store.calculatorFilters.advs,
                    selectedPdvs: store.calculatorFilters.pdvs,
                    distanceSelectionPos: store.calculatorFilters.pdvDistance.latlng,
                    distanceSelection: store.calculatorFilters.pdvDistance.distance,
                    pdvs: store.dataAdherents.pdvs,
                    mapIndicateur: store.calculatorFilters.indicateur,
                    financierDetailsLoaded: store.financier.details.loaded,
                }
            }
        },
        watch: {
            "mapDatas": {
                handler() {
                    this.debouncedRender();
                },
            },
            "app.calculatorFilters.isClient": {
                handler() {
                    if (this.$route.path.startsWith("/zones") && this.app.calculatorFilters.isClient != 1) {
                        this.$router.replace("/");
                    }
                }
            },
            "$route": {
                handler() {
                    this.debouncedRender();
                    if (store.dataMap.map) store.dataMap.map._onResize();
                    if (this.$route.path.startsWith("/zones")) {
                        if (store.financier.details.loaded != store.self.guid) {
                            dataFinanceMutations.loadDataFinanceDetails().then(() => {
                                this.debouncedRender();
                            });
                        }
                    }
                }
            }
        },
        methods: {
            debouncedRender() {
                this.shouldRender = true;
                if (store.dataMap.dataLoaded && this.canRender) {
                    render();
                    this.canRender = false;
                    this.shouldRender = false;
                    window.setTimeout(() => {
                        this.canRender = true;
                        if (this.shouldRender)
                            this.debouncedRender();
                    }, 500);
                }
            },

            resetMap(scope, bounds) {
                mutations.resetMap(scope, bounds);
            },

            printMap: function () { //Permet d'exporter la map (pdf)
                mutations.displayLoader(true);
                var filAriane = "CMEM / "; //Sauvegarde du fil d'ariane
                filAriane += store.breadcrumb.Pays.Nom != "" ? store.breadcrumb.Pays.Nom + ' / ' : '';
                filAriane += store.breadcrumb.Region.Nom != "" ? store.breadcrumb.Region.Nom + ' / ' : '';
                filAriane += store.breadcrumb.Departement.Nom != "" ? store.breadcrumb.Departement.Nom + ' / ' : '';
                filAriane += store.breadcrumb.Ville.Nom != "" ? store.breadcrumb.Ville.Nom + ' / ' : '';

                //On enlève les boutons avant de sauvegarder la map
                this.setVisibility(document.getElementById("buttonImprimer"), "hidden");
                this.setVisibility(document.getElementsByClassName("leaflet-top leaflet-left")[0], "hidden");

                //On récupère la date et l'heure d'aujourd'hui afin de les afficher dans le pdf
                var today = new Date();
                var jours = today.getDate() < 10 ? jours = "0" + today.getDate().toString() : today.getDate().toString();
                var mois = today.getMonth() + 1 < 10 ? mois = "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
                var date = jours + '-' + mois + '-' + today.getFullYear();
                var heure = today.getHours() < 10 ? '0' + today.getHours() + 'H' : today.getHours().toString() + 'H'
                heure += today.getMinutes() < 10 ? '0' + today.getMinutes().toString() : today.getMinutes().toString();


                //Initialisation de la capture d'écran de la map



                //Création du pdf
                var doc = new jsPDF('p', 'mm', 'a4');
                doc.setFont('Calibri-Regular', 'normal');
                doc.addImage(imageLogo, "PNG", 1, 1, 492 / 6, 149 / 6); //Ajout du logo
                doc.setTextColor(167, 193, 60);
                doc.text(store.self.nom, 10, 35);
                doc.text(filAriane, 10, 45); //Ajout du fil d'ariane

                this.simpleMapScreenshoter = new SimpleMapScreenshoter()
                this.simpleMapScreenshoter.addTo(store.dataMap.map);
                document.getElementsByClassName("leaflet-control-simpleMapScreenshoter")[0].remove();
                this.simpleMapScreenshoter.takeScreen('canvas', {
                    caption: function () {
                        return "Carte CMEM créée à " + heure + " le " + date;
                    }
                }).then(image => {
                    var myImage = image.toDataURL("image/jpeg,1.0"); //sauvegarde de l'image en jpeg
                    var imgWidth = (image.width * 20) / 130; //sauvegarde la largeur
                    var imgHeight = (image.height * 20) / 120; //sauvegarde de la hauteur
                    doc.addImage(myImage, 'JPEG', 5, 50, imgWidth, imgHeight);
                    this.setFilters(doc); //Ajouter les filtres selectionnés
                    doc = this.addWaterMark(doc, date, heure); //Ajout du filigrane
                    var fileName = "ExportCarte-" + store.self.name + "-" + store.self.firstname + "-" + date + "-" + heure + ".pdf"; //nom du fichier
                    doc.save(fileName); //On enregistre
                    this.setVisibility(document.getElementById("buttonImprimer"), "visible"); //On réaffiche le boutton Imprimer
                    this.setVisibility(document.getElementsByClassName("leaflet-top leaflet-left")[0], "visible"); //On réaffiche les bouttons de zooms
                    mutations.displayLoader(false);

                }).catch(e => {
                    console.error("Erreur lors de l'impression de la map : " + e)
                })

            },


            setVisibility: function (element, state) { //Fonction rend visible ou non un élément
                element.style.visibility = state;
            },


            addWaterMark: function (doc, date, heure) { //Fonction qui ajoute des filigrane à un doc avec la date et l'heure
                var totalPages = doc.internal.getNumberOfPages(); //récupère le nombre total de pages

                for (var i = 1; i <= totalPages; i++) { //Parcours de toute es pages
                    doc.setPage(i);//On se met sur la page i
                    doc.setTextColor(150, 150, 150)//On met le texte en gris
                    doc.setFontSize(30); //on met la font size
                    doc.text(50, doc.internal.pageSize.height - 90, 'CONFIDENTIEL - CMEM FRANCE', null, 45); //45 = rotation vers la droite
                    doc.text(95, doc.internal.pageSize.height - 110, store.self.firstname + ' ' + store.self.name, null, 45);
                    doc.text(105, doc.internal.pageSize.height - 95, date + ' ' + heure, null, 45);
                }

                return doc;
            },



            setFilters: function (doc) { //Fonctions qui récupère les filtres, les traduits et les écrits dans le document
                //Initialisation des variables
                var tranches = [];
                var families = [];
                var gammes = [];
                var commerciaux = [];
                var groupements = [];
                var isClient = '';
                var specialiste = '';
                var generaliste = '';
                var indicateur = '';
                //Sauvegarde des commerciaux
                store.self.commerciaux.forEach(e => { if (e.Active) commerciaux.push(e.LastName + ' - ' + e.FirstName + ' - ' + e.Email) });


                //On vérifie si l'on est en mode observatoire
                if (store.self.isOdw) {
                    for (let i = 0; i < store.gammes.length; i++) {
                        if (store.gammes[i].Active) gammes.push(store.gammes[i].Famille); //Sauvegarde des gammes
                    }
                    groupements = this.getFilterArray(this.app.dataAdherents.centrales, store.calculatorFilters.adhs, 'Name')
                    families = this.getFilterArray(store.families, store.calculatorFilters.families, 'Label');
                    tranches = this.getFilterArray(store.dataAdherents.tranchesCa, store.calculatorFilters.tranchesCa, 'Libelle');
                    store.calculatorFilters.isClient ? isClient = 'Client' : isClient = 'Non client'; //Clients
                    store.calculatorFilters.specialistYes ? specialiste = 'Oui' : specialiste = ''; //Spécialiste coché
                    store.calculatorFilters.specialistNo ? generaliste = 'Oui' : generaliste = ''; //Généraliste coché
                    switch (store.calculatorFilters.indicateur) { //Gestion des indicateurs de carte
                        case 'pdm': indicateur = 'Part de marché'; break;
                        case 'tp': indicateur = 'Taux de pénétration'; break;
                        case 'pm': indicateur = 'Panier moyen'; break;
                        default: indicateur = '';
                    }


                }


                //Implémentation des filtres dans le document pdf sur une nouvelle page
                doc.addPage(); //On implémente les filtres sur une autre page
                doc.setFontSize(22);

                let classificationPDV = '' + generaliste != '' ? 'Généraliste -' : '';
                let periodeToPrint = 'Non précisée';
                if (store.self.accessOdw == 1) {
                    //Le choix de la période est disponible si l'utilisateur à accès à Odw
                    if (store.calculatorFilters.periodeName != "Choix de la période" && store.calculatorFilters.selectedYear != "Choix de l'année") periodeToPrint = store.calculatorFilters.selectedYear + ' / ' + store.calculatorFilters.periodeName;
                }
                let univers = store.calculatorFilters.isLnl == 1 ? 'Ligne et Lumière ' : '';
                univers += store.calculatorFilters.isByLnl == 1 ? '\n By Ligne et Lumière' : '';
                classificationPDV += specialiste != '' ? 'Spécialiste' : '';
                doc.autoTable({
                    tableWidth: 180, //Largeur de 180
                    startY: 20, //On commence à 20 de hauteur
                    cellWidth: 'wrap', //Les cellules s'enveloppent
                    styles: { fontSize: 10, overflow: 'linebreak' },
                    theme: 'grid', //Thème grille
                    columnStyles: {
                        0: { cellWidth: 50, halign: 'left' },
                        1: { halign: 'right', cellWidth: 'auto', overflow: 'linebreak' },
                    },
                    margin: { left: 10, right: 0 },
                    head: [['Filtre', 'Valeur']],//Haut du tableau
                    body: [
                        [['Classification points de vente '], [classificationPDV == '' ? 'Aucune' : classificationPDV]],
                        [['Indicateurs de carte '], [indicateur == '' ? 'Aucun' : indicateur]],
                        [['Groupements'], [this.buildStringByArray(groupements) == '' ? 'Aucun' : this.buildStringByArray(groupements)]],
                        [['Commerciaux'], [this.buildStringByArray(commerciaux) == '' ? 'Aucun' : this.buildStringByArray(commerciaux)]],
                        [['Tranches de chiffre d\'affaire sélectionnées'], [this.buildStringByArray(tranches) == '' ? 'Aucune' : this.buildStringByArray(tranches)]],
                        [['Spécialité point de vente'], [this.buildStringByArray(families) == '' ? 'Aucune' : this.buildStringByArray(families)]],
                        [['Clients sélectionnés'], [isClient == '' ? 'Non précisé' : isClient]],
                        [['Univers'], [univers == '' ? 'Non précisé' : univers]],
                        [['Année / Période'], [periodeToPrint]]

                    ],
                    didParseCell: function (table) {
                        if (table.section === 'head') { //modification de l'entête de la table
                            table.cell.styles.fillColor = '#A7C13C'; //Couleur de CMEM
                            table.cell.styles.halign = 'center'; //Centré
                        }
                    },
                });




            },

            buildStringByArray(array) { //Construit une chaîne de caractère et la retourne à partir d'une Table
                var string = '';
                for (let i = 0; i < array.length; i++) {
                    string += ' ' + array[i].charAt(0).toUpperCase() + array[i].slice(1).toLowerCase(); //Format du texte
                    if ((string.includes('@') || string.includes('€')) && i != array.length - 1) string += '\n'; //Gère le format des tranches CA et commerciaux
                    if (i != array.length - 1) string += ' -';
                }
                return string;


            },
            //Fonction qui écrit dans un doc pdf avec un texte, sa forme, son margin gauche, son margin du dessus, et sa couleur
            setTextOfDoc: function (doc, texte, isBold, leftPas, topPas, r, g, b) {
                var p = 10;
                if (topPas >= 300) { //si on est au dessus
                    doc.addPage(); //On rajoute une page
                    p = -topPas + 20; //Il faut réinitaliser le pas (ex : 10 = 10 - 300 => -290)
                    topPas = 10;
                }
                if (isBold) doc.setFont('times', 'bold');
                else doc.setFont('times', 'normal');
                doc.setTextColor(r, g, b);
                doc.text(texte, leftPas, topPas);
                return p;
            },
            //affiche filtres de type tableau
            addArrayFilter: function (doc, array, filterName, pas) {
                if (array.length != 0) {

                    pas += this.setTextOfDoc(doc, ("-> " + filterName), true, 25, pas, 0, 0, 0);
                    for (var x = 0; x < array.length; x++) {
                        pas += this.setTextOfDoc(doc, (" - " + this.formatCommerciauxName(array[x])), false, 30, pas, 0, 0, 0);
                    }
                }
                return pas;


            },
            //Renvoie un tableau contenant les labels des élements cochés en vérifiant leur appartenance à deux tableaux
            getFilterArray(arraySource, arrayChecked, objectProperty) {//ici arraySource représente les données stockées lors du lancement de l'appli et arrayCheck représente un tableau d'id d'éléments coché
                var arrayTab = [];
                var id = ['Id'];
                if (objectProperty == 'Guid') id = ['Guid']
                for (let k = 0; k < arrayChecked.length; k++) {
                    for (let a = 0; a < arraySource.length; a++) {
                        if (arraySource[a][id] == arrayChecked[k]) {
                            arrayTab.push(arraySource[a][objectProperty]);

                        }
                    }
                }
                return arrayTab;
            },


            formatCommerciauxName: function (com) { //Format le filtre, si c'est des commerciaux on va les alignés les uns en dessous des autres
                if (com.includes('@')) {
                    var tab = com.split('-');
                    for (var i = 0; i < tab.length; i++) {
                        if (tab[i].includes('@')) return tab[i];
                    }
                } else return com;

            },

            printMenu() { //Affiche le menu quand l'utilisateur est sous version mobile et qu'il clique sur le menu burger
                document.getElementById('selector').classList.toggle("active");
                document.getElementById('buttonBurger').classList.toggle("active");
            }
        }
    }
</script>

<style scoped>
    .maps-page-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: hidden;
    }

    .maps-page-container .carto-container {
        flex-grow: 1;
    }

    .carto-container {
        display: grid;
        grid-template-columns: 400px 1fr;
        column-gap: 24px;
        max-height: 100%;
        overflow: hidden;
    }

    .carto-container.non-odw {
        grid-template-columns: 1fr;
        column-gap: 0;
    }


    .map-container, .results-container {
        max-height: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .map-container {
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        display: flex;
        flex-direction: column;
    }

    .map-container .header {
        margin: 0 12px;
        padding-bottom: 12px;
        width: auto;
    }

    .breadcrumb {
        background-color: transparent !important;
    }

    #map {
        width: 100%;
        flex-grow: 1;
    }
</style>