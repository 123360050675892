<template>
    <div>
        <div class="header">
            <h2>
                Global
            </h2>
        </div>
        <h3 class="text-center">Résultats par groupements clients par région, {{ extendedTitle }}</h3>
        <table class="table table-bordered table-striped results-table">
            <thead>
                <tr>
                    <th class="adh-col"></th>
                    <th v-for="(region, index) of properRegionTable" :key="index" class="align-middle text-center">{{
                        region.Name }}</th>
                    <th class="total align-middle text-center">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(adh, i) of adhList" :key="adh.Guid != 'separator' ? adh.Guid : i"
                    :class="{ separator: adh.Guid == 'separator' }">
                    <th v-if="adh.Guid != 'separator'">
                        <div class="d-flex justify-content-between align-items-center"
                            :class="{ ['hl' + adh.HierarchyLvl]: true }">{{ adh.Name.padStart(adh.Name.length +
                                adh.HierarchyLvl, ' ') + (adh.HierarchyLvl == 3 ? (" - " + adh.ZipCode + " - " + adh.City) : "")
                            }}<button @click="toggleExpand(adh.Guid)" v-if="adh.HierarchyLvl < 3">{{
    adhGuidExtended.indexOf(adh.Guid) == -1 ? "+" : "-" }}</button>
                        </div>
                    </th>
                    <td v-else></td>
                    <td v-for="region of properRegionTable" :key="adh.Guid + '-' + region.Id" class="align-middle"><span
                            v-if="adh.Guid != 'separator'">{{ formatNumberEur((region.IdNational in adh.CaPerRegion ?
                                adh.CaPerRegion[region.IdNational] : 0)) }}</span></td>
                    <td class="total align-middle"><span v-if="adh.Guid != 'separator'">{{ formatNumberEur(adh.Ca || 0)
                    }}</span></td>
                </tr>
                <tr>
                    <th class="total align-middle">Total</th>
                    <td class="total align-middle" v-for="region of properRegionTable" :key="region.Id">{{
                        formatNumberEur(getCaTotalByRegion(region.IdNational)) }}</td>
                    <td class="total align-middle">{{ formatNumberEur(app.financier.details.self.caTotal) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { store } from "../store/store";
//import { dataFinanceMutations } from "../../components/store/dataFinance";

export default {
    name: "ResultsTable",
    data() {
        return {
            app: store,
            adhGuidExtended: [],
        }
    },
    computed: {
        // On récupère la hierarchie de store.financier
        hierarchy() {
            return this.app.financier.hierarchy;
        },
        // On ajoute le détail de la sélection dans le titre
        extendedTitle: function () {
            // Liste des gammes sélectionnées
            var gammes = "par gamme ";
            if (this.app.calculatorFilters.gammes.length === 0 || (this.app.calculatorFilters.gammes.length === 1 && this.app.calculatorFilters.gammes[0] === 0)) {
                gammes = "par CA total ";
            } else {
                this.app.calculatorFilters.gammes.forEach((gamme, key) => {
                    const gammeIndex = this.app.gammes.findIndex((el) => el.Id === gamme)
                    const gammeName = this.app.gammes[gammeIndex] ? this.app.gammes[gammeIndex].Famille : "CA total"
                    if (key == 0) {
                        gammes += gammeName + " ";
                    }
                    else {
                        gammes += "et " + gammeName + " ";
                    }
                });
            }

            // Liste des typologies sélectionnes
            var typology = " et par typologie d'adhérent ";
            if (this.app.calculatorFilters.isSpecialiste == -1) {
                typology = typology + "généraliste et spécialiste";
            }
            else if (this.app.calculatorFilters.isSpecialiste == 0) {
                typology = typology + "généraliste";
            }
            if (this.app.calculatorFilters.isSpecialiste == 1) {
                typology = typology + "spécialiste";
            }

            return gammes + typology;
        },
        // On retire les départements outre-mer pour les regrouper sous la grande région "DOM-TOM"
        properRegionTable: function () {
            return this.app.dataMap.regions.filter(r => r.IdNational != '01' && r.IdNational != '02' && r.IdNational != '03' && r.IdNational != '04' && r.IdNational != '06');
        },
        adhList: function () {
            let res = [];
            store.dataAdherents.centrales
                .slice()
                .sort((a, b) => a.Name < b.Name ? -1 : 1)
                .forEach(c => {
                    let adhGrp = { Name: c.Name, Guid: c.Guid, HierarchyLvl: 0, CaPerRegion: {}, Ca: 0 };
                    if (adhGrp.Guid in this.hierarchy) {
                        adhGrp.Ca = this.hierarchy[adhGrp.Guid].Ca;
                        adhGrp.CaPerRegion = this.hierarchy[adhGrp.Guid].CaPerRegion;
                    }

                    res.push(adhGrp);
                    if (adhGrp.Guid in this.hierarchy && this.adhGuidExtended.indexOf(adhGrp.Guid) != -1) {
                        // On développe
                        const childList = this.getAdhChildList(0, this.hierarchy[adhGrp.Guid]);
                        if (childList && childList.length > 0) {
                            res.push({ Guid: "separator" })
                            res.push(...childList);
                        }
                    }
                });
            return res;
        }
    },
    methods: {
        init() {
            return;
        },
        toggleExpand(guid) {
            const i = this.adhGuidExtended.indexOf(guid);
            if (i == -1) this.adhGuidExtended.push(guid)
            else this.adhGuidExtended.splice(i, 1);
        },
        getAdhChildList(parentHierarchyLvl, parent) {
            const res = [];

            const hierarchyLvl = parentHierarchyLvl + 1;

            Object.values(parent.children)
                .slice()
                .sort((a, b) => a.Name < b.Name ? -1 : 1)
                .forEach(adh => {
                    const adhObj = { ...adh, HierarchyLvl: hierarchyLvl }
                    res.push(adhObj);
                    // S'il faut développer les adh enfants
                    if (hierarchyLvl < 3 && this.adhGuidExtended.indexOf(adhObj.Guid) != -1) {
                        const childList = this.getAdhChildList(hierarchyLvl, adh);
                        if (childList && childList.length > 0) {
                            res.push({ Guid: "separator" })
                            res.push(...childList);
                        }
                    }
                })
            return res;
        },
        getPdvRegion(pdv) {
            const idDep = pdv.IdDepartement;
            const region = store.dataMap.regions.find(r => r.Departements.findIndex(d => d.IdDepartement == idDep) != -1);

            if (!region) return "-1";
            let regionId = region.IdNational;

            if (["01", "02", "03", "04", "06"].indexOf(regionId) != -1) regionId = 99;// On remplace les DOM-TOM par la grande région DOM-TOM
            return regionId;
        },
        //
        getCaTotalByRegion(regionId) {
            if (!(this.app.financier.details.loaded) || !this.app.financier.details.self.groupement)
                return 0;
            let res = 0;
            for (const groupId in this.app.financier.details.self.groupement) {
                res += this.app.financier.details.self.groupement[groupId][regionId] || 0
            }
            return res;
        },
        formatNumberEur(number) {
            return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(number);
        },
    },
    filters: {
    }
}
</script>

<style>
.results-table tr th button {
    border: 1px solid #DEDEDE;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    aspect-ratio: 1/1;
    line-height: 1;
    padding: 0 3px 3px 3px;
    vertical-align: middle;
    transition: all ease 230ms;
}

.results-table tr th button:hover {
    background: rgba(0, 0, 0, 0.125);
}

.results-table tbody tr th {
    min-width: 15rem;
}

.results-table tr th,
.results-table tr td {
    min-height: 100%;
}

.results-table tr.separator th,
.results-table tr.separator td {
    background-color: #DEDEDE;
    padding: 4px 0;
    margin: 0;
}

.results-table tbody tr th div {
    font-size: 0.85rem;
}

.results-table tr th div.hl1 {
    padding-left: 8px;
    font-style: italic;
}

.results-table tr th div.hl2 {
    padding-left: 16px;
    font-weight: normal;
}

.results-table tr th div.hl3 {
    padding-left: 24px;
    font-style: italic;
    font-weight: normal;
}
</style>