<template>
    <div class="search-frs">
        <input class="input form-control" type="text" v-model="searchFrs" placeholder="Rechercher un fournisseur" />
        <div class="frs-results">
            <div class="col-3 frs-result" v-for="frs of searchFrsResults" :key="frs.Guid" @click="selection(frs)">
                <div class="d-flex justify-content-between align-items-start">
                    <h3>{{frs.Name}}</h3>
                    <div class="badge badge-info">{{frs.PaysRef}}</div>
                </div>
                <div class="d-flex">
                    <span class="frs-nomenclature">{{frs.N1}}</span>
                    <span class="sep"> / </span>
                    <span class="frs-nomenclature">{{frs.N2}}</span>
                </div>
                <div class="dates">
                    Référencé<span v-if="frs.DateDereference"> du </span><span v-else> le </span>{{frs.DateReference}} <span v-if="frs.DateDereference">au {{frs.DateDereference}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from "../store/store";

    export default {
        name: "SearchFrs",
        data() {
            return {
                store,
                searchFrs: "",
            }
        },
        methods: {
            selection(frs) {
                this.searchFrs = "";
                this.$emit('selection', frs);
            }
        },
        computed: {
            searchFrsResults() {
                let res = []
                if (this.searchFrs.length >= 2) {
                    res = this.store.dataFournisseurs.filter(frs => {
                        let reg = new RegExp(`.*${this.searchFrs}.*`, "i");
                        return reg.test(frs.Name);
                    })
                }
                return res;
            }
        }
    }
</script>

<style scoped>

    .frs-result h3 {
        margin-top: 0;
    }

    .frs-results {
        margin-top: 18px;
        display: flex;
        flex-wrap: wrap;
    }

    .frs-result {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 6px 12px;
        transition: all ease 0.3s;
        margin-bottom: 16px;
        margin-left: 8px;
        margin-right: 8px;
    }

        .frs-result:hover {
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.2);
        }

    .sep {
        margin: 0 4px;
    }

    .frs-nomenclature {
        font-size: 12px;
    }

    .dates {
        color: #666;
        font-size: 14px;
    }
</style>
