<template>
    <div class="association-container">
        <h1>Association fournisseurs / gammes</h1>
        <br />
        <div class="card shadow mb-4">
            <!-- Card Header - Dropdown -->
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h5>Personnalisation des groupes d'associations</h5>
            </div>
            <!-- Card Body -->
            <span class="btn btn-success" @click="displayType = (displayType + 1) % 2"><i class="fas fa-exchange-alt" /> Affichage par {{displayType === 0 ? "groupe d'association" : "fournisseur"}}</span>
            <div v-if="displayType === 0">
                <div class="pt-2 pb-2 pr-5 pl-5" v-for="grp in sortedGrps" :key="grp.id">
                    <div class="grp-title d-flex justify-content-between align-items-center">
                        <div v-if="grp.id !== updateGrpId" class="grp-title-header">
                            <h6>{{ grp.name }}</h6>
                            <button class="edit-icon" @click="updateGrpId = grp.id;updateGrpTitle = grp.name">
                                <i class="fa-solid fa-pen" />
                            </button>
                        </div>
                        <div v-else class="grp-title-header grp-update-container">
                            <input class="form-control" type="text" v-model="updateGrpTitle" />
                            <button class="valid-update-icon btn btn-success" @click="updateGroup">Valider</button>
                            <button class="cancel-update-icon btn btn-warning" @click="updateGrpId  = null; updateGrpTitle = ''">Annuler</button>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="toggler p-3" @click="grp.open = !grp.open">
                                <i class="fas" :class="grp.open ? 'fa-sort-up' : 'fa-sort-down'" />
                            </div>
                            <div v-if="grp.assocItems.length == 0">
                                <button class="btn btn-danger" @click="deleteGrp(grp.id)">X</button>
                            </div>
                        </div>
                    </div>
                    <div class="content" v-if="grp.open">
                        <table class="table table-responsive">
                            <tr>
                                <th>Fournisseur</th>
                                <th>Gamme</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr v-for="item of grp.assocItems" :key="`${item.supplierName}-${item.gammeName}`">
                                <td>
                                    <select class="form-control" v-model="item.supplierModel" @change="item.gammeModel = ''">
                                        <option v-for="(supplier, frsGuid) in frs" :value="frsGuid" :key="frsGuid">{{supplier.FrsName}}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-control" v-model="item.gammeModel" v-if="item.supplierModel in frs">
                                        <option v-for="gamme in filterGammes(frs[item.supplierModel].gammes)" :value="gamme.Id" :key="gamme.Id">{{gamme.Label + (gamme.HaveCA ? "" : " (Pas de CA)")}}</option>
                                    </select>
                                </td>
                                <td><button class="btn btn-success" @click="updateItem(item.rowId, item.supplierModel, item.gammeModel)">Enregistrer</button></td>
                                <td><button class="btn btn-danger" @click="deleteItem(item.rowId, grp.id)">Supprimer</button></td>
                                <td class="d-flex align-items-center"><span v-if="!gammeHasCa(frs[item.supplierModel] ? frs[item.supplierModel].gammes : [], item.gammeModel)" class="text-danger">Pas de CA</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <select class="form-control" v-model="grp.supplierModel" @change="grp.gammeModel = ''">
                                        <option v-for="(supplier, frsGuid) in frs" :value="frsGuid" :key="frsGuid">{{supplier.FrsName}}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-control" v-if="grp.supplierModel" v-model="grp.gammeModel">
                                        <option v-for="gamme in filterGammes(frs[grp.supplierModel].gammes)" :value="gamme.Id" :key="gamme.Id">{{gamme.Label + (gamme.HaveCA ? "" : " (Pas de CA)")}}</option>
                                    </select>
                                </td>
                                <td colspan="2"><button class="w-100 btn btn-dark" @click="addItem(grp.id, grp.supplierModel, grp.gammeModel)">Ajouter</button></td>
                                <td class="d-flex align-items-center"><span v-if="frs[grp.supplierModel] && !gammeHasCa(frs[grp.supplierModel].gammes, grp.gammeModel)" class="text-danger">Pas de CA</span></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div v-else class="pb-2 pt-2 pl-5 pr-5 frs-based">
                <SearchFrs @selection="selectedFrs = $event" />
                <div v-if="selectedFrs != null">
                    <h2>Fournisseur sélectionné : {{selectedFrs.Name}}</h2>
                    <div class="pt-2 pb-2 pr-5 pl-5" v-for="(grp, grpId) in selectedFrsGrps" :key="grpId">
                        <div class="grp-title d-flex justify-content-between align-items-center">
                            <h6>{{ grp.name }}</h6>
                            <div class="toggler p-3" @click="grp.open = !grp.open">
                                <i class="fas" :class="grp.open ? 'fa-sort-up' : 'fa-sort-down'" />
                            </div>
                        </div>
                        <div class="content" v-if="grp.open">
                            <table class="table table-responsive">
                                <tr>
                                    <th>Gamme</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr v-for="item of grp.filteredAssocItems" :key="`${item.supplierName}-${item.gammeName}`">
                                    <td>
                                        <select class="form-control" v-model="item.gammeModel" v-if="selectedFrs.Guid in frs">
                                            <option v-for="gamme in filterGammes(frs[selectedFrs.Guid].gammes)" :value="gamme.Id" :key="gamme.Id">{{gamme.Label}}</option>
                                        </select>
                                    </td>
                                    <td><button class="btn btn-success" @click="updateItem(item.rowId, selectedFrs.Guid, item.gammeModel)">Enregistrer</button></td>
                                    <td><button class="btn btn-danger" @click="deleteItem(item.rowId, grpId)">Supprimer</button></td>
                                </tr>
                                <tr>
                                    <td>
                                        <select class="form-control" v-model="grp.gammeModel">
                                            <option v-for="gamme in filterGammes(frs[selectedFrs.Guid].gammes)" :value="gamme.Id" :key="gamme.Id">{{gamme.Label}}</option>
                                        </select>
                                    </td>
                                    <td colspan="2"><button class="w-100 btn btn-dark" @click="addItem(grpId, selectedFrs.Guid, grp.gammeModel)">Ajouter</button></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div>
                    <h6 id="titre-add-grp">Ajouter dans un nouveau groupe</h6>
                    <table class="table table-responsive">
                        <tr></tr>
                        <tr>
                            <td>
                                <select class="form-control" v-model="newSelectedGrp">
                                    <option v-for="(grpInfo, grpId) of grps" :value="grpId" :key="grpId">{{grpInfo.name}}</option>
                                </select>
                            </td>
                            <td>
                                <select class="form-control" v-model="newSelectedGamme">
                                    <option v-for="gamme in filterGammes(frs[selectedFrs.Guid].gammes)" :value="gamme.Id" :key="gamme.Id">{{gamme.Label}}</option>
                                </select>
                            </td>
                            <td>
                                <button class="btn btn-success" @click="addItem(newSelectedGrp, selectedFrs.Guid, newSelectedGamme)">Ajouter</button>
                            </td>
                        </tr>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="card shadow mb-4">
            <!-- Card Header - Dropdown -->
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h5>Ajouter des groupes d'associations</h5>
            </div>
            <!-- Card Body -->
            <div>
                <div>
                    <h6 id="titre-add-grp">Ajouter un groupe</h6>
                    <table class="table table-responsive">
                        <tr></tr>
                        <tr>
                            <td><input type="text" class="form-control" placeholder="Nom du groupe" v-model="newGrpModel" /></td>
                            <td>
                                <button class="btn btn-success" @click="addGroup(newGrpModel)">Ajouter</button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store, mutations } from "../store/store";
    import { fetchApi, postApi } from "@/utils";

    import SearchFrs from "./SearchFrs.vue";

    export default {
        name: 'Association',
        components: {
            SearchFrs
        },
        data: function () {
            return {
                app: store,
                mutations,
                grps: null,
                frs: null,
                selectedFrs: null,
                newSelectedGrp: null,
                newSelectedGamme: null,
                newGrpModel: '',
                displayType: 0,
                updateGrpTitle: "",
                updateGrpId: null
            };
        },
        watch: {
            idPeriode() {
                this.fetchData();
                this.mutations.initFournisseurs();
            }
        },
        methods: {
            gammeHasCa: function(gammeList, gammeId) {
                const gamme = gammeList.find(gamme => gamme.Id === gammeId);
                return gamme && gamme.HaveCA;
            },
            filterGammes: function (gammes) {
                return gammes;
                // if (this.app.calculatorFilters.idPeriode === 19) return gammes
                // return gammes.filter(el => {
                //     let res;
                //     if (gammes.findIndex(_el => _el.Id !== 0 && _el.Id !== 20) === -1)
                //         res = el.Id === 0
                //     else res = el.Id !== 0 && el.Id !== 20
                //     return res;
                // })
            },
            updateItem: function (id, newSupplier, newGamme) {
                const that = this;
                const formData = new FormData();
                formData.append('assocId', id);
                formData.append('frsGuid', newSupplier);
                formData.append('gammeId', newGamme);
                postApi("assoc/update", formData, "admin")
                    .then(() => that.fetchData())
            },
            deleteItem: function (id) {
                const that = this;
                fetchApi("assoc/delete?assocId=" + id, {}, "admin")
                    .then(() => {
                        that.fetchData();
                    })
            },
            addItem: function (grp, supplier, gamme) {
                const that = this;
                const formData = new FormData();
                formData.append('grpId', grp);
                formData.append('frsGuid', supplier);
                formData.append('gammeId', gamme);
                postApi("assoc/add", formData)
                    .then(() => {
                        that.fetchData();
                    })
            },
            addGroup: function (grpName) {
                const that = this;
                const formData = new FormData();
                formData.append('grpName', grpName);
                formData.append('periodId', this.app.calculatorFilters.idPeriode);
                postApi("assoc/addGrp", formData)
                    .then(() => {
                        that.fetchData();
                    })
            },
            updateGroup: function () {
                const that = this;
                const formData = new FormData();
                formData.append('grpId', this.updateGrpId);
                formData.append('grpName', this.updateGrpTitle);
                postApi("assoc/updateGrp", formData)
                    .then(() => {
                        that.updateGrpId = null;
                        that.updateGrpTitle = "";
                        that.fetchData();
                    })
            },
            deleteGrp: function (id) {
                const that = this;
                fetchApi("assoc/deleteGrp?grpId=" + id, {}, "admin")
                    .then(() => that.fetchData())
            },

            fetchData() {
                if (this.app.calculatorFilters.idPeriode == -1) return;

                fetchApi("assoc?idPeriode=" + this.app.calculatorFilters.idPeriode, {}, "maps").then((response) => {
                    const { data } = response;
                    this.frs = {};
                    data.frs.forEach(item => {
                        this.frs[item.FrsGuid] = item.infos;
                    });

                    let res = {}
                    data.grps.forEach(row => {
                        if (!(row.GroupeId in res)) res[row.GroupeId] = {
                            name: row.GroupeName,
                            id: row.GroupeId,
                            open: this.grps && this.grps[row.GroupeId] ? this.grps[row.GroupeId].open : false,
                            assocItems: [],
                            supplierModel: "",
                            gammeModel: ""
                        }
                        if (row.Id >= 0)
                            res[row.GroupeId].assocItems.push({
                                rowId: row.Id,
                                supplierName: row.FrsName,
                                supplierGuid: row.FrsGuid,
                                gammeName: row.GammeName,
                                gammeId: row.GammeId,
                                supplierModel: row.FrsGuid,
                                gammeModel: row.GammeId
                            })
                    });
                    this.grps = res;
                })
            }
        },
        computed: {
            sortedGammes() {
                return this.gammes.slice().sort((a, b) => a.Label < b.Label ? -1 : 1);
            },
            sortedGrps() {
                if (!this.grps) return null;
                let res = [];
                for (let key of Object.keys(this.grps)) {
                    res.push(this.grps[key])
                }
                if (res.length === 0) return null;
                return res.sort((a, b) => a.name < b.name ? -1 : 1);
            },
            selectedFrsGrps() {
                let res = {}
                Object.keys(this.grps)
                    .filter(grpId => (this.grps[grpId].assocItems
                        .findIndex(assoc => assoc.supplierGuid === this.selectedFrs.Guid) >= 0))
                    .forEach(grpId => {
                        res[grpId] = this.grps[grpId];
                        res[grpId].filteredAssocItems = res[grpId].assocItems.filter(assoc => assoc.supplierGuid === this.selectedFrs.Guid);
                    })
                return res;
            },
            idPeriode() {
                return this.app.calculatorFilters.idPeriode;
            }

        },
        mounted: function () {
            this.fetchData();
            this.mutations.initFournisseurs();
        }
    }
</script>

<style scoped>
    .association-container {
        display: inline-block;
        width: calc(100% - 250px);
        margin: 0 auto;
        padding: 12px 42px;
        background-color: #F3F3F3;
        height: 94vh;
        overflow-y: scroll;
    }

    h1 {
        color: var(--primary);
        text-align: center;
        font-size: 2.5rem;
        font-weight: 100;
    }

    .grp-title-header .edit-icon {
        opacity: 0;
        transition: all ease 0.3s;

        background: transparent;
        border: none;

        display: block;
        cursor: pointer;
    }

    .grp-title-header .edit-icon:hover {
        color: var(--primary);
    }

    .grp-title:hover .grp-title-header .edit-icon {
        opacity: 1;
    }

    .grp-title-header.grp-update-container button {
    }

    .grp-title-header {
        display: flex;
        align-items: center;
        column-gap: 2rem;
    }

    #titre-add-grp {
        margin-top: 18px;
        margin-left: 15px;
    }

    h5 {
        color: var(--primary);
        font-weight: 100;
        margin: 0;
    }

    div.card > div > div {
        border-bottom: solid 1px #DEDEDE;
    }

    .frs-based h2 {
        font-size: 2em;
        font-weight: bold;
    }
    .switch {
        color: #999;
        transition: all ease 0.3s;
        margin-left: 2em;
        margin-top: 12px;
    }

    .switch i {
        font-size: 1.3em;
        display: inline-block;
        color: black;
        position: relative;
    }

    .switch:hover {
        color: black;
        cursor: pointer;
    }

    div.card > .btn {
        border-radius: 0;
    }

</style>