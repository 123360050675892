<template>
  <div class="sidebar white-box">
    <ul>
      <li @mousedown="resetMap('app', 0)">
        <router-link :to="{ path: '/', query: $route.query }" data-v-step="7">
          <img src="@/assets/images/logo.png" alt="CMEM" />
        </router-link>
      </li>
      <li>
        <router-link :to="{ path: '/', query: $route.query }" :class="{ active: $route.path == '/' }">
          <font-awesome-icon icon="map-marked-alt" />
          Carto
        </router-link>
      </li>
      <li v-if="app.self.isOdw &&
        app.calculatorFilters.isClient == 1 &&
        !app.self.isDepFilter
        ">
        <router-link :to="{ path: '/results/charts', query: $route.query }"
          :class="{ active: $route.path.startsWith('/results') }">
          <font-awesome-icon icon="th-list" />
          Resultats
        </router-link>
      </li>
      <li v-if="app.self.isOdw &&
        (app.self.accessDor || app.self.isAdmin) &&
        app.calculatorFilters.isClient == 1
        ">
        <router-link :to="{ path: '/detailed', query: $route.query }"
          :class="{ active: $route.path.startsWith('/detailed') }">
          <font-awesome-icon icon="chart-area" />
          Détaillé
        </router-link>
      </li>
      <li v-if="app.calculatorFilters.isClient == 1 && !app.self.isDepFilter">
        <router-link :to="{ path: '/zones/pdm', query: $route.query }"
          :class="{ active: $route.path.startsWith('/zones') }">
          <font-awesome-icon icon="globe-europe" />
          Zones
        </router-link>
      </li>
      <li v-if="app.self.accessDonneesExternes || app.self.isAdmin">
        <router-link :to="{ path: '/external/marche', query: $route.query }"
          :class="{ active: $route.path.startsWith('/external') }">
          <font-awesome-icon icon="database" />
          Données Externes
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { store, mutations } from "./store/store";

export default {
  name: "Sidebar",
  data() {
    return {
      app: store,
    };
  },
  methods: {
    resetMap(scope, bounds) {
      mutations.resetMap(scope, bounds);
    },
  },
};
</script>

<style scoped>
.sidebar {
  width: 100%;
  height: 100%;
  border-radius: 0;
  padding: 12px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  /*font-size: 1.5em;*/
}

.sidebar li a {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #cae8e0;
  font-size: 1rem;
  transition: all ease 230ms;
}

.sidebar li a.active,
.sidebar li a:hover {
  color: var(--primary);
}

.sidebar li a svg {
  font-size: 1.75rem;
  /*font-size: 1.5em;*/
}

.sidebar li img {
  max-width: 100%;
}

.sidebar li:first-child {
  padding-bottom: 12px;
  border-bottom: 1px solid #f3f3f3;
}</style>
