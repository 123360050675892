<template>
    <div class="">
        <div class="header">
            <h2>Évolution du chiffre d'affaires</h2>
        </div>
        <div class="graphClients">
            <div style="width:75%;margin-left: auto;margin-right: auto;">
                <canvas height="300" width="200" id="graph-evolution-ca"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
//import Vue from "vue";
import { store } from "../store/store";
import { dataFinanceMutations } from "../store/dataFinance";
// import { VueGoodTable } from "vue-good-table-next";
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Chart from 'chart.js/auto';
//import Vue from "vue";
import { getRandomColor } from "@/utils";

export default {
    name: 'DorEvolutionCa',
    components: {
    },
    data() {
        return {
            app: store,
            data: [],
            labels: [],
        }
    },
    mounted() {
        // On crée le graph
        if (this.app.financier.evolutionCa.loaded != this.app.self.guid) {
            dataFinanceMutations.loadEvolutionCa().then(() => {
                this.initChart("graph-evolution-ca");
            });
        } else {
            this.initChart("graph-evolution-ca");
        }
    },
    watch: {
        //Lorsqu'un filtre est modifié et cause une actualisation des résultats financiers
        "app.financier.evolutionCa.loaded": function () {
            if (this.app.financier.results.CaSelf)
                this.initChart("graph-evolution-ca");
        }
    },
    methods: {
        formatNumberEur(number) { //format de l'euro
            return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(number);
        },

        initChart(chartId) {
            this.generateChart(chartId);
        },
        generateChart(chartId) { //Génère le graphique
            if (store.financier.evolutionCa.loaded != store.self.guid) return;

            if (store.charts[chartId]) {
                store.charts[chartId].destroy();
                store.charts[chartId] = null;
            }
            const canvas = document.getElementById(chartId);
            store.charts[chartId] = new Chart(canvas, {
                type: 'line',
                data: {

                    labels: store.financier.evolutionCa.data.map(d => d.year),
                    datasets: [
                        {
                            label: "Mon CA ",
                            data: store.financier.evolutionCa.data.map(d => d.ca),
                            backgroundColor: getRandomColor(1),
                            fill: false
                        },
                    ],
                    styling: {
                        borderWidth: 0
                    }
                },
                options: {
                    tension: 0.4,
                    animation: false,
                    scales: {
                        y: {
                            ticks: {
                                beginAtZero: true,
                                callback: function (label) {
                                    return "" + Number(label).toLocaleString("fr-FR", { minimumFractionDigits: 0 }) + "";
                                }
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    return Number(context.raw).toLocaleString("fr-FR", { minimumFractionDigits: 0 }) + " €";
                                }
                            }
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,

                }
            });
        }
    }
}
</script>

<style></style>