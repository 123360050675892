<template>
    <div>
        <div class="header">
            <h2 class="d-flex align-items-center">
                Indicateurs de carte
                <span class="ml-3" style="color: black; vertical-align: center;"><font-awesome-icon icon="info-circle" size="1x" v-on:click="displayInfos=true" style="vertical-align:center;" /></span>
            </h2>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th>Région</th>
                    <th v-if="$route.path == '/zones/pdm'">PDM</th>
                    <th v-if="$route.path == '/zones/pdm'">PDM Concurrents</th>
                    <th v-if="$route.path == '/zones/tp'">TP</th>
                    <th v-if="$route.path == '/zones/pm'">PM</th>
                    <th v-if="$route.path == '/zones/pm'">PM marché</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="r of regions" :key="r.IdNational">
                    <td>{{r.Name}}</td>
                    <td v-if="$route.path == '/zones/pdm'">{{pdmPerRegion[r.IdNational] ? formatPercent(pdmPerRegion[r.IdNational][0]) : 0}}</td>
                    <td v-if="$route.path == '/zones/pdm'">{{pdmPerRegion[r.IdNational] ? formatPercent(pdmPerRegion[r.IdNational][1]) : 0}}</td>
                    <td v-if="$route.path == '/zones/tp'">{{tpPerRegion[r.IdNational] ? formatPercent(tpPerRegion[r.IdNational][0]) : 0}}</td>
                    <td v-if="$route.path == '/zones/pm'">{{pmPerRegion[r.IdNational] ? formatEur(pmPerRegion[r.IdNational][0]) : 0}}</td>
                    <td v-if="$route.path == '/zones/pm'">{{pmPerRegion[r.IdNational] ? formatEur(pmPerRegion[r.IdNational][1]) : 0}}</td>
                </tr>
            </tbody>
        </table>
        <Modal title="Légende" @close="displayInfos=false" :open="displayInfos">
            Rouge : 0% - 25% <br />
            Orange : 25% - 50%<br />
            Jaune : 50% - 75%<br />
            Vert : 75% - 100%
        </Modal>
    </div>
</template>

<script>
    import { store } from "./store/store";

    export default {
        name: "ZoneResults",
        data() {
            return {
                displayInfos: false
            }
        },
        computed: {
            dataRegion() {
                return store.financier.details.region;
            },
            dataRegionSelf() {
                return store.financier.details.self.region;
            },
            regions() {
                return store.dataMap.regions.filter(r => r.IdNational != '01' && r.IdNational != '02' && r.IdNational != '03' && r.IdNational != '04' && r.IdNational != '06').sort((a, b) => a.Name < b.Name ? -1 : 1);
            },
            pdmPerRegion() {
                let res = {};

                if (!this.dataRegionSelf || !this.dataRegion) return res;
                store.dataMap.regions.forEach(r => {
                    const selfCa = this.dataRegionSelf[r.IdNational] ? this.dataRegionSelf[r.IdNational].total : 0;
                    const regionTot = this.dataRegion[r.IdNational] ? this.dataRegion[r.IdNational].total : 0;
                    const pdm = regionTot !== 0 ? (selfCa / regionTot) * 100 : 0;
                    res[r.IdNational] = [pdm, 100 - pdm];
                });

                return res;
            },
            tpPerRegion() {
                let res = {};

                if (!this.dataRegionSelf || !this.dataRegion) return res;
                store.dataMap.regions.forEach(r => {
                    const self = this.dataRegionSelf[r.IdNational] ? this.dataRegionSelf[r.IdNational].NbFactures : 0;
                    const regionTot = this.dataRegion[r.IdNational] ? this.dataRegion[r.IdNational].NbFactures : 0;
                    const tp = regionTot !== 0 ? (self / regionTot) * 100 : 0;
                    res[r.IdNational] = [tp, 100 - tp];
                });

                return res;
            },
            pmPerRegion() {
                let res = {};

                if (!this.dataRegionSelf || !this.dataRegion) return res;
                store.dataMap.regions.forEach(r => {
                    const selfFactures = this.dataRegionSelf[r.IdNational] ? this.dataRegionSelf[r.IdNational].NbFactures : 0;
                    const regionTotFactures = this.dataRegion[r.IdNational] ? this.dataRegion[r.IdNational].NbFacturesDoublons : 0;

                    const selfCa = this.dataRegionSelf[r.IdNational] ? this.dataRegionSelf[r.IdNational].total : 0;
                    const regionTotCa = this.dataRegion[r.IdNational] ? this.dataRegion[r.IdNational].total : 0;

                    const selfPM = selfFactures !== 0 ? selfCa / selfFactures : 0;
                    const regionPM = regionTotFactures !== 0 ? regionTotCa / regionTotFactures : 0;
                    res[r.IdNational] = [selfPM, regionPM];
                });

                return res;
            }
        },
        methods: {
            formatPercent(val) {
                return new Intl.NumberFormat('fr-FR', { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(val / 100);
            },
            formatEur(val) {
                return new Intl.NumberFormat('fr-FR', { style: "currency", currency: "EUR", maximumFractionDigits: 0 }).format(val);
            }
        },
    }
</script>

<style scoped>

</style>