<template>
  <div id="download-container" class="white-box">
    <a class="btn btn-cmem" href="/Restitution_potentiels_fournisseurs.xlsx" download>Télécharger les données</a>
  </div>
  <!-- <div class="external-results-container">
    <ul class="tab-nav white-box">
      <li :class="{ active: $route.path === '/external/marche' }">
        <router-link   :to="{ path: '/external/marche', query: $route.query }">
          <div class="icon">
            <font-awesome-icon icon="magnifying-glass-chart" />
          </div>
          Données marché
        </router-link>
      </li>
    </ul>
    <div class="external-results-content">
      <router-view></router-view>
    </div>
  </div> -->
</template>

<script>
import { store } from "../../components/store/store";
import { VueApp as Vue } from "@/main.js";
// import { dataFinanceMutations } from "../../components/store/dataFinance";

export default {
  name: "External",
  data() {
    return { app: store };
  },
  mounted() {
    if (Vue.$router.currentRoute.value.path.startsWith("/external") && !this.app.self.accessDonneesExternes && !this.app.self.isAdmin)
      Vue.$router.replace("/");
    this.init();
  },
  methods: {
    init() {
      // if (this.app.financier.details.loaded != this.app.self.guid)
      //   dataFinanceMutations.loadDataFinanceDetails();
    },
  },
};
</script>

<style scoped>
/* #download-container {
  display: grid;
  place-items: center;
} */

.external-results-container {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  /*row-gap: 24px;*/
}

.external-results-content {
  flex-grow: 1;
  overflow-y: auto;
}
</style>
