<template>
  <div class="prosperence-container">
    <div class="white-box prosperence-filters">
      <div class="header">
        <h2>Filtres</h2>
      </div>
      <ul v-if="category.filterIsList">
        <li v-for="option in category.filterOptions" :key="option.value">
          <label>
            <input v-if="category.filterIsCheckbox" class="checkbox-cmem" type="checkbox" :value="option.value"
              v-model="category.filterValue" />
            <input v-if="!category.filterIsCheckbox" class="radio-cmem" type="radio" :value="option.value"
              v-model="category.filterValue" />
            {{ option.label }}
          </label>
        </li>
      </ul>
      <div v-else-if="category.filterIsCustom">
        <h3>Code APE</h3>
        <multiselect v-model="category.filterValue.codeApe" :options="apeOptions.map(c => c.value)"
          :custom-label="c => getApeLabel(c)" :searchable="true" :multiple="true" placeholder="Choisissez un/des code APE"
          selectLabel="Appuyez sur Entrée pour sélectionner" selectedLabel="Sélectionné"
          deselectLabel="Appuyez sur Entrée pour enlever"></multiselect>
        <hr />
        <h3>Effectifs</h3>
        <ul>
          <li v-for="option in effectifOptions" :key="option.value">
            <label>
              <input class="checkbox-cmem" type="checkbox" :value="option.value"
                v-model="category.filterValue.effectif" />
              {{ option.label }}
            </label>
          </li>
        </ul>
        <hr />
        <h3>Classification</h3>
        <ul>
          <li>
            <label>
              <input class="checkbox-cmem" type="checkbox" value="G" v-model="category.filterValue.classification" />
              Généralistes
            </label>
          </li>
          <li>
            <label>
              <input class="checkbox-cmem" type="checkbox" value="S" v-model="category.filterValue.classification" />
              Spécialistes
            </label>
          </li>
        </ul>
      </div>
    </div>
    <div class="prosperence-content">
      <div class="prosperence-category-nav">
        <ul class="tab-nav white-box">
          <li v-for="category in Object.values(categories)" :key="category.value"
            :class="{ active: selectedCategory == category.value }" @click="updateCategory(category.value)">
            <a href="#">
              {{ category.label }}
            </a>
          </li>
        </ul>
      </div>
      <MapProsperence :scope="selectedCategory" :filter="JSON.stringify(category.filterValue)" />
    </div>
  </div>
</template>

<script>
import { fetchApiWithCache } from "../../../utils";
import MapProsperence from "./MapProsperence.vue";
import { getData } from "./map.js";

export default {
  name: "Prosperence",
  components: {
    MapProsperence,
  },
  data: function () {
    return {
      selectedCategory: "nbEntreprises",
      filter: null,
      apeOptions: [],
      effectifOptions: [
        { value: "", label: "Inconnu" },
        { value: "0 salarié", label: "0 salarié" },
        { value: "1 ou 2 salariés", label: "1 ou 2 salariés" },
        { value: "3 à 5 salariés", label: "3 à 5 salariés" },
        { value: "6 à 9 salariés", label: "6 à 9 salariés" },
        { value: "10 à 19 salariés", label: "10 à 19 salariés" },
        { value: "20 à 49 salariés", label: "20 à 49 salariés" },
        { value: "50 à 99 salariés", label: "50 à 99 salariés" },
        { value: "Etablissement non employeur", label: "Etablissement non employeur" },
      ],
      categories: {
        nbEntreprises: {
          label: "Nombre Entreprises",
          value: "nbEntreprises",
          filterIsCustom: true,
          filterValue: {
            codeApe: ["43.33Z"],
            effectif: [""],
            classification: ["G"],
          }
        },
        potentiel: {
          label: "Potentiel Marché",
          value: "potentiel",
          filterValue: ["G"],
          filterIsList: true,
          filterIsCheckbox: true,
          filterOptions: [
            { label: "Généralistes", value: "G" },
            { label: "Spécialistes", value: "S" },
          ]
        },
        classificationDPE: {
          label: "Classification DPE",
          value: "classificationDPE",
          filterValue: ["A"],
          filterIsList: true,
          filterIsCheckbox: true,
          filterOptions: [
            { label: "DPE A", value: "A" },
            { label: "DPE B", value: "B" },
            { label: "DPE C", value: "C" },
            { label: "DPE D", value: "D" },
            { label: "DPE E", value: "E" },
            { label: "DPE F", value: "F" },
            { label: "DPE G", value: "G" },
          ]
        },
        nbTransactions: {
          label: "Nombre de transactions",
          value: "nbTransactions",
          filterValue: "cumul",
          filterIsList: true,
          filterIsCheckbox: false,
          filterOptions: [
            { label: "Cumul", value: "cumul" },
            { label: "Moyenne par propriétaire", value: "moyenne" },
          ]
        },
        nbM2: {
          label: "Nombre de M2 construits",
          value: "nbM2",
          filterValue: "cumul",
          filterIsList: true,
          filterIsCheckbox: false,
          filterOptions: [
            { label: "Cumul", value: "cumul" },
            { label: "Moyenne par propriétaire", value: "moyenne" },
          ]
        },
        nbProprio: {
          label: "Nombre de propriétaires",
          value: "nbProprio",
          filterValue: "",
        },
      },
    };
  },
  mounted() {
    fetchApiWithCache("api/prosperence/ape-options").then(res => {
      this.apeOptions = res.data;
    })
  },
  computed: {
    category() {
      return this.categories[this.selectedCategory];
    },
  },
  methods: {
    async updateCategory(newCategory) {
      // On met à jour la catégorie (scope) sélectionnée
      this.selectedCategory = newCategory;
      // On défini la valeur des filtres par défaut
      this.filter = this.categories[newCategory].filterValue;
      // On récupère les données en fonction du scope et des filtres sélectionnés (par défaut)
      await getData(this.selectedCategory, JSON.stringify(this.filter));
    },
    getApeLabel(value) {
      const obj = this.apeOptions.find(a => a.value == value);
      if (!obj) return "";
      return "(" + obj.value + ") " + obj.label;
    }
  },
};
</script>

<style scoped>
.prosperence-container {
  display: grid;
  grid-template-columns: 250px 1fr;
  height: 100%;
  gap: 24px;
}

.prosperence-filters {
  max-height: 100%;
  overflow-y: auto;
}

.prosperence-filters ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.prosperence-content {
  display: flex;
  flex-direction: column;
}

.prosperence-map {
  flex-grow: 1;
}
</style>
