<script>
    import { store, mutations } from "./store/store";

    export default {
        name: 'popup',

        props: {
            items: {
               
            },
        },
        data() {
            return {
                app: store
            };
        },
        methods: {
            switchDisplayPopup() {
                mutations.switchFilters("messagePopup");
            }
        }
    };
</script>

<template>
    <div class="popup" v-if="app.dataDisplay.popupMessage">
        <div class="popup-content">
            <h3>Attention</h3>
            <hr />
            <a href="#" class="close" v-on:click="switchDisplayPopup()">X</a>
            <p>Si vos commerciaux n'apparaissent pas correctement ou que les départements ne sont pas bons, nous vous invitons à corriger ces derniers sur votre page Intranet.</p>
        </div>
    </div>

</template>

<style>
    .popup {
        position: absolute;
        z-index: 999999;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(155, 155, 155, 0.8);
    }

        .popup > .popup-content {
            margin: 70px auto;
            padding: 20px;
            background: #fff;
            border-radius: 5px;
            width: 30%;
            position: relative;
        }

            .popup > .popup-content > h3 {
                color: var(--primary);
                font-weight: 100;
            }

            .popup > .popup-content > p {
                padding: 0;
            }

            .popup > .popup-content > a.close {
                position: absolute;
                top: 20px;
                right: 30px;
                transition: all 200ms;
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
                color: #000000;
            }
</style>
