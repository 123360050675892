<template>
    <div id="groupements">
        <h3>Groupements
            <span class="ml-1"><font-awesome-icon icon="info-circle" size="1x" v-on:click="modalOpen=true" /></span>
        </h3>
        <div>
            <multiselect v-model="selectedGrps"
                         :options="app.dataAdherents.centrales"
                         trackBy="Guid"
                         :custom-label="c => c.Name"
                         :closeOnSelect="false"
                         :searchable="true"
                         :multiple="true"
                         placeholder="Choisissez un/des groupement(s)"
                         selectLabel="Appuyez sur Entrée pour sélectionner"
                         selectedLabel="Sélectionné"
                         deselectLabel="Appuyez sur Entrée pour enlever"
                         @open="selectOpen = true"
                         @close="selectOpen = false; centraleChanged();"
                         @input="centraleChanged"></multiselect>
        </div>
        <Modal title="Informations" :open="modalOpen" @close="modalOpen = false" class="md-modal">
            <ul class="centrales-def-list">
                <li v-for="c in app.dataAdherents.centrales" :key="c.Id">
                <img :src="c.Marker" alt="" />{{ c.Name }}
                </li>
            </ul>
        </Modal>
    </div>
</template>
<script>
    import { store } from "../store/store"
    import { dataFinanceMutations } from "../store/dataFinance";
    import { pointDeVenteMutations } from "../store/pointDeVente";
    import Modal from "../Modal.vue";

    export default {
        name: "GroupementFilter",
        components: {
            Modal
        },
        data() {
            return {
                app: store,
                selectOpen: false,
                modalOpen: false,
                selectedGrps: []
            }
        },
        watch: {
            "app.calculatorFilters.adhs": {
                handler() {
                    this.selectedGrps = this.app.calculatorFilters.adhs
                        .map(grpGuid => {
                            return this.app.dataAdherents.centrales.find(c => c.Guid == grpGuid);
                        })
                        .filter(c => c != undefined);
                }
            }
        },
        methods: {
            compareAdhs() {
                let equal = true;
                if (this.app.calculatorFilters.adhs.length !== this.selectedGrps.length) return false;
                for (let i = 0; i < this.selectedGrps.length; i++) {
                    if (this.app.calculatorFilters.adhs.indexOf(this.selectedGrps[i].Guid) === -1) equal = false;
                }
                return equal;
            },
            // Changement de centrale
            centraleChanged() {
                if (this.compareAdhs() || this.selectOpen) return;
                this.app.calculatorFilters.adhs = this.selectedGrps.map(c => c.Guid);
                if (this.app.self.isOdw)
                    dataFinanceMutations.loadDataFinance();
                else
                    pointDeVenteMutations.loadPdv();
            },
        }
    }
</script>

<style>
    .centrales-def-list {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .centrales-def-list li:not(:last-child) {
        margin-bottom: 1rem;
    }

    .centrales-def-list img {
        width: 2em;
        margin-right: 12px;
    }
</style>