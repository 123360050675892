<template>
    <div class="detailed-container">
        <ul class="tab-nav white-box">
            <li :class="{active: $route.path === '/detailed'}">
                <router-link   :to="{ path: '/detailed', query: $route.query }">
                    <div class="icon">
                        <font-awesome-icon icon="user-tag" />
                    </div>
                    Top Commerciaux
                </router-link>
            </li>
            <li :class="{active: $route.path === '/detailed/indicators'}">
                <router-link   :to="{ path: '/detailed/indicators', query: $route.query }">
                    <div class="icon">
                        <font-awesome-icon icon="chart-line" />
                    </div>
                    Indicateurs
                </router-link>
            </li>
            <li :class="{active: $route.path === '/detailed/client'}">
                <router-link   :to="{ path: '/detailed/client', query: $route.query }">
                    <div class="icon">
                        <font-awesome-icon icon="store-alt" />
                    </div>
                    Analyse clients
                </router-link>
            </li>
            <!--<li :class="{active: $route.path === '/detailed/specialist'}">
                <router-link   :to="{ path: '/detailed/specialist', query: $route.query }">
                    <font-awesome-icon icon="search-location" />
                    Analyse spécialistes
                </router-link>
            </li>-->
            <li :class="{active: $route.path === '/detailed/evolution'}" v-if="app.calculatorFilters.periodeName === 'Déclaration annuelle' && canPrintEvolution === true ">
                <router-link   :to="{ path: '/detailed/evolution', query: $route.query }">
                    <!--<font-awesome-icon icon="sort-numeric-up" />-->
                    <div class="icon">
                        <font-awesome-icon icon="chart-line" />
                    </div>
                    &Eacute;volution CA
                </router-link>
            </li>

            <li :class="{active: $route.path === '/detailed/distribution'}">
                <router-link   :to="{ path: '/detailed/distribution', query: $route.query }">
                    <div class="icon">
                        <font-awesome-icon icon="sort-numeric-up" />
                    </div>
                    Distribution
                </router-link>
            </li>
        </ul>
        <div class="white-box detailed-content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import { store } from "../../components/store/store";
    import { dataFinanceMutations } from "../../components/store/dataFinance";

    //import Vue from 'vue';

    export default {
        name: 'Detailed',
        data() {
            return {
                app: store,
                dataFinanceMutations: dataFinanceMutations,
            }
        },
        mounted() {
            if (!this.app.self.accessDor && !this.app.self.isAdmin) {
                this.$router.replace("/");
            }

            if (this.app.dorDatas.init != this.app.self.guid)
                this.dataFinanceMutations.loadDorDatas();
        },
        computed: {
            canPrintEvolution() {
                return store.perioFourni.annees.length > 1;
            }
        },
    }
</script>

<style scoped>

    .detailed-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: hidden;
    }

    .detailed-content {
        flex-grow: 1;
        overflow-y: auto;
    }
</style>
