////import Vue from "vue";
import "leaflet/dist/leaflet.css";
import { store } from "./store";
import { pointDeVenteMutations } from "./pointDeVente";
import { selectedDeps } from "./map";
import { fetchApi, getRandomColor } from "@/utils";
// import Vue from "vue";
import { VueApp as Vue } from "@/main.js";

export const dataFinanceMutations = {
  // Chargement des données
  loadDataFinance() {
    if (store.calculatorFilters.idPeriode == -1) return;

    // On reset toutes les données financieres
    store.dorDatas.init = false;
    store.financier = {
      results: {},
      hierarchy: {},
      generatedDatas: {},
      pdvDatas: [],
      details: {
        loaded: false,
        full: undefined,
        region: undefined,
        self: {
          groupement: undefined,
          region: undefined,
          caTotal: undefined,
        },
      },
      acomptes: [],
      evolutionCa: {
        loaded: false,
        data: [],
      },
    };

    // Si on doit calculer les indicateurs
    if (
      Vue.$router.currentRoute.value.path.startsWith("/zones") ||
      Vue.$router.currentRoute.value.path.startsWith("/results")
    )
      this.loadDataFinanceDetails();

    // Si on est en fullscreen, on charge directement les données du DOR
    if (
      Vue.$router.currentRoute.value.path.startsWith("/detailed") &&
      (store.self.accessDor || store.self.isAdmin)
    ) {
      // Met à jour les données
      this.loadDorDatas();
    }

    if (
      Vue.$router.currentRoute.value.path.startsWith("/detailed/evolution") &&
      (store.self.accessDor || store.self.isAdmin)
    ) {
      // Met à jour les données
      this.loadEvolutionCa();
    }

    fetchApi(
      "data?geos=" +
        JSON.stringify(selectedDeps()) +
        "&adhs=" +
        JSON.stringify(store.calculatorFilters.adhs) +
        "&tranchesCa=" +
        JSON.stringify(store.calculatorFilters.tranchesCa) +
        "&gammes=" +
        JSON.stringify(store.calculatorFilters.gammes) +
        "&familles=" +
        JSON.stringify(store.calculatorFilters.families) +
        "&guid=" +
        store.self.guid +
        "&isClient=" +
        store.calculatorFilters.isClient +
        "&typo=" +
        store.calculatorFilters.isSpecialiste +
        "&isLnl=" +
        (store.calculatorFilters.lnlAll ? -1 : store.calculatorFilters.isLnl) +
        "&isLS=" +
        (store.calculatorFilters.lnlAll
          ? -1
          : store.calculatorFilters.libreService
          ? 1
          : -1) +
        "&isByLnl=" +
        (store.calculatorFilters.lnlAll
          ? -1
          : store.calculatorFilters.isByLnl) +
        "&idPeriode=" +
        store.calculatorFilters.idPeriode +
        "&dateEntree=" +
        store.calculatorFilters.dateEntree +
        "&isBefore=" +
        store.calculatorFilters.isBeforeDate +
        "&distance=" +
        (store.calculatorFilters.pdvDistance.latlng
          ? store.calculatorFilters.pdvDistance.distance * 1000
          : -1) +
        "&lat=" +
        (store.calculatorFilters.pdvDistance.latlng
          ? store.calculatorFilters.pdvDistance.latlng.lat
          : -1) +
        "&lng=" +
        (store.calculatorFilters.pdvDistance.latlng
          ? store.calculatorFilters.pdvDistance.latlng.lng
          : -1),
      {},
      "drone",
    ).then((response) => {
      //Gestion messages d'erreur (accès ODW) si admin, redirection sinon
      if (store.self.isAdmin == 1) {
        if (response.data.Status == -1)
          alert("Attention, ce fournisseur n'a pas de contrat signé");
        else if (response.data.Status == -2)
          alert("Attention, ce fournisseur n'a pas souscrit à ODW3");
        else if (response.data.Status == -3)
          alert(
            "Attention, l'accès de ce fournisseur n'a pas encore été validé par un CDM",
          );
        else if (response.data.Status == -4)
          alert(
            "Ce fournisseur ne possède qu'un seul concurrent dans cette sélection. Il est donc impossible d'afficher les chiffres",
          );
      } else {
        if (response.data.Status <= -1 && response.data.Status != -4) {
          // On ne peut pas accèder aux données, on redirige vers ODW = 0
          const email = new URL(window.location.href).searchParams.get("email");
          const login = new URL(window.location.href).searchParams.get("login");
          const autologinHash = new URL(window.location.href).searchParams.get(
            "autologinHash",
          );
          const odw = new URL(window.location.href).searchParams.get("odw");
          if (odw == 1) {
            window.location.href = `/?autologinHash=${autologinHash}&login=${login}&email=${email}&odw=0`;
          }
        } else if (response.data.Status == -4) {
          window.alert(
            "Vous ne possedez qu'un concurrent sur cette sélection. Il est donc impossible d'afficher les chiffres",
          );
        }
      }

      if (store.isDemo) {
        store.financier.pdvDatas = response.data.PointDeVenteDatas.map(
          (pdv) => ({
            ...pdv,
            Name: "Bois MTX",
            PdvName: "Bois MTX",
            SocieteName: "Societe Bois MTX",
            City: "Ma ville",
            Address: "99 Rue de la rue",
            Email: "abdefg@hij.fr",
            FaxNumber: "06 00 00 00 00",
            PhoneNumber: "06 00 00 00 00",
            PortablePhoneNumber: "06 00 00 00 00",
            RespFirstName: "Jean",
            RespLastName: "Dupont",
          }),
        );
      } else {
        store.financier.pdvDatas = response.data.PointDeVenteDatas;
      }

      var pdvs = store.financier.pdvDatas.map((pdv) => ({
        IdPdv: pdv.PdvGuid,
        Code8: pdv.IdCompta,
        GroupementId: pdv.GroupeGuid,
        Name: pdv.PdvName,
        NomGroupement: pdv.GroupeName,
        GpsCoordinates: pdv.GPS,
        Type: pdv.IsSiege == 0 ? "S" : "D",
        Siret: pdv.Siret,
        AdressLine1: pdv.Address,
        Departement: pdv.IdDepartement,
        ZipCode: pdv.ZipCode,
        City: pdv.City,
        Ca: pdv.Ca,
        DateEntree: pdv.DateEntree,
        DateSortie: pdv.DateSortie,
        DateEntreeMme: pdv.DateEntreeMme,
        DateSortieMme: pdv.DateSortieMme,
        Email: pdv.Email,
        FaxNumber: pdv.FaxNumber,
        PhoneNumber: pdv.PhoneNumber,
        PortablePhoneNumber: pdv.PortablePhoneNumber,
        IsSpecialiste: pdv.IsSpecialiste,
        IsClient: pdv.IsClient,
        DetailSpe: pdv.DetailSpe,
        IsLnl: pdv.IsLnl,
        IsByLnl: pdv.IsByLnl,
        IsLS: pdv.IsLS,
        RespFirstName: pdv.RespFirstName,
        RespLastName: pdv.RespLastName,
        RespCivility: pdv.RespCivility,
      }));

      store.financier.hierarchy = this.computeHierarchy(
        store.financier.pdvDatas,
      );
      store.financier.results = response.data.Results;
      store.financier.generatedDatas = response.data.GeneratedDatas;

      const nbc = store.financier.results.NbConcurrent;
      const caConcurrents =
        store.financier.results.CaTotal - store.financier.results.CaSelf;
      const caMoyenConcurrents = nbc ? caConcurrents / nbc : 0;
      store.financier.generatedDatas.PdmConcurrents = store.financier.results
        .CaTotal
        ? (
            (caMoyenConcurrents * 100) /
            store.financier.results.CaTotal
          ).toFixed(2)
        : 0;
      store.dataAdherents.pdvsCount = response.data.Results.NbPdv;
      // On ajoute les points de vente concernés par la sélection
      store.dataAdherents.pdvs =
        pointDeVenteMutations.populatePointsDeVente(pdvs);

      this.loadAcomptes(); //Chargement des acomptes
    });
  },
  // Chargement des données détaillées
  loadDataFinanceDetails() {
    let pi = new Promise((resolve) => resolve());
    if (store.calculatorFilters.idPeriode === -1) return pi;
    store.financier.details.loaded = false;
    return new Promise((resolve) => {
      fetchApi(
        "details?gammes=" +
          JSON.stringify(store.calculatorFilters.gammes) +
          "&guid=" +
          store.self.guid +
          "&typo=" +
          store.calculatorFilters.isSpecialiste +
          "&familles=" +
          JSON.stringify(store.calculatorFilters.families) +
          "&isLnl=" +
          (store.calculatorFilters.lnlAll
            ? -1
            : store.calculatorFilters.isLnl) +
          "&isLS=" +
          (store.calculatorFilters.lnlAll
            ? -1
            : store.calculatorFilters.libreService
            ? 1
            : -1) +
          "&isByLnl=" +
          (store.calculatorFilters.lnlAll
            ? -1
            : store.calculatorFilters.isByLnl) +
          "&tranchesCa=" +
          JSON.stringify(store.calculatorFilters.tranchesCa) +
          "&idPeriode=" +
          store.calculatorFilters.idPeriode,
        {},
        "drone",
      ).then((response) => {
        const { data } = response;
        // Utilisés pour le tableau global
        let res = {}; // CA Par groupement, par région (le groupement "self" contient le CA du fournisseur par région filtré (par groupements/spécialiste/etc...))
        //let regionTotals = {};      // Ca total par région
        let resSelf = {}; // CA du fournisseur par groupement
        let regionSelf = {}; // CA/NbPdv/NbPdvDoublon du fournisseur par région
        var caTotal = 0; // CA total du fournisseur
        var countSelfRegion = 0;
        var countSelfGroupement = 0;
        // Utilisé pour le calcul de la Part De Marché (PDM)
        let regionFilteredTotals = {}; // CA/NbPdv/NbPdvDoublon par région excluant les groupements non sélectionnés

        data.forEach((row) => {
          // Remplissage de regionTotals
          //if (!regionTotals[row.RegionId]) {
          //    regionTotals[row.RegionId] = 0;
          //}

          // Remplissage de regionFilteredTotals
          // Si le groupement est sélectionné, ou qu'ils le sont tous (aucun)
          if (!regionFilteredTotals[row.RegionId]) {
            regionFilteredTotals[row.RegionId] = {
              total: 0,
              NbFactures: 0,
              NbFacturesDoublons: 0,
              name: store.dataMap.regions.find(
                (r) => r.IdNational == row.RegionId,
              ).Name,
            };
          }

          if (row.IsSelf == 0) {
            // Remplissage du res
            if (!(row.GroupeGuid in res)) {
              res[row.GroupeGuid] = {};
            }

            res[row.GroupeGuid][row.RegionId] = row.CA;

            if (!res[row.GroupeGuid].total) {
              res[row.GroupeGuid].total = 0;
            }

            res[row.GroupeGuid].total += row.CA;

            // Remplissage regionFilteredTotals
            if (
              store.calculatorFilters.adhs.length === 0 ||
              store.calculatorFilters.adhs.indexOf(row.GroupeGuid) !== -1
            ) {
              regionFilteredTotals[row.RegionId].total += row.CA; // On ajoute son CA sur la région, au CA de la région
              regionFilteredTotals[row.RegionId].NbFactures += row.NbFactures; // Possible car groupement différent => pas les mêmes pdv
              regionFilteredTotals[row.RegionId].NbFacturesDoublons +=
                row.NbFacturesDoublons;
            }
          }

          // Remplissage du resSelf et regionSelf
          if (row.IsSelf == 1) {
            // resSelf
            if (!(row.GroupeGuid in resSelf)) {
              resSelf[row.GroupeGuid] = {};
            }
            resSelf[row.GroupeGuid][row.RegionId] = row.CA;

            if (!resSelf[row.GroupeGuid].total) {
              resSelf[row.GroupeGuid].total = 0;
            }
            resSelf[row.GroupeGuid].total += row.CA;

            if (!resSelf[row.GroupeGuid].name) {
              resSelf[row.GroupeGuid].name = store.dataAdherents.centrales.find(
                (c) => c.Guid == row.GroupeGuid,
              ).Name;
              resSelf[row.GroupeGuid].backgroundColor =
                getRandomColor(countSelfGroupement);
              countSelfGroupement++;
            }

            // regionSelf
            if (!(row.RegionId in regionSelf)) {
              regionSelf[row.RegionId] = {
                total: 0,
                NbFactures: 0,
                idNational: row.RegionId,
                name: store.dataMap.regions.find(
                  (r) => r.IdNational == row.RegionId,
                ).Name,
                backgroundColor: getRandomColor(countSelfRegion),
              };
              countSelfRegion++;
            }

            if (
              store.calculatorFilters.adhs.length === 0 ||
              store.calculatorFilters.adhs.indexOf(row.GroupeGuid) !== -1
            ) {
              regionSelf[row.RegionId].total += row.CA;
              regionSelf[row.RegionId].NbFactures += row.NbFactures; // On peut additionner les factures car groupements différents => Pas de PDV doublons
            }

            // CA total
            caTotal += row.CA;
          }
        });

        store.financier.details.full = res;
        store.financier.details.region = regionFilteredTotals;
        store.financier.details.self = {
          groupement: resSelf,
          region: regionSelf,
          caTotal: caTotal,
        };

        store.financier.details.loaded = store.self.guid;
        resolve();
      });
    });
  },

  /// =========== RECUPERATION DONNEES DOR ===================//
  loadEvolutionCa() {
    store.financier.evolutionCa.loaded = false;
    return new Promise((resolve) => {
      let promiseList = [];
      for (let i = 2019; i <= new Date().getFullYear() - 1; i++) {
        promiseList.push(this.getYearCa(i)); //On récupère les données pour chaque année
      }

      Promise.all(promiseList).then(() => {
        store.financier.evolutionCa.data =
          store.financier.evolutionCa.data.sort((a, b) => a.year - b.year);
        store.financier.evolutionCa.loaded = store.self.guid;
        resolve();
      });
    });
  },
  getYearCa(year) {
    const yearObj = store.perioFourni.annees.find((_a) => _a.year == year);
    const period = yearObj ? yearObj.id : null;

    return new Promise((resolve) => {
      if (!period) {
        resolve();
        return;
      }

      fetchApi(
        "data?geos=" +
          JSON.stringify(selectedDeps()) +
          "&adhs=" +
          JSON.stringify(store.calculatorFilters.adhs) +
          "&tranchesCa=" +
          JSON.stringify(store.calculatorFilters.tranchesCa) +
          "&gammes=" +
          JSON.stringify(store.calculatorFilters.gammes) +
          "&familles=" +
          JSON.stringify(store.calculatorFilters.families) +
          "&guid=" +
          store.self.guid +
          "&isClient=" +
          store.calculatorFilters.isClient +
          "&typo=" +
          store.calculatorFilters.isSpecialiste +
          "&isLnl=" +
          (store.calculatorFilters.lnlAll
            ? -1
            : store.calculatorFilters.isLnl) +
          "&isLS=" +
          (store.calculatorFilters.lnlAll
            ? -1
            : store.calculatorFilters.libreService
            ? 1
            : -1) +
          "&isByLnl=" +
          (store.calculatorFilters.lnlAll
            ? -1
            : store.calculatorFilters.isByLnl) +
          "&idPeriode=" +
          period +
          "&dateEntree=" +
          store.calculatorFilters.dateEntree +
          "&isBefore=" +
          store.calculatorFilters.isBeforeDate +
          "&distance=" +
          (store.calculatorFilters.pdvDistance.latlng
            ? store.calculatorFilters.pdvDistance.distance * 1000
            : -1) +
          "&lat=" +
          (store.calculatorFilters.pdvDistance.latlng
            ? store.calculatorFilters.pdvDistance.point.latlng.lat
            : -1) +
          "&lng=" +
          (store.calculatorFilters.pdvDistance.latlng
            ? store.calculatorFilters.pdvDistance.point.latlng.lng
            : -1),
      )
        .then((response) => {
          store.financier.evolutionCa.data.push({
            year,
            ca: response.data.Results.CaSelf,
          });
          resolve();
        })
        .catch(() => resolve());
    });
  },
  loadAdvsCa() {
    fetchApi(
      "fournisseur/advCa?geos=" +
        JSON.stringify(selectedDeps()) +
        "&adhs=" +
        JSON.stringify(store.calculatorFilters.adhs) +
        "&tranchesCa=" +
        JSON.stringify(store.calculatorFilters.tranchesCa) +
        "&gammes=" +
        JSON.stringify(store.calculatorFilters.gammes) +
        "&familles=" +
        JSON.stringify(store.calculatorFilters.families) +
        "&guid=" +
        store.self.guid +
        "&isClient=" +
        store.calculatorFilters.isClient +
        "&typo=" +
        store.calculatorFilters.isSpecialiste +
        "&isLnl=" +
        (store.calculatorFilters.lnlAll ? -1 : store.calculatorFilters.isLnl) +
        "&isLS=" +
        (store.calculatorFilters.lnlAll
          ? -1
          : store.calculatorFilters.libreService
          ? 1
          : -1) +
        "&isByLnl=" +
        (store.calculatorFilters.lnlAll
          ? -1
          : store.calculatorFilters.isByLnl) +
        "&distance=" +
        (store.calculatorFilters.pdvDistance.latlng
          ? store.calculatorFilters.pdvDistance.distance * 1000
          : -1) +
        "&lat=" +
        (store.calculatorFilters.pdvDistance.latlng
          ? store.calculatorFilters.pdvDistance.latlng.lat
          : -1) +
        "&lng=" +
        (store.calculatorFilters.pdvDistance.latlng
          ? store.calculatorFilters.pdvDistance.latlng.lng
          : -1) +
        "&idPeriode=" +
        store.calculatorFilters.idPeriode,
    ).then((response) => {
      const { data } = response;
      if (store.isDemo) {
        store.dorDatas.advsCa = data.map((adv) => ({
          ...adv,
          Commercial: {
            ...adv.Commercial,
            FirstName: "Jean",
            LastName: "Dupont",
            Email: "abdefg@hij.fr",
            PortablePhoneNumber: "06 00 00 00 00",
          },
        }));
      } else {
        store.dorDatas.advsCa = data;
      }
    });
  },

  loadDorIndicators() {
    fetchApi(
      "fournisseur/dorIndicateurs?geos=" +
        JSON.stringify(selectedDeps()) +
        "&adhs=" +
        JSON.stringify(store.calculatorFilters.adhs) +
        "&tranchesCa=" +
        JSON.stringify(store.calculatorFilters.tranchesCa) +
        "&gammes=" +
        JSON.stringify(store.calculatorFilters.gammes) +
        "&familles=" +
        JSON.stringify(store.calculatorFilters.families) +
        "&guid=" +
        store.self.guid +
        "&isClient=" +
        store.calculatorFilters.isClient +
        "&typo=" +
        store.calculatorFilters.isSpecialiste +
        "&isLnl=" +
        (store.calculatorFilters.lnlAll ? -1 : store.calculatorFilters.isLnl) +
        "&isLS=" +
        (store.calculatorFilters.lnlAll
          ? -1
          : store.calculatorFilters.libreService
          ? 1
          : -1) +
        "&isByLnl=" +
        (store.calculatorFilters.lnlAll
          ? -1
          : store.calculatorFilters.isByLnl) +
        "&distance=" +
        (store.calculatorFilters.pdvDistance.latlng
          ? store.calculatorFilters.pdvDistance.distance * 1000
          : -1) +
        "&lat=" +
        (store.calculatorFilters.pdvDistance.latlng
          ? store.calculatorFilters.pdvDistance.latlng.lat
          : -1) +
        "&lng=" +
        (store.calculatorFilters.pdvDistance.latlng
          ? store.calculatorFilters.pdvDistance.latlng.lng
          : -1) +
        "&idPeriode=" +
        store.calculatorFilters.idPeriode,
    ).then((response) => {
      const { data } = response;
      store.dorDatas.indicateurs = data;
    });
  },

  loadDorDatas() {
    this.loadAdvsCa();
    this.loadDorIndicators();

    store.dorDatas.init = store.self.guid;
  },

  //Charge les CA des acomptes de la période selectionnée
  loadAcomptes() {
    fetchApi(
      "acompteca/id?idPeriode=" +
        store.calculatorFilters.idPeriode +
        "&frsGuid=" +
        store.self.guid,
    ).then((response) => {
      const { data } = response;
      data.forEach((period, i) => {
        if (i > 0) {
          const alreadyCountedCa = data.reduce((acc, el, _i) => {
            if (_i < i) acc += el.Ca;
            return acc;
          }, 0);
          period.Ca -= alreadyCountedCa;
        }
      });
      store.financier.acomptes = data || [];
    });
  },

  // Met en forme les PDV pour créer une hierarchie, avec les CA à chaque niveau
  computeHierarchy(pdvs) {
    var result = {};

    for (const pdv of pdvs) {
      if (!(pdv.GroupeGuid in result)) {
        result[pdv.GroupeGuid] = {
          Guid: pdv.GroupeGuid,
          Name: pdv.GroupeName,
          children: {},
          Ca: 0,
          CaPerRegion: {},
        };
      }
      let grpObj = result[pdv.GroupeGuid];

      if (!(pdv.SousGroupeGuid in grpObj.children)) {
        grpObj.children[pdv.SousGroupeGuid] = {
          Guid: pdv.SousGroupeGuid,
          Name: pdv.SousGroupeName,
          children: {},
          Ca: 0,
          CaPerRegion: {},
        };
      }
      let ssGrpObj = grpObj.children[pdv.SousGroupeGuid];

      if (!(pdv.SocieteGuid in ssGrpObj.children)) {
        ssGrpObj.children[pdv.SocieteGuid] = {
          Guid: pdv.SocieteGuid,
          Name: store.isDemo ? "Societe Bois MTX" : pdv.SocieteName,
          children: {},
          Ca: 0,
          CaPerRegion: {},
        };
      }
      let socObj = ssGrpObj.children[pdv.SocieteGuid];

      if (!(pdv.PdvGuid in socObj.children)) {
        socObj.children[pdv.PdvGuid] = {
          ...pdv,
          Guid: pdv.PdvGuid,
          Name: store.isDemo ? "Bois MTX" : pdv.PdvName,
          CaPerRegion: {},
        };
      }
      let pdvObj = socObj.children[pdv.PdvGuid];

      const idDep = pdv.IdDepartement;
      const region = store.dataMap.regions.find(
        (r) => r.Departements.findIndex((d) => d.IdDepartement == idDep) != -1,
      );

      let regionId = "-1";
      if (region) regionId = region.IdNational;

      if (["01", "02", "03", "04", "06"].indexOf(regionId) != -1) regionId = 99; // On remplace les DOM-TOM par la grande région DOM-TOM

      socObj.Ca += pdv.Ca;
      ssGrpObj.Ca += pdv.Ca;
      grpObj.Ca += pdv.Ca;

      if (regionId in pdvObj.CaPerRegion)
        pdvObj.CaPerRegion[regionId] += pdv.Ca;
      else pdvObj.CaPerRegion[regionId] = pdv.Ca;

      if (regionId in socObj.CaPerRegion)
        socObj.CaPerRegion[regionId] += pdv.Ca;
      else socObj.CaPerRegion[regionId] = pdv.Ca;

      if (regionId in ssGrpObj.CaPerRegion)
        ssGrpObj.CaPerRegion[regionId] += pdv.Ca;
      else ssGrpObj.CaPerRegion[regionId] = pdv.Ca;

      if (regionId in grpObj.CaPerRegion)
        grpObj.CaPerRegion[regionId] += pdv.Ca;
      else grpObj.CaPerRegion[regionId] = pdv.Ca;
    }

    return result;
  },
};
