<template>
    <div class="">
        <div class="header d-block">
            <h2>Top commerciaux</h2>
            <small>CA par commercial selon les données calculables</small>
        </div>
        <div class="content indicateurs">
            <div class="graph graph-top-adv">
                <canvas id="advsTopGraph" width="500" height="300"></canvas>
            </div>
            <table class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Mail</th>
                        <th>Téléphone</th>
                        <th>Départements <font-awesome-icon class="ml-4" icon="info-circle"
                                v-on:click="mutations.displayInfo('deptCol')" /></th>
                        <th>CA</th>
                        <th></th>
                    </tr>
                </thead>
                <tr v-for="row of advsCa" :key="row.Commercial.Id">
                    <td>{{ `${row.Commercial.FirstName} ${row.Commercial.LastName}` }}</td>
                    <td>{{ row.Commercial.Email }}</td>
                    <td>{{ row.Commercial.PortablePhoneNumber }}</td>
                    <td>
                        <span v-for="(dept, index) of row.Commercial.Departements" :key="dept"
                            :class="{ 'text-danger': app.dataMap.departements.findIndex(d => d.Id == dept) === -1 && dept !== '2A' && dept !== '2B' && dept !== 'B-' }"
                            :style="{ padding: 0, fontSize: 'inherit' }">
                            {{ dept }}{{ index !== row.Commercial.Departements.length - 1 ? ", " : "" }}
                        </span>
                    </td>
                    <td>{{ formatNumberEur(row.CA) }}</td>
                    <td><font-awesome-icon icon="search" @click="selectedRow = row" /></td>
                </tr>
            </table>
        </div>
        <div v-if="selectedRow">
            <DorAdvPopup :data="selectedRow" @close="selectedRow = null" />
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js/auto';

import { store, mutations } from "../store/store";
import { dataFinanceMutations } from "../store/dataFinance";
import { getRandomColor } from "@/utils";

import DorAdvPopup from "./DorAdvPopup.vue";

export default {
    name: 'AdvsDor',
    components: {
        DorAdvPopup
    },
    data() {
        return {
            app: store,
            mutations,
            dataFinanceMutations,
            selectedRow: null,
            chart: null
        }
    },
    mounted() {
        if (this.app.dorDatas.init) // Si initialisé
            this.initChart(this.advsCa.slice(0, 10));
    },
    computed: {
        advsCa() {
            return this.app.dorDatas.advsCa.slice().sort((a, b) => b.CA - a.CA);
        }
    },
    methods: {
        initChart(newVal) {
            const canvas = document.getElementById("advsTopGraph");
            if (this.chart) {
                this.chart.destroy();
                this.chart = null;
            }

            this.chart = new Chart(canvas, {
                type: 'bar',
                data: {
                    labels: [...newVal.map(row => `${row.Commercial.FirstName} ${row.Commercial.LastName}`)],
                    datasets: [
                        {
                            label: "Panier moyen",
                            data: [...newVal.map(row => row.CA)],
                            backgroundColor: getRandomColor(1)
                        }
                    ]
                },
                options: {
                    scales: {
                        y: {
                            ticks: {
                                beginAtZero: true,
                                callback: (value) => {
                                    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
                                }
                            }
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    return Number(context.raw).toLocaleString("fr-FR", { minimumFractionDigits: 0 }) + " €";
                                }
                            }
                        }
                    }
                }

            });
        },
        formatNumberEur(number) {
            return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(number);
        },
        formatPhoneNumber(str) {
            let res = "";
            for (let i = 0; i < str.length; i++) {
                res += str[i];
                if (i % 2 === 0)
                    res += " ";
            }
            return "(+33)" + res;
        }
    },
    watch: {
        advsCa(newVal) {
            this.initChart(newVal.slice(0, 10));
        }
    },
    filters: {
    }

}
</script>

<style>
.advs-fullscreen-container .lg-modal .popup-content {
    /*        width: 80%;*/
}

.advs-fullscreen-container .graph-top-adv {
    /*        display: inline-block;*/
    width: 100% !important;
    display: block;
    margin: 0 auto;
    max-height: 300px !important;
}

.advs-fullscreen-container table {
    /*display: inline-block;
        width: calc(100% - 550px);*/
    /*        max-height: 300px;*/
    /*        overflow-y: scroll;*/
}

.advs-fullscreen-container table td:not(:last-child) {
    text-align: left !important;
}

.advs-fullscreen-container table td:nth-child(3) {
    /*        word-break: keep-all;*/
    white-space: nowrap;
    /*            overflow-wrap: break-word;*/
}

.advs-fullscreen-container table td:nth-child(2) {
    overflow-wrap: break-word;
}
</style>
