<template>
    <div id="familles" v-if="app.self.isOdw && app.calculatorFilters.isClient == 1">
        <h3>
            Gammes
        </h3>
        <div id="familles-choice">
            <div v-for="gamme in app.gammes" :key="gamme.Id" :title="gamme.Famille" class="familles-choice-textbox">
                <label>
                    <input type="radio"
                           :value="gamme.Id"
                           :checked="app.calculatorFilters.gammes.indexOf(gamme.Id) != -1"
                           @change="gammeChanged(gamme.Id)"
                           class="radio-cmem"
                           name="radiogammes" />
                    {{ gamme.Famille }}
                </label>
            </div>
        </div>
    </div>
</template>
<script>
    import { store } from "../store/store"
    import { dataFinanceMutations } from "../store/dataFinance";

    export default {
        name: "GammeFilter",
        data() {
            return {
                app: store,
            }
        },
        methods: {
            gammeChanged(gammeId) {
                store.calculatorFilters.gammes = [gammeId]
                dataFinanceMutations.loadDataFinance();
            }
        }
    }
</script>

<style></style>