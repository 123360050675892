<template>
    <div id="clients" v-if="app.self.isOdw || app.self.isSupplier || app.self.isAdmin">
        <h3>Client</h3>
        <div id="clients-choice">
            <div class="clients-choice-textbox">
                <label>
                    <input type="radio"
                           value="1"
                           v-model="app.calculatorFilters.isClient"
                           class="radio-cmem"
                           @change="clientChanged()" />
                    Oui
                </label>
            </div>
            <div class="clients-choice-textbox">
                <label>
                    <input type="radio"
                           name="clientRadio"
                           value="0"
                           v-model="app.calculatorFilters.isClient"
                           class="radio-cmem"
                           @change="clientChanged()" />
                    Non
                </label>
            </div>
            <div class="clients-choice-textbox">
                <label>
                    <input type="radio"
                           name="clientRadio"
                           value="2"
                           v-model="app.calculatorFilters.isClient"
                           class="radio-cmem"
                           @change="clientChanged()" />
                    Non / Client chez concurrents
                </label>
            </div>
            <div v-if="!app.self.isOdw" class="clients-choice-textbox">
                    <input type="radio"
                           id="radio-client-tous"
                           name="clientRadio"
                           value="-1"
                           v-model="app.calculatorFilters.isClient"
                           class="radio-cmem"
                           @change="clientChanged()" />
                <label for="radio-client-tous">
                    Tous
                </label>
            </div>
        </div>
    </div>
</template>
<script>
    import { store } from "../store/store"
    import { dataFinanceMutations } from "../store/dataFinance";
    import { pointDeVenteMutations } from "../store/pointDeVente";

    export default {
        name: "ClientFilter",
        props: {
            open: Boolean
        },
        data() {
            return {
                app: store,
            }
        },
        methods: {
            clientChanged() {
                if (store.calculatorFilters.isClient != 1) {
                    store.calculatorFilters.tranchesCa = [];
                    this.$router.push("/");
                }
                if (store.self.isOdw) {
                    dataFinanceMutations.loadDataFinance();
                } else {
                    pointDeVenteMutations.loadPdv();
                }
            }
        }
    }
</script>

<style></style>