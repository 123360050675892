<template>
    <div id="users-container">
        <div>
            <h1>Utilisateurs</h1>
        </div>
        <div class="card shadow mb-4">
            <!-- Card Header - Dropdown -->
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h5>Utilisateurs Intranet</h5>
            </div>
            <!-- Card Body -->
            <div class="pt-2 pb-2 pl-5 pr-5">
                <div class="row indicator-line mb-4">
                    <div class="col-sm">
                        <div class="tuile shadow">
                            <h2>Nombre d'accès validés</h2>
                            <span class="indicator">{{ nbFrs }}</span>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="tuile shadow">
                            <h2>Nombre de comptes créés</h2>
                            <span class="indicator">{{ nbCreatedIntraAccount }}</span>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="tuile shadow">
                            <h2>Nombre de profils ODW activés</h2>
                            <span class="indicator">{{ nbProfileActivated }}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <label>
                        Fournisseur :
                        <input class="form-control w-40" type="text" v-model="frsFilter" />
                    </label>
                </div>
                <table class="table table-hover table-sm">
                    <thead class="thead-light">
                        <tr>
                            <th>Fournisseur</th>
                            <th>Login</th>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Compte Intranet créé</th>
                            <th>Profil ODW3</th>
                            <th>Profil Obs. Détaillé</th>
                            <th>Accès restreint ?</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user of usersFiltered" :key="`${user.ContactUsername}${user.FrsGuid}`" :class="{warning: !user.IsIntraAccount}">
                            <td>{{user.FrsName}}</td>
                            <td>{{user.ContactUsername}}</td>
                            <td>{{user.ContactLastName}}</td>
                            <td>{{user.ContactFirstName}}</td>
                            <td class="text-center">
                                <font-awesome-icon :icon="['far', user.IsIntraAccount ? 'check-circle' : 'times-circle']" />
                            </td>
                            <td class="text-center">
                                <font-awesome-icon :icon="['far', user.IsOdw ? 'check-circle' : 'times-circle']" />
                            </td>
                            <td class="text-center">
                                <font-awesome-icon :icon="['far', user.IsDOR ? 'check-circle' : 'times-circle']" />
                            </td>
                            <td><input class="checkbox-cmem" type="checkbox" :checked="user.IsDepRestricted" @click.prevent="toggleDepFilteredAccess(user)"/></td>
                            <td class="text-center"><button :disabled="user.IsIntraAccount" class="btn btn-success" @click="openModalRelance(user.ContactEmail)">Relancer</button></td>
                            <td class="text-center">
                                <button v-if="user.IsOdw" :disabled="!user.IsIntraAccount" class="btn btn-warning" @click="toggleProfile(user.ContactUsername)">Désactiver le profil ODW3</button>
                                <button v-else class="btn btn-success" :disabled="!user.IsIntraAccount" @click="toggleProfile(user.ContactUsername)">Activer le profil ODW3</button>
                            </td>
                            <td class="text-center">
                                <button v-if="user.IsDOR" :disabled="!user.IsIntraAccount" class="btn btn-warning" @click="toggleProfile(user.ContactUsername, true)">Désactiver le profil Obs. Détaillé</button>
                                <button v-else class="btn btn-success" :disabled="!user.IsIntraAccount" @click="toggleProfile(user.ContactUsername, true)">Activer le profil Obs. Détaillé</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <Modal title="Mail de relance" :open="relanceModal" @close="relanceModal = false">
            <p>Êtes-vous sûr de vouloir envoyer un mail de relance à {{selectedRelanceMail}} ?</p>
            <div class="d-flex justify-content-around">
                <button @click="relancer(selectedRelanceMail)" class="btn btn-info">Oui</button>
                <button @click="relanceModal = false" class="btn btn-warning">Non</button>
            </div>
        </Modal>
    </div>
</template>

<script>
    //import Vue from "vue";
    import { fetchApi } from "@/utils";
    import { store } from "../store/store";
    import Modal from "../Modal.vue";

    export default {
        name: 'MenuCdm',
        components: { Modal },
        data() {
            return {
                store,
                relanceModal: false,
                selectedRelanceMail: "",
                frsFilter: "",
                users: [],
            }
        },
        mounted() {
            this.initUsers()
        },
        watch: {
            idPeriode() {
                this.initUsers();
            }
        },
        computed: {
            usersFiltered() {
                return this.users.filter(u => {
                    const reg = new RegExp(this.frsFilter, "ig");
                    return reg.test(u.FrsName);
                })
            },
            nbFrs() {
                return this.users.filter((u, index) => this.users.findIndex(u2 => u2.FrsGuid == u.FrsGuid) == index).length
            },
            nbCreatedIntraAccount() {
                let frsCreatedIntra = []
                this.users.forEach(u => {
                    if (u.IsIntraAccount && frsCreatedIntra.indexOf(u.FrsGuid) == -1) frsCreatedIntra.push(u.FrsGuid);
                })
                return frsCreatedIntra.length
            },
            nbProfileActivated() {
                let frsProfileActive = []
                this.users.forEach(u => {
                    if (u.IsOdw && frsProfileActive.indexOf(u.FrsGuid) == -1) frsProfileActive.push(u.FrsGuid);
                })
                return frsProfileActive.length
            },
            idPeriode() {
                return this.store.calculatorFilters.idPeriode;
            }
        },
        methods: {
            initUsers() {
                fetchApi("cdm/validation/intra/all?periodId=" + this.store.calculatorFilters.idPeriode)
                    .then(response => {
                        this.users = response.data.slice().sort((a, b) => {
                            if (a.FrsName.toLowerCase() < b.FrsName.toLowerCase()) return -1
                            else if (a.FrsName.toLowerCase() > b.FrsName.toLowerCase()) return 1

                            if (a.OriginalContact < b.OriginalContact) return 1
                            else if (a.OriginalContact > b.OriginalContact) return -1

                            if (a.ContactEmail.toLowerCase() < b.ContactEmail.toLowerCase()) return -1
                            else if (a.ContactEmail.toLowerCase() > b.ContactEmail.toLowerCase()) return 1

                        })
                    })
            },
            openModalRelance(email) {
                this.selectedRelanceMail = email;
                this.relanceModal = true;
            },
            relancer(email) {
                this.relanceModal = false;
                fetchApi(`cdm/validation/intra/relance?email=${email}`)
                    .then(response => {
                        if (response.status === 200) {
                            window.alert("Mail de relance envoyé avec succès");
                            //this.initUsers();
                        }
                    })
            },
            toggleDepFilteredAccess(user) {
                fetchApi("cdm/validation/toggle-dep-restriction?userLogin=" + user.ContactUsername)
                    .then(() => {
                        this.initUsers();
                    })
            },
            toggleProfile(login, dor = false) {
                fetchApi(`cdm/validation/intra/toggleprofile?email=${login}&dor=${dor}`)
                    .then(() => {
                        //console.log("response toggle: ", response)
                        this.initUsers();
                    })
            }
        },
    }

</script>

<style scoped>
    .tuile {
        border-radius: 5px;
        background: #FFFFFF;
        padding: 5px;
    }

        .tuile > h2 {
            font-weight: 100;
            text-align: center;
            padding: 3px;
        }

        .tuile > span.indicator {
            display: block;
            text-align: center;
            font-size: 4rem;
        }

    .indicator-line > div:nth-of-type(1) > .tuile {
        border-bottom: solid 7px #c42525;
    }

    .indicator-line > div:nth-of-type(2) > .tuile {
        border-bottom: solid 7px #2b941b;
    }

    .indicator-line > div:nth-of-type(3) > .tuile {
        border-bottom: solid 7px #125d82;
    }

    #users-container {
        padding: 12px 42px;
        width: calc(100% - 250px);
        height: 94vh;
        overflow-y: scroll;
        box-sizing: border-box;
        background-color: #F3F3F3;
    }

    span {
        padding: 0;
    }

    h1 {
        color: var(--primary);
        text-align: center;
        font-size: 2.5rem;
        font-weight: 100;
    }

    h3 {
        margin-top: 3em;
        font-size: 1.3em;
    }

    h5 {
        color: var(--primary);
        font-weight: 100;
        margin: 0;
    }

    .table thead {
        text-align: center;
    }

    .btn-info {
        border: none !important;
        background-color: var(--primary) !important;
    }

    .fa-times-circle {
        color: rgb(155, 25, 35);
    }

    .fa-check-circle {
        color: var(--primary);
    }

    .collapse-frs {
        position: relative;
    }

        .collapse-frs > div {
            width: 100%;
            height: 100%;
            text-align: center;
        }

            .collapse-frs > div:hover {
                cursor: pointer;
            }

    table tr.secondary {
        background-color: #EEE;
    }

    table tr.warning {
        background-color: #FDD;
    }
</style>