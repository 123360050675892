<template>
    <div v-if="app.self.isOdw">
        <div class="header">
            <h2 class="w-100 d-flex justify-content-between align-items-center">
                Résultats financiers
                <span class="small">Calculé sur base de {{ app.financier.results.TypeCalcul }}</span>
            </h2>
        </div>
        <div class="selector-filters-body">
            <div>
                <h5>Part de marché <span><font-awesome-icon icon="info-circle" v-on:click="displayInfo('pdm')" /></span>
                </h5>
                <div class="row">
                    <div class="graph col-6 d-flex align-items-center">
                        <canvas id="chartCa" width="110" height="110"></canvas>
                    </div>
                    <div class="table-container col-6">
                        <table class="table table-bordered table-striped">
                            <tr>
                                <th>CA marché CMEM</th>
                                <td>{{ formatNumberEur(app.financier.results.CaTotal) }}</td>
                            </tr>
                            <tr>
                                <th>Mon CA</th>
                                <td>{{ formatNumberEur(app.financier.results.CaSelf) }}</td>
                            </tr>
                            <tr>
                                <th title="Part de marché">Ma PDM</th>
                                <td>{{ app.financier.generatedDatas.PdmSelf }} %</td>
                            </tr>
                            <tr>
                                <th>PDM Moyen concurrents</th>
                                <td>{{ app.financier.generatedDatas.PdmConcurrents }} %</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <hr>
            <div>
                <h5>Taux de pénétration <span><font-awesome-icon icon="info-circle" v-on:click="displayInfo('tp')" /></span>
                </h5>
                <div class="row">
                    <div class="graph col-6 d-flex align-items-center">
                        <canvas id="chartPenetration" width="110" height="110"></canvas>
                    </div>
                    <div class="table-container col-6">
                        <table class="table table-bordered table-striped">
                            <tr>
                                <th>Taux moyen marché</th>
                                <td>{{ app.financier.generatedDatas.TpTotal }} %</td>
                            </tr>
                            <tr>
                                <th>Mon taux</th>
                                <td>{{ app.financier.generatedDatas.TpSelf }} %</td>
                            </tr>
                            <tr>
                                <th>Rappel scoring*</th>
                                <td>{{ app.financier.generatedDatas.TpGlobalSelf }} %</td>
                            </tr>
                            <tr>
                                <th>Nombre PDV clients</th>
                                <td>{{ app.financier.results.PdvSelfFactures }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <p class="small">* Taux de pénétration sur l'ensemble des PDV CMEM</p>
            </div>
            <hr>
            <div>
                <h5>Panier moyen <span><font-awesome-icon icon="info-circle" v-on:click="displayInfo('pm')" /></span></h5>
                <div class="row">
                    <div class="graph col-6 d-flex align-items-center">
                        <canvas id="chartPanierMoyen" width="110" height="250"></canvas>
                    </div>
                    <div class="table-container col-6">
                        <table class="table table-bordered table-striped">
                            <tr>
                                <th>Panier moyen marché</th>
                                <td>{{ formatNumberEur(app.financier.generatedDatas.PanierMoyTotal) }}</td>
                            </tr>
                            <tr>
                                <th>Panier moyen concurrents</th>
                                <td>{{ formatNumberEur(app.financier.generatedDatas.PanierMoyConcurrents) }}</td>
                            </tr>
                            <tr>
                                <th>Mon panier moyen</th>
                                <td>{{ formatNumberEur(app.financier.generatedDatas.PanierMoySelf) }}</td>
                            </tr>
                            <tr>
                                <th>Panier moyen PDV</th>
                                <td>{{ formatNumberEur(app.financier.generatedDatas.PanierMoyPdv) }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <hr>
            <div>
                <h5>
                    Détail du chiffre d'affaires au cours de l'année <!--Acomptes-->
                    <span><font-awesome-icon icon="info-circle" size="1x" v-on:click="displayInfo('acomptes')" /></span>
                </h5>
                <div class="graphAcompte">
                    <div class="chartLegendAcompte">
                        <!--La légende à été créée à la "main" car celle ne ChartJs ne correspondait pas à nos attentes-->
                        <div class="legendAcompte" v-for="item in app.acomptesLegend" v-bind:key="item.id">
                            <div class="label">
                                <span>{{ item.label }}</span>
                            </div>
                            <div class="zoneboxe">
                                <div class="boxe" :style="{ 'background-color': item.backgroundColor }"></div>
                            </div>
                            <div class="result">
                                <span>{{ formatNumberEur(item.data[0]) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="canvasAcompteCa">
                        <canvas id="chartAcompte" width="200" height="100"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js/auto';

import { store, mutations } from "../store/store";
import { getRandomColor } from "@/utils";

export default {
    name: "MasterSelectorResultatsFinanciers",
    data() {
        return {
            app: store,
            mutations,
            charts: {
                ca: null,
                penetration: null,
                panierMoyen: null,
                acompteCa: null
            },
            acompteslegend: []
        }
    },
    mounted() {
        this.displayGraphPdm(store.financier.results.CaExceptSelf, store.financier.results.CaSelf);
        this.displayGraphTp(store.financier.generatedDatas.TpTotal,
            store.financier.generatedDatas.TpSelf,
            store.financier.generatedDatas.TpGlobalSelf);
        this.displayGraphPanierMoyen(store.financier.generatedDatas.PanierMoyTotal,
            store.financier.generatedDatas.PanierMoySelf,
            store.financier.generatedDatas.PanierMoyConcurrents,
            store.financier.generatedDatas.PanierMoyPdv)
        this.displayGraphAcompte();
    },
    computed: {
        pdmChartDatas() {
            return {
                CaExceptSelf: store.financier.results.CaExceptSelf,
                CaSelf: store.financier.results.CaSelf
            }
        },
        tpChartDatas() {
            return {
                TpTotal: store.financier.generatedDatas.TpTotal,
                TpSelf: store.financier.generatedDatas.TpSelf,
                TpGlobalSelf: store.financier.generatedDatas.TpGlobalSelf
            }
        },
        pmChartDatas() {
            return {
                PanierMoyTotal: store.financier.generatedDatas.PanierMoyTotal,
                PanierMoySelf: store.financier.generatedDatas.PanierMoySelf,
                PanierMoyConcurrents: store.financier.generatedDatas.PanierMoyConcurrents,
                PanierMoyenPdv: store.financier.generatedDatas.PanierMoyPdv
            }
        },
        acomptesChartDatas() {
            return store.financier.acomptes;
        }
    },
    watch: {
        pdmChartDatas() {
            this.displayGraphPdm(store.financier.results.CaExceptSelf, store.financier.results.CaSelf);
        },
        tpChartDatas() {
            this.displayGraphTp(store.financier.generatedDatas.TpTotal,
                store.financier.generatedDatas.TpSelf,
                store.financier.generatedDatas.TpGlobalSelf);
        },
        pmChartDatas() {
            this.displayGraphPanierMoyen(store.financier.generatedDatas.PanierMoyTotal,
                store.financier.generatedDatas.PanierMoySelf,
                store.financier.generatedDatas.PanierMoyConcurrents,
                store.financier.generatedDatas.PanierMoyPdv)
        },
        acomptesChartDatas() {
            this.displayGraphAcompte();
        }
    },
    methods: {
        displayInfo(info) {
            mutations.displayInfo(info);
        },
        // Part de marché
        displayGraphPdm(CaExceptSelf, CaSelf) {
            const canvas = document.getElementById("chartCa");
            if (this.charts.ca) this.charts.ca.destroy();
            this.charts.ca = new Chart(canvas, {
                type: 'doughnut',
                data: {
                    labels: ['CA concurrents', 'Mon CA'],
                    datasets: [
                        {
                            label: "Proportion des chiffres d'affaire",
                            data: [CaExceptSelf, CaSelf],
                            backgroundColor: ['#dee2e6', getRandomColor(1)]
                        },
                    ],
                    styling: {
                        borderWidth: 0
                    }
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: true,
                            callbacks: {
                                label: function (context) {
                                    // return " " + context.label + " : " + Number(context.parsed).toLocaleString("fr-FR", { minimumFractionDigits: 0 }) + " €";
                                    return Number(context.parsed).toLocaleString("fr-FR", { minimumFractionDigits: 0 }) + " €";
                                }
                            }
                        }
                    },
                }
            });
        },
        // Taux pénétration
        displayGraphTp(tauxPenetrationTotal, tauxPenetrationTotalSelf, tauxPenetrationMarche) {
            const canvas = document.getElementById("chartPenetration");
            if (this.charts.penetration) this.charts.penetration.destroy();
            this.charts.penetration = new Chart(canvas, {
                type: 'bar',
                data: {
                    labels: ['Taux moyen marché', 'Mon taux', 'Rappel scoring*'],
                    datasets: [
                        {
                            label: "Taux de pénétration",
                            data: [tauxPenetrationTotal, tauxPenetrationTotalSelf, tauxPenetrationMarche],
                            backgroundColor: ['#dee2e6', getRandomColor(1), getRandomColor(4)]
                        }
                    ],
                    styling: {
                        borderWidth: 0
                    }
                },
                options: {
                    scales: {
                        y: {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: true,
                            callbacks: {
                                label: function (context) {
                                    return context.formattedValue + " %";
                                }
                            }
                        }
                    },
                }
            });
        },
        // Panier moyen
        displayGraphPanierMoyen(panierMoyenTotal, panierMoyenSelf, panierMoyenConcurrents, panierMoyenPdv) {
            const canvas = document.getElementById("chartPanierMoyen");
            if (this.charts.panierMoyen) this.charts.panierMoyen.destroy();
            this.charts.panierMoyen = new Chart(canvas, {
                type: 'bar',
                data: {
                    labels: ['Panier moyen marché', 'Panier moyen concurrents', 'Mon panier moyen', 'Panier moyen PDV'],
                    datasets: [
                        {
                            label: "Panier moyen",
                            data: [panierMoyenTotal, panierMoyenConcurrents, panierMoyenSelf, panierMoyenPdv],
                            backgroundColor: [getRandomColor(4), '#dee2e6', getRandomColor(1), getRandomColor(9)]
                        }
                    ]
                },
                options: {
                    scales: {
                        y: {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    },
                    legend: {
                        display: false
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: true,
                            callbacks: {
                                label: function (context) {
                                    return Number(context.raw).toLocaleString("fr-FR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + " €";
                                }
                            }
                        }
                    },
                }
            });
        },
        // Acomptes
        displayGraphAcompte() {
            if (this.charts.acompteCa) this.charts.acompteCa.destroy();

            const data = { //sauvegarde de l'année
                labels: ['Année ' + store.calculatorFilters.selectedYear],
                datasets: []
            };
            //Pour chaque acompte on sauvegarde son id, son nom, son chiffre, et un appel à la fonction RandomColor
            data.datasets = store.financier.acomptes.map((acompte, index) => ({
                id: index,
                label: acompte.Nom,
                data: ['' + acompte.Ca.toString()],
                backgroundColor: getRandomColor(index + 2),
                stack: 1
            }));

            if (data.datasets.length > 0) data.datasets.push({
                id: data.datasets.length,
                label: 'Annuel ' + store.calculatorFilters.selectedYear,
                data: ['' + store.financier.acomptes[0].CaAnnee],
                backgroundColor: getRandomColor(store.financier.acomptes.length + 3),
                stack: 0
            })

            this.acomptesLegend = data.datasets.slice();

            const canvas = document.getElementById("chartAcompte");

            this.charts.acompteCa = new Chart(canvas, {
                type: 'bar',
                data,
                options: {
                    responsive: true,
                    elements: {
                        bar: {
                            borderWidth: 2,
                        }
                    },

                    scales: {
                        y: {
                            stacked: true,
                        },
                        x: {
                            stacked: true,
                            ticks: {
                                beginAtZero: true,
                                callback: function (label) {
                                    return label.toLocaleString("fr-FR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + " €";
                                }
                            }
                        },
                    },
                    indexAxis: 'y',
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: true,
                            callbacks: {
                                label: function (context) {
                                    return Number(context.raw).toLocaleString("fr-FR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + " €";
                                }
                            }
                        }
                    },
                }
            });

        },

        formatNumberEur(number) {
            return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(number);
        },

        formatNumberPer(number) {
            return new Intl.NumberFormat('fr-FR', { style: 'percent' }).format(number);
        },

        formatNumberRound(number) {
            return Math.round(number * 100) / 100;
        }
    },
}
</script>

<style scoped>
.white-box {
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
}

.row {
    margin: 0;
}

.header .small {
    color: #777;
    text-transform: none;
}

.table th {
    font-weight: normal;
    font-size: 0.8rem;
}

.table th,
.table td {
    padding: 4px;
}
</style>