<template>
    <div class="">
        <div class="header">
            <h2>Top clients</h2>
        </div>
        <div class="GammeDorBody">
            <div class="GammeSelection">
                <label>Classés par défaut par top décroissant. </label>
            </div>
        </div>
        <div class="">
            <vue-good-table :columns="columns" :rows="rows || []" :pagination-options="{
                enabled: true,
                rowsPerPageLabel: 'Lignes par page',
                ofLabel: 'sur',
                perPage: '5',
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                allLabel: 'Toutes'
            }" />
        </div>
        <h2 id="treemap-title">{{ "Top " + this.levels[this.currentLevel].title }} <font-awesome-icon class="ml-1"
                icon="info-circle" style="font-size: 0.6em; vertical-align: top;" @click="displayInfosGraph = true" /></h2>
        <div id="treemap-controls">
            <div>
                <span v-for="(filter, index) of levelFilter" :key="index">{{ getLabelFromId(filter, index) }}<span
                        v-if="index < levelFilter.length - 1"> &gt; </span></span>
            </div>
            <div class="btn-group">
                <button class="btn btn-secondary" :disabled="currentLevel == 0"
                    @click="levelFilter = []; currentLevel = 0"><font-awesome-icon icon="angle-double-up"
                        size="1x" /></button>
                <button class="btn btn-secondary" :disabled="currentLevel == 0" @click="levelUp"><font-awesome-icon
                        icon="angle-up" size="1x" /></button>
                <button class="btn btn-secondary" :disabled="currentLevel == levels.length - 1"
                    @click="currentLevel = currentLevel + 1"><font-awesome-icon icon="angle-down" size="1x" /></button>
            </div>
        </div>
        <div class="graphClients">
            <canvas height="450" style="padding:35px;" id="treemap"></canvas>
        </div>
        <Modal :open="displayInfosGraph" title="Informations" @close="displayInfosGraph = false">
            <dl>
                <dt>Double flèche haut</dt>
                <dd>Remonte tout en haut dans la hierarchie</dd>
                <dt>Flèche haut</dt>
                <dd>Remonte d'un niveau dans la hierarchie</dd>
                <dt>Flèche bas</dt>
                <dd>Descend d'un niveau dans la hierarchie sans filtre</dd>
                <dt>Clic sur un adhérent</dt>
                <dd>Descend d'un niveau dans la hierarchie en filtrant</dd>
            </dl>
        </Modal>
    </div>
</template>

<script>
import { store } from "../store/store";
import { VueGoodTable } from "vue-good-table-next";
import Chart from "chart.js/auto";
import 'vue-good-table-next/dist/vue-good-table-next.css';
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";

Chart.register(TreemapController, TreemapElement);

import Modal from "@/components/Modal";
import { getRandomColorTab } from "../../utils";

export default {

    name: 'DorAnalyseClient',
    components: {
        VueGoodTable,
        Modal
    },
    data() {
        return {
            app: store,
            displayInfosGraph: false,
            columns: [
                {
                    label: 'Top',
                    field: 'Top',
                    type: 'number'
                },
                {
                    label: 'Groupement',
                    field: 'GroupeName',
                    type: 'string'
                },
                {
                    label: 'Société',
                    field: 'SocieteName',
                    type: 'string'
                },
                {
                    label: 'Point de vente',
                    field: 'PdvName',
                    type: 'string'
                },
                {
                    label: 'Ville',
                    field: 'City',
                    type: 'string'
                },
                {
                    label: 'Code Postal',
                    field: 'ZipCode',
                    type: 'string'
                },
                {
                    label: 'Part de marché (%)',
                    field: 'Percent',
                    formatFn: this.formatFn,
                    type: 'decimal'
                },
            ],
            rows: [],
            caSelf: store.financier.results.CaSelf,
            dataLoaded: false,
            levels: [{ title: "Groupement", label: "GroupeName", value: "GroupeGuid" },
            { title: "Sous Groupe", label: "SousGroupeName", value: "SousGroupeGuid" },
            { title: "Societé", label: "SocieteNameId", value: "SocieteNameId" },
            { title: "Point de Vente", label: "PdvNameId", value: "PdvNameId" }],
            currentLevel: 0,
            levelFilter: []
        };

    },
    mounted() {
        this.initData();
    },
    watch: {
        currentLevel() {
            let lastFilterRealIndex = -1
            this.levelFilter.forEach((el, index) => {
                if (el) lastFilterRealIndex = index
            });
            this.levelFilter = this.levelFilter.slice(0, lastFilterRealIndex + 1)
            this.initChart("treemap")
        },
        "app.financier.pdvDatas": function () {
            this.initData();
        }
    },
    methods: {
        initData() {
            this.rows = [];
            store.financier.pdvDatas.slice().sort((a, b) => b.Ca - a.Ca).forEach((pdv, index) => {
                let nPdv = Object.assign({}, pdv)
                nPdv.Percent = nPdv.Ca / this.caSelf * 100;
                nPdv.Top = index + 1;
                nPdv.label = "Top " + nPdv.Top + " - " + nPdv.PdvName
                //if (store.isDemo) {
                //    nPdv.PdvNameId = nPdv.PdvName + " (" + index + ")";
                //    nPdv.SocieteNameId = nPdv.SocieteName + " (" + index + ")";
                //} else {
                nPdv.PdvNameId = nPdv.PdvName + " (" + nPdv.IdCompta + ")"
                nPdv.SocieteNameId = nPdv.SocieteName + " (" + nPdv.IdCompta.slice(0, 6) + "00" + ")"
                //}
                this.rows.push(nPdv);
            });


            // On crée le graph 
            this.initChart("treemap");
        },
        //renvoie le bon format
        formatFn(value) {
            if (!value) return "";
            return (value.toFixed(2));
        },
        getLabelFromId(id, level) {
            const idField = this.levels[level].value
            const labelField = this.levels[level].label

            const concernedPdv = this.rows.find(pdv => pdv[idField] == id)
            if (!concernedPdv) return "Pas de sélection"
            return concernedPdv[labelField]
        },
        levelUp() {
            if (this.currentLevel <= 0) return;
            this.currentLevel -= 1;
            this.levelFilter = this.levelFilter.slice(0, this.currentLevel)
        },

        initChart(chartId) {
            var dynamicColors = function () {
                return getRandomColorTab();
                //    var r = 50
                //    var g = Math.floor(Math.random() * 255);
                //    var b = 50
                //    while (g < 80 || g > 190) {
                //        g = Math.floor(Math.random() * 255);
                //    }
                //    return "rgb(" + r + "," + g + "," + b + ")";
            };

            if (store.charts[chartId]) {
                store.charts[chartId].destroy();
                store.charts[chartId] = null;
            }
            var ctx = document.getElementById(chartId).getContext("2d");
            const activeRows = this.rows.filter(pdv => {
                const lastFilterIndex = this.levelFilter.length - 1
                if (lastFilterIndex < 0 || !this.levelFilter[lastFilterIndex] || this.currentLevel === 0) return true

                const filterField = this.levels[lastFilterIndex].value
                return pdv[filterField] === this.levelFilter[lastFilterIndex]
            });
            if (activeRows.length <= 0) return;

            store.charts[chartId] = new Chart(ctx, {
                type: "treemap",
                data: {
                    datasets: [
                        {
                            labels: {
                                display: true,
                                formatter: (ctx) => ctx.raw.g
                            },
                            tree: activeRows,
                            key: "Percent",
                            //groups: ["PdvNameId"],
                            groups: [this.levels[this.currentLevel].label],
                            fontColor: 'black',
                            fontFamily: 'Arial',
                            fontSize: 20,
                            backgroundColor: function () {
                                return dynamicColors();
                            },

                            spacing: 1,
                            borderWidth: 4,
                            borderColor: "rgba(180,180,180, 0.15)"
                        }
                    ]
                },
                options: {
                    maintainAspectRatio: false,
                    animation: false,
                    title: {
                        display: false,
                        text: "Top " + this.levels[this.currentLevel].title,
                        fontSize: 22
                    },
                    onClick: (e, ctx) => {
                        if (ctx.length > 0) {
                            const item = ctx[0];
                            const dataItem = item.element;
                            const _data = dataItem.$context.raw._data;
                            // On peut descendre d'un niveau
                            if (_data.children.length > 0 && this.currentLevel < this.levels.length - 1) {
                                const field = this.levels[this.currentLevel].value;
                                this.levelFilter[this.currentLevel] = activeRows[_data._idx][field];

                                this.currentLevel += 1;
                            }
                        }
                    },
                    plugins: {
                        datalabels: false,
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                title: function () {
                                    return '';
                                },
                                label: function (context) {
                                    return "Top " + (context.dataIndex + 1) + " - " + context.raw.g
                                }
                            }
                        },
                    }
                }
            });
        }
    },

}

</script>

<style>
/*.graphClients {
        height: 400px;
        overflow-y: auto;
    }

    #graph-analyse-clients{
        height: 1000px;
        width: 500px;
        position: relative;
    }*/

#treemap-title {
    text-align: center;
    margin-top: 12px;
}

#treemap-controls {
    display: flex;
    justify-content: space-between;
    margin: 24px 35px 0;
}

#treemap {
    padding-top: 0 !important;
}
</style>