<template>
    <div class="results-container">
        <ul class="tab-nav white-box">
            <li :class="{active: $route.path === '/results/charts'}">
                <router-link   :to="{ path: '/results/charts', query: $route.query }">
                    <div class="icon">
                        <font-awesome-icon icon="chart-line" />
                    </div>
                    Graphiques
                </router-link>
            </li>
            <li :class="{active: $route.path === '/results'}">
                <router-link   :to="{ path: '/results', query: $route.query }">
                    <div class="icon">
                        <font-awesome-icon icon="th-list" />
                    </div>
                    Tableau
                </router-link>
            </li>
        </ul>
        <div class="white-box results-content">
            <router-view></router-view>
        </div>
    </div>
</template>


<script>
    import { store } from "../../components/store/store";
    import { dataFinanceMutations } from "../../components/store/dataFinance";

    export default {
        name: 'Results',
        data() {
            return { app: store }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                if (this.app.financier.details.loaded != this.app.self.guid)
                    dataFinanceMutations.loadDataFinanceDetails();
            },
        }
    }
</script>

<style scoped>
    .results-container {
        height: 100%;
        width: 100%;
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
        /*row-gap: 24px;*/
    }

    .results-content {
        flex-grow: 1;
        overflow-y: auto;
    }
</style>