<template>
    <div v-if="app.self.isOdw && app.calculatorFilters.isClient == 1">
        <h3>Recherche par date</h3>
        <!--Recherche par date d'entrée-->
        <div class="input-container">
            <select  class="form-control"
                    v-model="app.calculatorFilters.isBeforeDate"
                    @change="changeTypeDateRecherchee()">
                <option value="2">Choix du filtre</option>
                <option value="0">Date d'entr&eacute;e depuis le</option>
                <option value="1">Date d'entr&eacute;e avant le</option>
            </select>

            <input class="form-control"
                   name="datePickerRechPdv"
                   type="date"
                   v-model="datePickerRechPdv"
                   onkeydown="return false"
                   @change="this.onDateEntreeChange" min="2007-01-01" />
        </div>

    </div>
</template>

<script>
    import { store } from "../store/store";
    import { dataFinanceMutations } from "../store/dataFinance";

    export default {
        name: "PdvDateFilter",
        data() {
            return {
                app: store,
                datePickerRechPdv: null,
            }
        },
        watch: {
            "app.calculatorFilters.dateEntree": {
                handler() {
                    if (this.app.calculatorFilters.dateEntree == "1900-01-01")
                        this.datePickerRechPdv = null
                    else
                        this.datePickerRechPdv = this.app.calculatorFilters.dateEntree;
                },
            },
        },

        methods: {
            changeTypeDateRecherchee() { //A chaque changement on remet à jour les données
                if (this.datePickerRechPdv != null) {
                    dataFinanceMutations.loadDataFinance();
                }
            },
            onDateEntreeChange() {
                if (this.datePickerRechPdv !== '') {
                    store.calculatorFilters.dateEntree = this.datePickerRechPdv;
                } else {
                    store.calculatorFilters.isBeforeDate = 2;
                    this.datePickerRechPdv = null;
                }
                dataFinanceMutations.loadDataFinance();
            },
        }
    }
</script>

<style scoped>
    .input-container {
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: 4px;
    }
</style>
