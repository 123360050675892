<template>
    <div class="frs-container">
        <div class="frs-datas">
            <h1>Etats fournisseurs</h1>
        </div>
        <div class="card shadow mb-4">
            <!-- Card Header - Dropdown -->
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h5>Informations détaillées fournisseurs</h5>
            </div>
            <!-- Card Body -->
            <div class="pt-2 pb-2 pl-5 pr-5">
                <SearchFrs @selection="selection" />
                <div v-if="frsRawDetails != null && frsCaByGamme.find(el => el.GammeId == 0)">
                    <small v-if="computedPeriod">{{ computedPeriod }}</small>
                    <div class="d-flex">
                        <h2>Fournisseur : {{ selectedFrs ? selectedFrs.Name : "N/A" }}</h2>
                        <img v-if="frsValidationData && frsValidationData.IsLnL" class="logo-lnl ml-4" src='logo-lnl.png' />
                        <img v-if="isLS" class="logo-lnl ml-4" src='logo-ls.png' />
                    </div>
                    <div class="d-flex flex-row justify-content-between">
                        <div>
                            <strong>{{ formatNumberEur((annee == 2019 ? (cumulativeSelf ? cumulativeSelf.Ca : 0) :
                                frsCaByGamme.find(el =>
                                    el.GammeId == 0).CA)) }}</strong> de chiffre d'affaire<br />
                            <strong>{{ cumulativeSelf ? cumulativeSelf.PdvFactureG : 0 }}</strong> points de vente facturés
                        </div>
                        <div class="d-flex flew-column">
                            <div v-if="frsValidationData && frsValidationData.IsOdw"
                                class="d-flex flex-column align-items-end">
                                <div v-if="frsValidationData && frsValidationData.UserGuid">
                                    L'accès à l'ODW3 a été <span v-if="!frsValidationData.State">dé-</span>validé par
                                    <strong>{{ frsValidationData.UserFirstName }} {{ frsValidationData.UserLastName
                                    }}</strong>
                                    ({{ frsValidationData.UserEmail }})<br />
                                    Le {{ formatNb(new Date(frsValidationData.Date).getDate()) }}/{{ formatNb(new
                                        Date(frsValidationData.Date).getMonth() + 1) }}/{{ formatNb(new
        Date(frsValidationData.Date).getFullYear()) }}
                                    <br />
                                    Commentaire: {{ frsValidationData.Comment }}
                                </div>
                                <div v-else>
                                    L'accès à l'ODW3 n'a pas encore été validé
                                </div>
                                <button v-if="frsValidationData && frsValidationData.UserGuid && frsValidationData.State"
                                    class="btn btn-danger" v-on:click="modalOpen = true">Dé-valider le fournisseur</button>
                                <button v-else class="btn btn-success" v-on:click="modalOpen = true">Valider le
                                    fournisseur</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="annee == 2019">
                        <h3>Cumul FRS: {{ (formatNumberEur(cumulativeDetails.length > 0 ? cumulativeDetails[0].CaMarche :
                            0)) }}</h3>
                        <table class="table table-hover table-sm">
                            <thead class="thead-light">
                                <tr>
                                    <td></td>
                                    <th class="sep" colspan="2">Part de marché</th>
                                    <th class="sep" colspan="6">Taux de Pénétration</th>
                                    <th class="sep" colspan="8">Panier moyen</th>
                                </tr>
                                <tr>
                                    <td></td>
                                    <th>Mon CA</th>
                                    <th class="sep">Ma PDM</th>
                                    <th>Taux moyen marché</th>
                                    <th>PDV facturés marché</th>
                                    <th>Mon taux</th>
                                    <th>PDV facturés chez moi</th>
                                    <th>Rappel scoring</th>
                                    <th class="sep">PDV facturés</th>
                                    <th>Panier moyen marché</th>
                                    <th>Somme des PDV du marché</th>
                                    <th>Panier moyen concurrents</th>
                                    <th>Somme des PDV concurrents</th>
                                    <th>Mon panier moyen</th>
                                    <th>Somme des PDV clients chez moi</th>
                                    <th>Panier moyen PDV</th>
                                    <th>PDV réalisant du CA avec 1 ou n FRS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row of cumulativeDetails"
                                    :class="{ 'table-success': row.FrsGuid === selectedFrs.Guid }"
                                    :key="`cumulative-${row.FrsGuid}`">
                                    <th>{{ row.FrsName }}</th>
                                    <td>{{ formatNumberEur(row.Ca) }}</td>
                                    <td class="sep">{{ row.pdm }} %</td>
                                    <td>{{ row.txMoyenMarche }} %</td>
                                    <td>{{ row.PdvFactureMarcheG }}</td>
                                    <td>{{ row.tx }} %</td>
                                    <td>{{ row.PdvFactureG }}</td>
                                    <td>{{ row.scoring }} %</td>
                                    <td class="sep">{{ row.pdvFactureMarcheDoublons }}</td>
                                    <td>{{ formatNumberEur(row.pmMarche) }}</td>
                                    <td>{{ row.pdvFactureMarcheDoublons }}</td>
                                    <td>{{ formatNumberEur(row.pmConcurrents) }}</td>
                                    <td>{{ row.pdvFactureConcurrentsDoublons }}</td>
                                    <td>{{ formatNumberEur(row.pm) }}</td>
                                    <td>{{ row.PdvFactureG }}</td>
                                    <td>{{ formatNumberEur(row.pmPdv) }}</td>
                                    <td>{{ row.PdvFactureMarcheG }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else>
                        <h3>Chiffre d'affaire par gamme</h3>
                        <ul>
                            <li v-for="row of this.frsCaByGamme" :key="row.GammeId">
                                <strong>{{ row.GammeName }}</strong> : {{ formatNumberEur(row.CA) }}
                            </li>
                        </ul>
                        <!--<table class="table table-hover table-sm table-responsive">
                            <thead class="thead-light">
                                <tr>
                                    <th class="sep">Gamme</th>
                                    <th class="sep">CA</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row of this.frsCaByGamme" :key="row.GammeId">
                                    <td>{{row.GammeName}}</td>
                                    <td>{{row.CA}}</td>
                                </tr>
                            </tbody>
                        </table>-->
                    </div>
                    <div v-for="({ rows, portee }) of sortedFrsDetails" :key="portee">
                        <h3>{{ portee }} : {{ formatNumberEur((rows.length > 0 ? rows[0].CaMarche : 0)) }}</h3>
                        <table class="table table-hover table-sm">
                            <thead class="thead-light">
                                <tr>
                                    <td></td>
                                    <th class="sep" colspan="2">Part de marché</th>
                                    <th class="sep" colspan="6">Taux de Pénétration</th>
                                    <th class="sep" colspan="8">Panier moyen</th>
                                </tr>
                                <tr>
                                    <td></td>
                                    <th>Mon CA</th>
                                    <th class="sep">Ma PDM</th>
                                    <th>Taux moyen marché</th>
                                    <th>PDV facturés marché</th>
                                    <th>Mon taux</th>
                                    <th>PDV facturés chez moi</th>
                                    <th>Rappel scoring</th>
                                    <th class="sep">PDV facturés</th>
                                    <th>Panier moyen marché</th>
                                    <th>Somme des PDV du marché</th>
                                    <th>Panier moyen concurrents</th>
                                    <th>Somme des PDV concurrents</th>
                                    <th>Mon panier moyen</th>
                                    <th>Somme des PDV clients chez moi</th>
                                    <th>Panier moyen PDV</th>
                                    <th>PDV réalisant du CA avec 1 ou n FRS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row of rows" :class="{ 'table-secondary': row.FrsGuid === selectedFrs.Guid }"
                                    :key="row.FrsGuid">
                                    <th>{{ row.FrsName }}</th>
                                    <td>{{ formatNumberEur(row.Ca) }}</td>
                                    <td class="sep">{{ row.pdm }} %</td>
                                    <td>{{ row.txMoyenMarche }} %</td>
                                    <td>{{ row.PdvFactureMarche }}</td>
                                    <td>{{ row.tx }} %</td>
                                    <td>{{ row.PdvFacture }}</td>
                                    <td>{{ row.scoring }} %</td>
                                    <td class="sep">{{ row.pdvFactureMarcheDoublons }}</td>
                                    <td>{{ formatNumberEur(row.pmMarche) }}</td>
                                    <td>{{ row.pdvFactureMarcheDoublons }}</td>
                                    <td>{{ formatNumberEur(row.pmConcurrents) }}</td>
                                    <td>{{ row.pdvFactureConcurrentsDoublons }}</td>
                                    <td>{{ formatNumberEur(row.pm) }}</td>
                                    <td>{{ row.PdvFacture }}</td>
                                    <td>{{ formatNumberEur(row.pmPdv) }}</td>
                                    <td>{{ row.PdvFactureMarcheG }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            :title="(frsValidationData && frsValidationData.State) ? 'Dé-validation de l\'accès ODW3 au fournisseur' : 'Validation de l\'accès ODW3 au fournisseur'"
            :open="modalOpen" @close="modalOpen = false">
            <form @submit.prevent="validateSupplier" class="form">
                <div v-if="frsValidationData && !frsValidationData.State">
                    <div class="form-row">
                        <div class="form-group col">
                            <input type="text" v-model="validationContactUserFirstname" class="form-control"
                                placeholder="Prénom" required />
                            <small
                                v-if="frsValidationData && frsValidationData.Contact && frsValidationData.Contact.UserFirstname">Prénom
                                par défaut: <strong>{{ frsValidationData.Contact.UserFirstname }}</strong></small>
                        </div>
                        <div class="form-group col">
                            <input type="text" v-model="validationContactUserLastname" class="form-control"
                                placeholder="Nom" required />
                            <small
                                v-if="frsValidationData && frsValidationData.Contact && frsValidationData.Contact.UserFirstname">Nom
                                par défaut: <strong>{{ frsValidationData.Contact.UserLastname }}</strong></small>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="email" v-model="validationContactEmail" class="form-control"
                            placeholder="Mail de contact" required />
                        <small v-if="frsValidationData && frsValidationData.Contact && frsValidationData.Contact.Mail">Mail
                            par défaut: <strong>{{ frsValidationData.Contact.Mail }}</strong></small>
                    </div>
                    <hr />
                </div>
                <textarea rows="5" v-model="validationComment" placeholder="Commentaire (facultatif)"
                    class="form-control mb-4"></textarea>
                <input type="submit" value="Sauvegarder" class="form-control btn btn-success" />
            </form>
        </Modal>
    </div>
</template>

<script>
//import Vue from "vue";
import { store, mutations } from "../store/store";
import { fetchApi } from "@/utils";
//import { cdmValidationMutations } from "../store/cdmValidation";

import SearchFrs from "./SearchFrs.vue";
import Modal from "../Modal.vue";

export default {
    name: 'Frs',
    components: {
        SearchFrs,
        Modal
    },
    data() {
        return {
            store,
            modalOpen: false,
            mutations,
            selectedFrs: null,
            frsRawDetails: null,
            frsValidationData: null,
            frsCaByGamme: null,
            validationComment: "",
            validationContactEmail: "",
            validationContactUserFirstname: "",
            validationContactUserLastname: "",
            computedPeriod: null
        };
    },
    watch: {
        "store.calculatorFilters.idPeriode": function () {
            this.selection(this.selectedFrs);
        }
    },
    methods: {
        formatNb(n) {
            return (n < 10 ? "0" : "") + n;
        },
        selection(frs) {
            if (frs !== null) {
                this.selectedFrs = frs;
                this.getDetails();
                this.getValidationData();
                if (this.store.calculatorFilters.idPeriode != 19) {
                    this.getFrsCaByGamme();
                }
            }
        },
        getFrsCaByGamme() {
            const path = "fournisseur/ca";
            fetchApi(`${path}?supplierGuid=${this.selectedFrs.Guid}&idPeriode=${this.store.calculatorFilters.idPeriode}`)
                .then(res => {
                    const { data } = res;
                    this.computedPeriod = data && data.length > 0 ? data[0].PeriodLabel : null;
                    this.frsCaByGamme = data.sort((a, b) => {
                        if (a.GammeId == 0) return -1;
                        return b.CA - a.CA
                    });
                })
        },

        getValidationData() {
            const path = "cdm/validation";
            fetchApi(`${path}?frsGuid=${this.selectedFrs.Guid}&idPeriode=${this.store.calculatorFilters.idPeriode}`)
                .then(res => {
                    const { data } = res;
                    this.frsValidationData = data;
                    this.validationComment = data && data.Comment ? data.Comment : "";
                    this.validationContactEmail = data.ContactEmail ? data.ContactEmail : (data && data.Contact && data.Contact.Mail ? data.Contact.Mail : "");
                    this.validationContactUserFirstname = data && data.Contact && data.Contact.UserFirstname ? data.Contact.UserFirstname : "";
                    this.validationContactUserLastname = data && data.Contact && data.Contact.UserLastname ? data.Contact.UserLastname : "";
                })
        },
        getDetails() {
            const path = "fournisseur/concurrents/details";
            fetchApi(`${path}?supplierGuid=${this.selectedFrs.Guid}&idPeriode=${this.store.calculatorFilters.idPeriode}`)
                .then(res => {
                    const { data } = res;
                    this.frsRawDetails = data;
                })
        },
        computeAdditionalData(data) {
            let res = Object.assign({}, data);
            let porteeRows = this.frsRawDetails.filter(row => row.PorteeName === data.PorteeName);
            const caMarche = porteeRows.reduce((cumul, row) => cumul + row.Ca, 0);
            const pdm = data.Ca / caMarche * 100;

            const txMoyenMarche = data.PdvFactureMarche / data.PdvFactureCMEM * 100;
            const tx = data.PdvFacture / data.PdvFactureMarche * 100;
            const scoring = data.PdvFacture / data.PdvFactureCMEM * 100;
            const pdvFactureMarcheDoublons = porteeRows.reduce((cumul, row) => cumul + row.PdvFacture, 0);
            const pdvFactureConcurrentsDoublons = pdvFactureMarcheDoublons - data.PdvFacture;

            const caConcurrents = caMarche - data.Ca;
            const pmMarche = caMarche / pdvFactureMarcheDoublons;
            //const pmConcurrents = caConcurrents / data.PdvFactureConcurrents;
            const pmConcurrents = caConcurrents / pdvFactureConcurrentsDoublons;
            const pm = data.Ca / data.PdvFacture;
            const pmPdv = caMarche / data.PdvFactureMarche;

            res.CaMarche = caMarche;
            res.pdm = pdm.toFixed(2);
            res.txMoyenMarche = txMoyenMarche.toFixed(2);
            res.tx = tx.toFixed(2);
            res.scoring = scoring.toFixed(2);
            res.pdvFactureMarcheDoublons = pdvFactureMarcheDoublons;
            res.pdvFactureConcurrentsDoublons = pdvFactureConcurrentsDoublons;
            res.pmMarche = pmMarche.toFixed(2);
            res.pmConcurrents = pmConcurrents.toFixed(2);
            res.pm = pm.toFixed(2);
            res.pmPdv = pmPdv.toFixed(2);
            return res;
        },
        computeCumulativeData(data) {
            let res = Object.assign({}, data);
            let porteeRows = this.frsRawDetails.filter((row, index) => index === this.frsRawDetails.findIndex(r => r.FrsGuid === row.FrsGuid)); // Ne garder qu'une ligne pour les pdvFactureG
            const caMarche = this.frsRawDetails.reduce((cumul, row) => cumul + row.Ca, 0); // Cumul sur TOUS les Ca
            const ca = this.frsRawDetails.reduce((cumul, row) => cumul + (row.FrsGuid === data.FrsGuid ? row.Ca : 0), 0); // Cumul sur les différentes portées du frs
            const pdm = ca / caMarche * 100;

            const txMoyenMarche = data.PdvFactureMarcheG / data.PdvFactureCMEM * 100;
            const tx = data.PdvFactureG / data.PdvFactureMarcheG * 100;
            const scoring = data.PdvFactureG / data.PdvFactureCMEM * 100;
            const pdvFactureMarcheDoublons = porteeRows.reduce((cumul, row) => cumul + row.PdvFactureG, 0);
            const pdvFactureConcurrentsDoublons = pdvFactureMarcheDoublons - data.PdvFactureG;

            const caConcurrents = caMarche - ca;
            const pmMarche = caMarche / pdvFactureMarcheDoublons;
            //const pmConcurrents = caConcurrents / data.PdvFactureConcurrentsG;
            const pmConcurrents = caConcurrents / pdvFactureConcurrentsDoublons;
            const pm = ca / data.PdvFactureG;
            const pmPdv = caMarche / data.PdvFactureMarcheG;

            res.CaMarche = caMarche;
            res.pdm = pdm.toFixed(2);
            res.txMoyenMarche = txMoyenMarche.toFixed(2);
            res.tx = tx.toFixed(2);
            res.scoring = scoring.toFixed(2);
            res.pdvFactureMarcheDoublons = pdvFactureMarcheDoublons;
            res.pdvFactureConcurrentsDoublons = pdvFactureConcurrentsDoublons;
            res.pmMarche = pmMarche.toFixed(2);
            res.pmConcurrents = pmConcurrents.toFixed(2);
            res.pm = pm.toFixed(2);
            res.pmPdv = pmPdv.toFixed(2);
            res.Ca = ca;
            return res;
        },

        // Valide ou dé-valide un fournisseur
        validateSupplier() {
            const path = "cdm/validation/update";
            const status = this.frsValidationData && this.frsValidationData.State ? 0 : 1;
            fetchApi(`${path}?frsGuid=${this.selectedFrs.Guid}&status=${status}&comment=${this.validationComment}&email=${this.validationContactEmail}&firstname=${this.validationContactUserFirstname}&lastname=${this.validationContactUserLastname}&idPeriode=${this.store.calculatorFilters.idPeriode}`)
                .then(() => {
                    this.getValidationData();
                    this.modalOpen = false;
                })
        },
        formatNumberEur(number) {
            return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(number);
        },
    },
    computed: {
        isLS() {
            if (this.selectedFrs) {
                return this.selectedFrs.N1 === "Agencement - Peinture - Décoration" ||
                    this.selectedFrs.N1 === "Outillage" ||
                    this.selectedFrs.N1 === "Quincaillerie";
            } return false;

        },
        annee() {
            const period = this.store.perioFourni.annees.find(el => el.periodId === this.store.calculatorFilters.idPeriode)
            if (period) return period.annee
            return -1
        },
        frsDetails() { // Format raw details into structured object with computed datas
            let res = {};
            if (!this.frsRawDetails) return res;
            for (let row of this.frsRawDetails) {
                if (!(row.PorteeName in res))
                    res[row.PorteeName] = [];
                const data = this.computeAdditionalData(row);
                res[row.PorteeName].push(data);
            }
            return res;
        },
        sortedFrsDetails() {
            let res = []
            Object.keys(this.frsDetails).sort((grpA, grpB) => {
                let grpTypeA = grpA.match(/^(.*)/i)[0];
                let grpTypeB = grpB.match(/^(.*)/i)[0];
                if (/Grp/.test(grpTypeA)) {
                    if (/Grp/.test(grpTypeB)) {
                        return grpA < grpB ? -1 : 1;
                    }
                    return -1; // Grp A forcément prioritaire
                }
                return grpA < grpB ? -1 : 1;
            }).forEach(portee => res.push({ portee, rows: this.frsDetails[portee].slice().sort((rowA, rowB) => rowB.Ca - rowA.Ca) }))


            return res;
        },
        cumulativeDetails() {
            let res = []
            if (!Object.keys(this.frsDetails).length > 1) return null;
            for (let row of this.frsRawDetails) {
                if (res.findIndex(r => r.FrsGuid === row.FrsGuid) < 0)
                    res.push(this.computeCumulativeData(row));
            }
            return res.sort((rowA, rowB) => rowB.Ca - rowA.Ca);
        },
        cumulativeSelf() {
            return this.cumulativeDetails.find((row) => row.FrsGuid === this.selectedFrs.Guid);
        }
    },
    mounted() {
        this.mutations.initFournisseurs();
    },
    filters: {
    }

}
</script>

<style scoped>
.frs-container {
    padding: 12px 42px;
    width: calc(100% - 250px);
    height: 94vh;
    overflow-y: scroll;
    box-sizing: border-box;
    background-color: #F3F3F3;
}

span {
    padding: 0;
}

h1 {
    color: var(--primary);
    text-align: center;
    font-size: 2.5rem;
    font-weight: 100;
}

h3 {
    margin-top: 3em;
    font-size: 1.3em;
}

h5 {
    color: var(--primary);
    font-weight: 100;
    margin: 0;
}

.table thead {
    text-align: center;
}

.table tbody td {
    text-align: right;
    white-space: nowrap;
}

.table .sep {
    margin: 0;
    height: 100%;
    border-right: 1px solid #CCC;
}
</style>
