////import Vue from "vue";
import { store as globalStore } from "../../store/store";
import { fetchApi, fetchApiWithCache } from "@/utils";
import L from "leaflet";
import "leaflet.markercluster";
var esri = require("esri-leaflet");
import { reactive } from "vue";

export let store = new reactive({
  map: null,
  layer: null,
  regions: [],
  departements: [],
  dataLoaded: false,
  selectedPlace: {
    region: null,
    departement: null,
  },
  dataMap: null,
});

//#region Handle Map
export function initData() {
  return new Promise((resolve) => {
    if (store.dataLoaded) {
      resolve();
      return;
    }

    store.regions = [];
    store.departements = [];
    store.communes = [];

    initRegions().then(() => {
      initDepartements().then(() => {
        initCommunes().then(() => {
          store.dataLoaded = true;
          resolve();
        });
      });
    });
  });
}
// Initialise la carte
export function initMap() {
  // Création de la map
  if (store.map) {
    try {
      store.map.off();
      store.map.remove();
    } finally {
      store.map = null;
    }
  }
  store.map = L.map("prosperence-map", {
    maxBounds: [
      [84.91473, -177.1875],
      [-85.098989645, 201.09375],
    ],
  }).setView([46.7890461, 1.7448433], 6.4);
  // Fond de la map
  store.layer = esri.basemapLayer("Gray").addTo(store.map);
  // On gère le clic sur la carte (utilisé pour rechercher autour d'un point)
  // store.map.on("mousedown", handleMapClick);
}

// On gère le clic sur la carte (utilisé pour rechercher autour d'un point)
// function handleMapClick(e) {
// }

/**
 *  INIT
 */

// Récupération des régions et chargement des différents bloc de recherche et des points sur la carte
function initRegions() {
  return new Promise((resolve, reject) => {
    fetchApi("region", {}, "maps").then((regions) => {
      fetchApi("decoupage/geographique", {}, "maps")
        .then((decoupage) => {
          const regionsObjList = populateRegions(regions.data, decoupage.data);
          store.regions = regionsObjList;
          resolve();
        })
        .catch(reject);
    });
  });
}

// Récupération des régions et chargement des différents bloc de recherche et des points sur la carte
function initDepartements() {
  return new Promise((resolve, reject) => {
    fetchApi("departement", {}, "maps")
      .then((response) => {
        store.departements = populateDepartements(response.data);
        resolve();
      })
      .catch(reject);
  });
}

// Récupère toutes les communes
function initCommunes() {
  return new Promise((resolve, reject) => {
    fetchApi("commune", {}, "maps")
      .then((response) => {
        store.communes = populateCommunes(response.data);
        resolve();
      })
      .catch(reject);
  });
}

/**
 *  POPULATE
 */

// Met au bon format les informations des régions
function populateRegions(regions, decoupage) {
  var returnArray = [];
  regions.forEach((region) => {
    var reg = {
      Id: region.Id,
      IdNational: region.IdNational,
      Name: region.Nom,
      RawData: region.RawData,
      Active: true,
      LeafletObject: L.geoJson(JSON.parse(region.RawData), {
        onEachFeature: onRegionClick,
        style: globalStore.mapParams.region,
      }),
      Departements: decoupage
        .filter((d) => d.IdRegion == region.IdNational)
        .map((d) => d.IdDepartement),
    };
    returnArray.push(reg);
  });

  //returnArray.sort(compareName);
  return returnArray;
}

// Met au bon format les informations des départements
function populateDepartements(departements) {
  var returnArray = [];
  departements.forEach((departement) => {
    var returnDepartement = {
      Id: departement.Id,
      IdNational: departement.IdNational,
      Name: departement.Nom,
      Active: false,
      RawData: JSON.parse(departement.RawData),
      LeafletObject: L.geoJson(JSON.parse(departement.RawData), {
        onEachFeature: onDepartementClick,
        style: globalStore.mapParams.departement,
      }),
      LeafletObjectAdvs: L.geoJson(JSON.parse(departement.RawData), {
        style: globalStore.mapParams.departementAdvs,
      }),
      Communes: [],
    };

    returnArray.push(returnDepartement);
  });

  return returnArray;
}

// Met au bon format les informations des communes
function populateCommunes(communes) {
  var returnArray = [];
  communes.forEach((commune) => {
    try {
      if (commune.IdDepartement == "2A" || commune.IdDepartement == "2B") {
        commune.IdDepartement = "20";
      }

      var returnCommune = {
        Id: commune.Id,
        IdNational: commune.IdNational,
        IdDepartement: commune.IdDepartement,
        Name: commune.Nom,
        Active: false,
        RawData: JSON.parse(commune.RawData),
        LeafletObject: L.geoJson(JSON.parse(commune.RawData), {
          // onEachFeature: onDepartementClick,
          style: { ...globalStore.mapParams.commune, weight: 1, opacity: 0.5 },
        }),
      };

      returnArray.push(returnCommune);

      const dep = store.departements.find(
        (d) => d.IdNational == commune.IdDepartement,
      );

      if (dep) {
        dep.Communes.push(commune.IdNational);
      }
    } catch (e) {
      console.error(e);
    }
  });

  return returnArray;
}

// affiche les régions sur la carte
function displayRegions() {
  // On réinitialise la couleur des régions
  store.regions.forEach((r) => {
    r.LeafletObject.setStyle(globalStore.mapParams.region);
  });

  // On ajoute toutes les régions à la carte
  store.regions.forEach((region) => {
    if (region.IdNational != store.selectedPlace.region?.IdNational) {
      region.LeafletObject.addTo(store.map);
      region.LeafletObject.setStyle(getPlaceStyle(region.IdNational));
    }
  });
}

// affiche les départements sélectionnés sur la carte
function displayDepartements() {
  selectedDeps()?.forEach((idNational) => {
    const dep = store.departements.find((dep) => dep.IdNational == idNational);
    if (dep && idNational != store.selectedPlace.departement?.IdNational) {
      dep.LeafletObject.addTo(store.map);
      dep.LeafletObject.setStyle(getPlaceStyle(dep.IdNational));
    }
  });
}

function displayCommunes() {
  selectedCommunes()?.forEach((idNational) => {
    const commune = store.communes.find((c) => c.IdNational == idNational);
    if (commune) {
      // On affiche les communes sélectionnés
      commune.LeafletObject.addTo(store.map);
      commune.LeafletObject.setStyle(getPlaceStyle(commune.IdNational));
    }
  });
}

function selectedDeps() {
  if (!store.selectedPlace.region) return null;
  const region = store.regions.find(
    (r) => r.IdNational == store.selectedPlace.region.IdNational,
  );
  if (!region) return null;
  return region.Departements;
}

function selectedCommunes() {
  if (!store.selectedPlace.departement) return null;
  const dep = store.departements.find(
    (d) => d.IdNational == store.selectedPlace.departement.IdNational,
  );
  if (!dep) return null;
  return dep.Communes;
}
// clic sur une région
function onRegionClick(feature, featureLayer) {
  featureLayer.on("click", function (e) {
    // centrer la carte sur la région
    store.map.fitBounds(e.target.getBounds());

    store.selectedPlace.region = {
      Nom: e.sourceTarget.feature.properties.nom,
      IdNational: e.sourceTarget.feature.properties.code,
      Bounds: e.target.getBounds(),
    };

    //DOM-TOM
    if (
      ["01", "02", "03", "04", "05", "06"].indexOf(
        store.selectedPlace.region.IdNational,
      ) !== -1
    )
      store.selectedPlace.region.DomTomDepId =
        "97" + store.selectedPlace.region.IdNational.slice(1);

    store.selectedPlace.departement = null;
  });
}

// clic sur un département
function onDepartementClick(feature, featureLayer) {
  featureLayer.on("click", function (e) {
    // centrer la carte sur la région
    store.map.fitBounds(e.target.getBounds());

    store.selectedPlace.departement = {
      Nom: e.sourceTarget.feature.properties.nom,
      IdNational: e.sourceTarget.feature.properties.code,
      Bounds: e.target.getBounds(),
    };
  });
}

// Affiche la carte en fonction des données
export async function render(scope, filter) {
  // On réinitialise tous les layers (regions, clusters, markers)
  store.map.eachLayer(function (layer) {
    store.map.removeLayer(layer);
  });

  //On ajoute la carte de base avec couleur grise par défaut
  store.layer = esri.basemapLayer("Gray").addTo(store.map);

  // On affiche les régions
  // On affiche les départements sélectionnés si besoin (zone commerciaux ou isOdw = 0)
  await getData(scope, filter);

  displayRegions();
  displayDepartements();

  // On affiche les communes sélectionnées si besoin
  displayCommunes();
}
//#endregion

//#region GetColor

// Get color from red to green
function getColorShade(value) {
  const red = { r: 163, g: 0, b: 11 };
  const green = { r: 0, g: 245, b: 143 };

  const result = {
    // Get value ponderation from red.r to green.r
    r: parseInt((green.r - red.r) * value + red.r),
    g: parseInt((green.g - red.g) * value + red.g),
    b: parseInt((green.b - red.b) * value + red.b),
  };

  return `rgb(${result.r}, ${result.g}, ${result.b})`;
}

// Get style commune
// eslint-disable-next-line no-unused-vars
function getPlaceStyle(placeIdNational) {
  // On met le style "commune" par défaut
  let style = { ...globalStore.mapParams.commune, weight: 1, opacity: 0.5 };

  try {
    // On récupère le score lié au code IdNational
    let score = store.dataMap.find(
      (d) => d.IdNational == placeIdNational,
    )?.Score;

    // Si pas de score, on prend 0
    if (score == null) {
      score = Math.random();
    }

    // On modifie le style
    if (score !== null && score !== undefined) {
      // On récupère une couleur sur un gradient rouge => vert selon le score (de 0 à 1)
      style.color = getColorShade(score);
      style.fillColor = style.color;
    }

    return style;
  } catch (e) {
    return style;
  }
}
//#endregion

//#region Handle Data
export function getData(scope, filter) {
  // On récupère les données en fonction du scope (nombre d'entreprises) et du filtre (valeurs des différents filtres)
  return new Promise((resolve) => {
    fetchApiWithCache(
      "api/prosperence?regionIdNational=" +
        store.selectedPlace.region?.IdNational +
        "&depIdNational=" +
        store.selectedPlace.departement?.IdNational +
        "&scope=" +
        scope +
        "&filter=" +
        filter,
    ).then((res) => {
      store.dataMap = res?.data;

      resolve();
    });
  });
}
//#endregion
