<template>
  <div id="prosperence-map"></div>
</template>

<script>
import { initData, initMap, render, store } from "./map.js";
export default {
  name: "MapProsperence",
  props: ["scope", "filter"],
  data() {
    return { store };
  },

  mounted() {
    initData().then(() => {
      initMap();
      render(this.scope, this.filter);
    });
  },
  computed: {
    mapDatas() {
      return {
        scope: this.scope,
        filter: this.filter,
        region: this.store.selectedPlace.region,
        dep: this.store.selectedPlace.departement,
      };
    },
  },
  watch: {
    mapDatas() {
      // Si l'un des filtres change, on actualise la carte
      render(this.scope, this.filter);
    },
  },
};
</script>

<style scoped>
#prosperence-map {
  flex-grow: 1;
}
</style>
