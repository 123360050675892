////import Vue from "vue";
import { store, mutations } from "./store";
import { fetchApi } from "@/utils";
import L from "leaflet";
import "leaflet.markercluster";
import { pointDeVenteMutations } from "./pointDeVente";
import { dataFinanceMutations } from "./dataFinance";
import { advMutations } from "./adv";
var esri = require("esri-leaflet");
import { VueApp as Vue } from "@/main.js";

// Coloration des zones de carte
function setStyleMapParams() {
  return new Promise((resolve, reject) => {
    fetchApi("maps/parameters", {}, "maps")
      .then((response) => {
        response.data.forEach((param) => {
          const scope =
            param.Scope.slice(0, 1).toLowerCase() + param.Scope.slice(1);
          if (/pdmSeuil/.test(scope)) store.mapParams[scope] = param.Color;
          else
            store.mapParams[scope] = {
              color: param.Color,
              fillColor: param.InnerColor,
            };
        });
        resolve();
      })
      .catch(reject);
  });
}

// Initialise la carte
export function initMap() {
  // Création de la map
  if (store.dataMap.map) {
    try {
      store.dataMap.map.off();
      store.dataMap.map.remove();
    } finally {
      store.dataMap.map = null;
    }
  }
  store.dataMap.map = L.map("map", {
    maxBounds: [
      [84.91473, -177.1875],
      [-85.098989645, 201.09375],
    ],
  }).setView([46.7890461, 1.7448433], 6.4);
  // Fond de la map
  store.dataMap.layer = esri.basemapLayer("Gray").addTo(store.dataMap.map);
  // On gère le clic sur la carte (utilisé pour rechercher autour d'un point)
  store.dataMap.map.on("mousedown", handleMapClick);

  render();
}

// On gère le clic sur la carte (utilisé pour rechercher autour d'un point)
function handleMapClick(e) {
  if (!store.calculatorFilters.pdvDistance.selecting) return;

  store.calculatorFilters.pdvDistance.latlng = e.latlng;
  store.calculatorFilters.pdvDistance.selecting = false;
  document.getElementById("map").classList.remove("selecting");

  dataFinanceMutations.loadDataFinance();
}

/**
 *  INIT
 */

// Récupération des régions et chargement des différents bloc de recherche et des points sur la carte
function initRegions() {
  return new Promise((resolve, reject) => {
    fetchApi("region", {}, "maps").then((regions) => {
      fetchApi("decoupage/geographique", {}, "maps")
        .then((decoupage) => {
          const regionsObjList = populateRegions(regions.data, decoupage.data);
          store.dataMap.regions = regionsObjList;
          resolve();
        })
        .catch(reject);
    });
  });
}

// Récupération des régions et chargement des différents bloc de recherche et des points sur la carte
function initDepartements() {
  return new Promise((resolve, reject) => {
    fetchApi("departement", {}, "maps")
      .then((response) => {
        store.dataMap.departements = populateDepartements(response.data);
        advMutations.initAdvs().then(resolve);
      })
      .catch(reject);
  });
}

// Récupération des groupements
function initCentrales() {
  return new Promise((resolve, reject) => {
    fetchApi("groupement" + (store.self.isOdw ? "?isOdw=1" : ""), {}, "sdc")
      .then((response) => {
        store.dataAdherents.centrales = populateCentrales(response.data);
        store.dataAdherents.centralesCount =
          store.dataAdherents.centrales.length;
        //store.calculatorFilters.adhs = store.dataAdherents.centrales.map(c => c.Guid);
        resolve();
      })
      .catch(reject);
  });
}

// Récupère en base les paramètres d'affichage des adhérents
function initParamAdherents() {
  return new Promise((resolve, reject) => {
    fetchApi("adherents/parameters?isOdw=" + store.self.isOdw, {}, "maps")
      .then((response) => {
        store.adherentsParam = [];

        response.data.forEach((param) => {
          var p = {
            GroupementGuid: param.GroupementGuid,
            Logo: param.Logo,
            Marker: param.Marker,
            Wrapper: param.PopupWrapperColor,
            MarkerCircle: param.MarkerCircle,
          };

          store.adherentsParam.push(p);

          // On ajoute l'info aux centrales
          var centrale = store.dataAdherents.centrales.find(
            (e) => e.Guid == p.GroupementGuid,
          );
          if (centrale) {
            centrale.Marker = store.rootApi + "markers/" + p.Marker;
            centrale.MarkerCircle = store.rootApi + "markers/" + p.MarkerCircle;
          }
        });
        resolve();
      })
      .catch(reject);
  });
}

//Initialise les clusters par groupement
function initClusterByGroupement() {
  store.dataMap.markerCluster = store.dataAdherents.centrales.map((grp) => ({
    //On créer un objet donnée
    idGroupement: grp.Guid, //On lui donne l'id du groupment
    MarkerClusterGroup: new L.MarkerClusterGroup({
      //Initialisation du layer Cluster
      showCoverageOnHover: false, //Lors du survole une zone de polygonale apparait
      spiderfyOnMaxZoom: false, //Necessite un clique pour voir transformer les clusters en marqeurs lors du zoom
      disableClusteringAtZoom: 9, //Valeur de zoom pour laquelle les clusters sont aplatis et n'existe plus
      animate: true, //Animation
      animateAddingMarkers: true,

      removeOutsideVisibleBounds: true, //Améliore les performances (les marqueurs en dehors du champ de vision sont désactivés)
      iconCreateFunction: function (cluster) {
        return L.divIcon({
          html:
            '<span class="ClusterNumber">' +
            cluster.getChildCount() +
            "</span>" +
            '<div class="clusterImg"><img  src="' +
            grp.MarkerCircle +
            '"></div>',
          className: "cluster", //css
          iconSize: null, //Pas d'icone particulier (peut insérer une image)
        });
      },
    }),
  }));
}

/**
 *  POPULATE
 */

// Met au bon format les informations des régions
function populateRegions(regions, decoupage) {
  var returnArray = [];
  regions.forEach((region) => {
    var reg = {
      Id: region.Id,
      IdNational: region.IdNational,
      Name: region.Nom,
      RawData: region.RawData,
      Active: true,
      LeafletObject: L.geoJson(JSON.parse(region.RawData), {
        onEachFeature: onRegionClick,
        style: store.mapParams.region,
      }),
      Departements: decoupage.filter((d) => d.IdRegion == region.IdNational),
    };
    returnArray.push(reg);
  });

  //returnArray.sort(compareName);
  return returnArray;
}

// Met au bon format les informations des départements
function populateDepartements(departements) {
  var returnArray = [];
  departements.forEach((departement) => {
    var returnDepartement = {
      Id: departement.Id,
      IdNational: departement.IdNational,
      Name: departement.Nom,
      Active: false,
      RawData: JSON.parse(departement.RawData),
      LeafletObject: L.geoJson(JSON.parse(departement.RawData), {
        onEachFeature: onDepartementClick,
        style: store.mapParams.departement,
      }),
      LeafletObjectAdvs: L.geoJson(JSON.parse(departement.RawData), {
        style: store.mapParams.departementAdvs,
      }),
    };

    returnArray.push(returnDepartement);
  });

  returnArray.sort(mutations.compare);

  return returnArray;
}

// met au bon format les centrales
function populateCentrales(centrales) {
  var returnArray = centrales
    .map((centrale) => ({
      Id: centrale.IdCompta,
      Guid: centrale.Guid,
      Name: centrale.Name,
      Marker: "",
    }))
    .sort((a, b) => (a.Name < b.Name ? -1 : 1));

  return returnArray;
}

function updateRegionsStyleByPdm() {
  store.dataMap.regions.forEach((r) => {
    const details = store.financier.details;
    const selfCa =
      details.self.region && details.self.region[r.IdNational]
        ? details.self.region[r.IdNational].total
        : 0;
    const regionTot =
      details.region && details.region[r.IdNational]
        ? details.region[r.IdNational].total
        : 0;
    const pdm = regionTot !== 0 ? (selfCa / regionTot) * 100 : 0;
    //console.log(`PDM "${r.Name}": ${pdm}% (${selfCa} / ${regionTot})`);
    if (pdm >= store.mapParams.pdmSeuil3)
      r.LeafletObject.setStyle(store.mapParams.colorStat1);
    else if (pdm >= store.mapParams.pdmSeuil2)
      r.LeafletObject.setStyle(store.mapParams.colorStat2);
    else if (pdm >= store.mapParams.pdmSeuil1)
      r.LeafletObject.setStyle(store.mapParams.colorStat3);
    else r.LeafletObject.setStyle(store.mapParams.colorStat4);
  });
}

function updateRegionsStyleByTP() {
  store.dataMap.regions.forEach((r) => {
    const details = store.financier.details;
    const self =
      details.self.region && details.self.region[r.IdNational]
        ? details.self.region[r.IdNational].NbFactures
        : 0;
    const regionTot =
      details.region && details.region[r.IdNational]
        ? details.region[r.IdNational].NbFactures
        : 0;
    const tp = regionTot !== 0 ? (self / regionTot) * 100 : 0;
    //console.log(`TP "${r.Name}": ${tp}% (${self} / ${regionTot})`);
    if (tp >= store.mapParams.pdmSeuil3)
      r.LeafletObject.setStyle(store.mapParams.colorStat1);
    else if (tp >= store.mapParams.pdmSeuil2)
      r.LeafletObject.setStyle(store.mapParams.colorStat2);
    else if (tp >= store.mapParams.pdmSeuil1)
      r.LeafletObject.setStyle(store.mapParams.colorStat3);
    else r.LeafletObject.setStyle(store.mapParams.colorStat4);
  });
}

function updateRegionsStyleByPM() {
  store.dataMap.regions.forEach((r) => {
    const details = store.financier.details;
    const selfFactures =
      details.self.region && details.self.region[r.IdNational]
        ? details.self.region[r.IdNational].NbFactures
        : 0;
    const regionTotFactures =
      details.region && details.region[r.IdNational]
        ? details.region[r.IdNational].NbFacturesDoublons
        : 0;

    const selfCa =
      details.self.region && details.self.region[r.IdNational]
        ? details.self.region[r.IdNational].total
        : 0;
    const regionTotCa =
      details.region && details.region[r.IdNational]
        ? details.region[r.IdNational].total
        : 0;

    const selfPM = selfFactures !== 0 ? selfCa / selfFactures : 0;
    const regionPM =
      regionTotFactures !== 0 ? regionTotCa / regionTotFactures : 0;

    if (selfPM > regionPM) r.LeafletObject.setStyle(store.mapParams.colorStat1);
    else if (selfPM < regionPM)
      r.LeafletObject.setStyle(store.mapParams.colorStat4);
    else r.LeafletObject.setStyle(store.mapParams.colorStat2);
  });
}

// affiche les régions sur la carte
function displayRegions() {
  // On réinitialise la couleur des régions
  store.dataMap.regions.forEach((r) => {
    r.LeafletObject.setStyle(store.mapParams.region);
  });

  let isZoneDisplay = false;
  if (store.calculatorFilters.isClient == 1) {
    if (Vue.$router.currentRoute.value.path === "/zones/pdm") {
      isZoneDisplay = true;
      updateRegionsStyleByPdm();
    } else if (Vue.$router.currentRoute.value.path === "/zones/tp") {
      isZoneDisplay = true;
      updateRegionsStyleByTP();
    } else if (Vue.$router.currentRoute.value.path === "/zones/pm") {
      isZoneDisplay = true;
      updateRegionsStyleByPM();
    }
  }

  if (!isZoneDisplay) {
    // On change la couleur de la région sélectionnée
    const _region = store.dataMap.regions.find(
      (r) => r.IdNational === store.breadcrumb.Region.Id,
    );
    if (_region) _region.LeafletObject.setStyle(store.mapParams.regionSelected);
  }

  // On ajoute toutes les régions à la carte
  store.dataMap.regions.forEach((region) => {
    region.LeafletObject.addTo(store.dataMap.map);
  });
}

// affiche les départements sélectionnés sur la carte
function displayDepartements() {
  if (store.self.isOdw) {
    // Si ODW, dans certains cas on n'affiche pas les departements
    const isDepFilter =
      store.self.isDepFilter &&
      store.self.depFilter &&
      store.self.depFilter.length > 0;
    const isAdvRegion = store.breadcrumb.Region.Id == -1;
    const isCartoDisplay = Vue.$router.currentRoute.value.path == "/";

    const isAdvDisplay = isAdvRegion && isCartoDisplay;

    // Si on n'est pas en accès restreint (accès ADV uniquement sur ses deps)
    // et si on n'est pas non plus sur un affichage région ADV (région ADV ET affichage de la carte (pas les zones))
    // on n'affiche pas les départements
    if (!isDepFilter && !isAdvDisplay) return;
  }

  // On est sur zone Commerciaux => affichage des départements
  const depsToDisplay = selectedDeps();
  depsToDisplay.forEach((idNational) => {
    const dep = store.dataMap.departements.find(
      (dep) => dep.IdNational == idNational,
    );
    if (dep && store.breadcrumb.Region.Id === -1) {
      // On affiche les départements des commerciaux
      dep.LeafletObjectAdvs.addTo(store.dataMap.map);
    } else if (dep) {
      // On affiche les départements sélectionnés (ODW = 0)
      dep.LeafletObject.addTo(store.dataMap.map);
    }
  });
}

// Affiche le cercle autour de la sélection si sélection autour d'un point
function displayDistanceSelection() {
  if (!store.calculatorFilters.pdvDistance.latlng) return;

  const latlng = store.calculatorFilters.pdvDistance.latlng;

  // On créé le geojson du marker s'il n'existe pas
  const point = L.marker(latlng, {
    icon: L.icon({
      iconUrl: store.rootApi + "markers/marker-18.png",
      iconSize: [40, 40],
      iconAnchor: [20, 40],
      shadowUrl: store.rootApi + "markers/marker-shadow.png",
      shadowSize: [40, 32],
    }),
  });

  // On créé le geojson du cercle s'il n'existe pas
  const circle = L.circle(latlng, {
    radius: store.calculatorFilters.pdvDistance.distance * 1000,
    color: "#FFF",
    opacity: 0.9,
    fillOpacity: 0.3,
  });

  // On les affiche
  point.addTo(store.dataMap.map);
  circle.addTo(store.dataMap.map);
}

// Affichage des clusters
function displayClusters() {
  let filteredPdvs = store.dataAdherents.pdvs.filter((_pdv) => {
    if (store.self.isOdw) return true;
    if (store.calculatorFilters.pdvs.length === 0) return true;
    return store.calculatorFilters.pdvs.indexOf(_pdv.Id) !== -1;
  });

  store.dataMap.markerCluster.forEach((cluster) => {
    //Pour chaque cluster qui se trouve dans le tableau markerCluster
    if (cluster.MarkerClusterGroup != null) {
      // On vide le cluster
      cluster.MarkerClusterGroup.clearLayers();
    }

    filteredPdvs.forEach((_pdv) => {
      if (cluster.idGroupement === _pdv.IdGroupement) {
        //Si l'id du cluster est égal à l'id de groupement du PDV
        try {
          cluster.MarkerClusterGroup.addLayer(_pdv.LeafletObject); //On ajoute groupe du cluster le pdv
        } catch (e) {
          console.error("error on pdv:", _pdv);
        }
      }
    });

    if (cluster.MarkerClusterGroup != null) {
      //Si le groupe est != null
      if (cluster.MarkerClusterGroup.getChildCount != 0) {
        //(si il y'a un PDV dans le group)
        store.dataMap.map.addLayer(cluster.MarkerClusterGroup); //On ajoute la layer à la map
      }
    }
  });
}

// clic sur une région
function onRegionClick(feature, featureLayer) {
  featureLayer.on("click", function (e) {
    if (store.calculatorFilters.pdvDistance.selecting) return;
    if (
      store.self.isOdw &&
      store.self.isDepFilter &&
      store.self.depFilter &&
      store.self.depFilter.length > 0
    )
      return;

    // centrer la carte sur la région
    store.dataMap.map.fitBounds(e.target.getBounds());

    // Fix pour Suisse
    if (e.sourceTarget.feature.properties.code == "CHE") {
      store.breadcrumb.Pays.Nom = "Suisse";
      store.breadcrumb.Pays.Id = -2;
    }
    // Fix pour Belgique
    else if (e.sourceTarget.feature.properties.code == "00") {
      store.breadcrumb.Pays.Nom = "Belgique";
      store.breadcrumb.Pays.Id = -1;
    } else {
      store.breadcrumb.Pays.Nom = "France";
      store.breadcrumb.Pays.Id = 1;
    }

    store.breadcrumb.Region = {
      Nom: e.sourceTarget.feature.properties.nom,
      Id: e.sourceTarget.feature.properties.code,
      Bounds: e.target.getBounds(),
    };

    //DOM-TOM
    if (
      ["01", "02", "03", "04", "05", "06"].indexOf(
        store.breadcrumb.Region.Id,
      ) !== -1
    )
      store.breadcrumb.Region.DomTomDepId =
        "97" + store.breadcrumb.Region.Id.slice(1);

    store.breadcrumb.Departement = {
      Nom: "",
      Id: 0,
      Bounds: null,
    };

    // On réinitialise les commerciaux sélectionnés
    store.calculatorFilters.advs = [];

    if (store.self.isOdw == 0) pointDeVenteMutations.loadPdv();
    else dataFinanceMutations.loadDataFinance();
  });
}

// clic sur un département
function onDepartementClick(feature, featureLayer) {
  if (store.self.isOdw == 0) {
    featureLayer.on("click", function (e) {
      if (store.calculatorFilters.pdvDistance.selecting) return;
      //v.resetVilles();
      // centrer la carte sur la région
      store.dataMap.map.fitBounds(e.target.getBounds());

      store.breadcrumb.Departement = {
        Nom: e.sourceTarget.feature.properties.nom,
        Id: e.sourceTarget.feature.properties.code,
        Bounds: e.target.getBounds(),
      };
    });
  }
}

//Initialise tous les éléments dont a besoin la carte (couleurs/régions/départements)
export function init() {
  let okFetch = 0;

  return new Promise((resolve) => {
    // Ajoute 1 au compteur de requêtes terminées, si toutes les requêtes sont passées, on resolve()
    function verifyOk() {
      okFetch += 1;
      if (okFetch == 5) resolve();
    }

    //initMap();
    setStyleMapParams().then(() => {
      verifyOk();
      initRegions().then(verifyOk);
      initDepartements().then(verifyOk);
    });
    initCentrales().then(() => {
      verifyOk();
      initParamAdherents().then(() => {
        initClusterByGroupement();
        verifyOk();
      });
    });
  });
}

//Récupère la liste des départements sélectionnés en fonction des commerciaux sélectionnés/du breadcrumb
export function selectedDeps() {
  let res = [];
  if (
    store.self.isOdw &&
    store.self.isDepFilter &&
    store.self.depFilter &&
    store.self.depFilter.length > 0
  )
    return store.self.depFilter;
  if (store.breadcrumb.Region.Id === -1) {
    // On est sur zone commerciaux
    // On parcours les commerciaux sélectionnés
    store.calculatorFilters.advs.forEach((advId) => {
      const adv = store.self.commerciaux.find((_c) => _c.Id == advId);
      if (adv && adv.DepartementsToDisplay.length > 0) {
        const existingDeps = adv.DepartementsToDisplay.filter((dep) => {
          return store.dataMap.departements.find(
            (d) => d.IdNational == dep.IdNational,
          );
        });

        // On les ajoute à la liste des départements à afficher
        res.push(...existingDeps.map((dep) => dep.IdNational));
      }
    });
  } else if (store.breadcrumb.Region.Id !== 0) {
    // Une région est sélectionnée
    const region = store.dataMap.regions.find(
      (r) => r.IdNational === store.breadcrumb.Region.Id,
    );
    // On récupère les départements contenus dans la région
    const deps = region.Departements;
    deps.forEach((dep) => {
      //if (region.IdNational == "01" || region.IdNational == "02" || region.IdNational == "03" || region.IdNational == "04" || region.IdNational == "06") {
      //    dep = store.dataMap.departements.find(d => (d.IdNational == idDepartement.IdDepartement && region.Name == d.Name));
      //}
      var _dep = store.dataMap.departements.find(
        (d) => d.IdNational == dep.IdDepartement,
      );
      if (_dep) res.push(dep.IdDepartement);
    });

    if (
      ["01", "02", "03", "04", "05", "06"].indexOf(
        store.breadcrumb.Region.Id,
      ) !== -1
    ) {
      // DOM-TOM
      const domtomDepId = store.breadcrumb.Region.DomTomDepId;
      var _dep = store.dataMap.departements.find(
        (d) => d.IdNational == domtomDepId,
      );
      if (_dep) res.push(domtomDepId);
    }
  }
  return res;
}

// Affiche la carte en fonction des données
export function render() {
  // On réinitialise tous les layers (regions, clusters, markers)
  store.dataMap.map.eachLayer(function (layer) {
    store.dataMap.map.removeLayer(layer);
  });

  //On ajoute la carte de base avec couleur grise par défaut
  store.dataMap.layer = esri.basemapLayer("Gray").addTo(store.dataMap.map);

  // On affiche les régions
  displayRegions();
  // On affiche les départements sélectionnés si besoin (zone commerciaux ou isOdw = 0)
  displayDepartements();

  if (Vue.$router.currentRoute.value.path == "/") {
    // On affiche la zone sélectionnée via "pdv autour d'un point" s'il y a
    displayDistanceSelection();
    // On affiche les clusters de pdv
    displayClusters();
  }
}

export default { init, initMap, selectedDeps, render };
