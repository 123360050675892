<template>
    <div>
        <div class="header">
            <h2>
                Mes indicateurs
            </h2>
        </div>
        <h3>Répartition du CA par région</h3>
        <div class="row">
            <div class="col-6 d-flex align-items-center">
                <div class="graph">
                    <canvas id="chartRepartitionRegion" width="400" height="300"></canvas>
                </div>
            </div>
            <div class="table-container col-6">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th class="align-middle text-center">Couleur</th>
                            <th class="align-middle text-center">Région</th>
                            <th class="align-middle text-center">CA (€)</th>
                            <th class="align-middle text-center">%</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="regionSelf in regionDetailsSorted" :key="regionSelf.idNational">
                            <th v-bind:style="{ color: regionSelf.backgroundColor }">█</th>
                            <th>{{ regionSelf.name }}</th>
                            <td>{{ formatNumberEur(getCaTotalByRegion(regionSelf.idNational)) }}</td>
                            <td>{{ Math.round((getCaTotalByRegion(regionSelf.idNational) /
                                app.financier.details.self.caTotal) * 10000) / 100 }} %</td>
                        </tr>
                        <tr>
                            <th></th>
                            <th>TOTAL</th>
                            <td>{{ formatNumberEur(app.financier.details.self.caTotal) }}</td>
                            <td>100 %</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="indicateurs">
            <h3>Répartition du CA par groupement</h3>
            <div class="row">
                <div class="col-6 d-flex align-items-center">
                    <div class="graph">
                        <canvas id="chartRepartitionGroupement" width="400" height="300"></canvas>
                    </div>
                </div>
                <div class="tableau col-6">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="align-middle text-center">Couleur</th>
                                <th class="align-middle text-center">Groupement</th>
                                <th class="align-middle text-center">CA (€)</th>
                                <th class="align-middle text-center">%</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="groupement in grpDetailsSorted" :key="groupement.Guid">
                                <th v-bind:style="{ color: groupement.backgroundColor }">█</th>
                                <th>{{ groupement.name }}</th>
                                <td>{{ formatNumberEur(groupement.total) }}</td>
                                <td>{{ Math.round((groupement.total / app.financier.details.self.caTotal) * 10000) / 100 }}
                                    %
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <th>TOTAL</th>
                                <td>{{ formatNumberEur(app.financier.details.self.caTotal) }}</td>
                                <td>100 %</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store/store";
//import { dataFinanceMutations } from "../../components/store/dataFinance";
import Chart from "chart.js/auto";

export default {
    name: "ResultsGraphs",
    data() {
        return {
            app: store,
            charts: {
                repartitionRegion: null,
                repartitionGroupement: null,
            }
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        "app.financier.details.self.region": function () {
            if (this.app.financier.details.self.region)
                this.displayGraphRepartitionRegion();
        },
        "app.financier.details.self.groupement": function () {
            if (this.app.financier.details.self.groupement)
                this.displayGraphRepartitionGroupement();
        },
    },
    computed: {
        // CA par Région trié
        regionDetailsSorted: function () {
            if (!this.app.financier.details.self.region) return []
            return Object.values(this.app.financier.details.self.region).slice().sort((a, b) => b.total - a.total)
        },
        // CA par Groupemetn trié
        grpDetailsSorted: function () {
            if (!this.app.financier.details.self.groupement) return []
            return Object.values(this.app.financier.details.self.groupement).slice().sort((a, b) => b.total - a.total)
        },
    },
    methods: {
        init() {
            if (store.financier.details.self.groupement && store.financier.details.self.region) {
                this.displayGraphRepartitionGroupement();
                this.displayGraphRepartitionRegion();
            }
        },
        //
        getCaTotalByRegion(regionId) {
            if (!(this.app.financier.details.loaded) || !this.app.financier.details.self.groupement)
                return 0;
            let res = 0;
            for (const groupId in this.app.financier.details.self.groupement) {
                res += this.app.financier.details.self.groupement[groupId][regionId] || 0
            }
            return res;
        },
        // Repartition par région
        displayGraphRepartitionRegion() {
            if (this.charts.repartitionRegion) this.charts.repartitionRegion.destroy();

            var labels = [];
            var datas = [];
            var backgroundColors = [];

            Object.values(store.financier.details.self.region).sort((a, b) => b.total - a.total).forEach(r => {
                labels.push(r.name);
                datas.push(Math.round(r.total));
                backgroundColors.push(r.backgroundColor);
            });

            const canvas = document.getElementById("chartRepartitionRegion");
            this.charts.repartitionRegion = new Chart(canvas, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: "Répartition par région",
                            data: datas,
                            backgroundColor: backgroundColors
                        },
                    ],
                    styling: {
                        borderWidth: 0
                    }
                },
                options: {
                    indexAxis: "y",
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    return Number(context.raw).toLocaleString("fr-FR", { minimumFractionDigits: 0 }) + " €";
                                }
                            }
                        }
                    }
                }
            });
        },

        // Repartition par groupement
        displayGraphRepartitionGroupement() {
            if (this.charts.repartitionGroupement) this.charts.repartitionGroupement.destroy();

            var labels = [];
            var datas = [];
            var backgroundColors = [];

            Object.values(store.financier.details.self.groupement).sort((a, b) => b.total - a.total).forEach(r => {
                labels.push(r.name);
                datas.push(Math.round(r.total));
                backgroundColors.push(r.backgroundColor);
            });

            const canvas = document.getElementById("chartRepartitionGroupement");
            this.charts.repartitionGroupement = new Chart(canvas, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: "Répartition par groupement",
                            data: datas,
                            backgroundColor: backgroundColors
                        },
                    ],
                    styling: {
                        borderWidth: 0
                    }
                },
                options: {
                    indexAxis: "y",
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: true,
                            callbacks: {
                                label: function (context) {
                                    return Number(context.raw).toLocaleString("fr-FR", { minimumFractionDigits: 0 }) + " €";
                                }
                            }
                        }
                    }
                }
            });
        },
        formatNumberEur(number) {
            return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(number);
        },
    },
    filters: {
    },

}
</script>