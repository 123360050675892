<template>
  <div class="home-container">
    <!--<Popup></Popup>
        <FournisseurPopup></FournisseurPopup>
        <PopupInformation></PopupInformation>-->
    <div
      class="home-content"
      :class="{ 'no-selector': $route.path.startsWith('/external') }"
    >
      <MasterSelector
        v-if="!$route.path.startsWith('/external')"
      ></MasterSelector>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
//import Popup from '../../components/Popup';
//import FournisseurPopup from '../../components/FournisseurPopup';
//import PopupInformation from '../../components/PopupInformation';
import MasterSelector from "../../components/MasterSelector";
//import Sidebar from '../../components/Sidebar';
//import Maps from '../components/Maps';
//import Fullscreen from "../components/Fullscreen/Fullscreen";
import { fetchApi } from "@/utils";
import { store, mutations } from "../../components/store/store";
import { dataFinanceMutations } from "../../components/store/dataFinance";
import { gammeMutations } from "../../components/store/gamme";
import { tranchesCaMutations } from "../../components/store/tranchesCa";
import { pointDeVenteMutations } from "../../components/store/pointDeVente";
import { init as initMapDatas } from "../../components/store/map";

export default {
  name: "Home",
  components: {
    //Popup,
    //FournisseurPopup,
    //PopupInformation,
    MasterSelector,
    //Sidebar
  },
  data() {
    return {
      app: store,
    };
  },
  mounted() {
    mutations.initVisibilityFiltersParameters();
    if (store.self.isAdmin) mutations.initFournisseurs();
    if (store.self.isOdw) {
      this.initFamilies(); //Initialise les familles
      tranchesCaMutations.initTranchesCa();
    }

    // Initialise les données pour la carte
    initMapDatas().then(() => {
      store.dataMap.dataLoaded = true;
      if (store.self.isOdw) {
        mutations.initPeriodes().then(() => {
          gammeMutations.initGammes().then(() => {
            dataFinanceMutations.loadDataFinance();
          });
        });
      } else {
        pointDeVenteMutations.loadPdv();
      }
    });
  },
  watch: {
    "app.calculatorFilters": {
      handler() {
        //console.log("hello, refresh !!!")
        //TODO : refresh datas
      },
      deep: true,
    },
  },
  methods: {
    initFamilies() {
      try {
        fetchApi("fournisseur/families", {}, "maps").then((response) => {
          store.families = response.data;
        });
      } catch (e) {
        console.log("error with: ", e);
        console.log("error with: ", e.response);
      }
    },
  },
};
</script>

<style>
.home-container {
  height: 100%;
  width: 100%;
  background-color: #f2f3f4;
  overflow-y: hidden;
}

.home-content {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 350px 1fr;
  column-gap: 24px;
}

.home-content.no-selector {
    grid-template-columns: 1fr;
}
</style>
