<template>
    <div id="family-selection" v-if="app.self.isOdw && app.calculatorFilters.isSpecialiste === 1">
        <h3>Spécialité point de vente</h3>
        <div>
            <multiselect v-model="selectedFams"
                         :options="app.families"
                         trackBy="Id"
                         :custom-label="f => f.Label"
                         :closeOnSelect="false"
                         :searchable="true"
                         :multiple="true"
                         placeholder="Choisissez une/des spécialité(s)"
                         selectLabel="Appuyez sur Entrée pour sélectionner"
                         selectedLabel="Sélectionné"
                         deselectLabel="Appuyez sur Entrée pour enlever"
                         @open="selectOpen = true"
                         @close="selectOpen = false; familiesChanged();"
                         @input="familiesChanged"></multiselect>
        </div>
    </div>
</template>

<script>
    import { store } from "../store/store";
    import { dataFinanceMutations } from "../store/dataFinance";

    export default {
        name: "FamilyFilter",
        data() {
            return {
                app: store,
                selectOpen: false,
                selectedFams: [store.calculatorFilters.families]
            }
        },
        mounted() {
            this.selectedFams = this.app.calculatorFilters.families.map(f => this.getFamily(f));
        },
        watch: {
            "app.calculatorFilters.families": {
                handler() {
                    this.selectedFams = this.app.calculatorFilters.families.map(f => this.getFamily(f));
                }
            }
        },
        methods: {
            getFamily(id) {
                return this.app.families.find(f => f.Id == id);
            },
            compareFams() {
                let equal = true;
                if (this.app.calculatorFilters.families.length !== this.selectedFams.length) return false;
                for (let i = 0; i < this.selectedFams.length; i++) {
                    if (this.app.calculatorFilters.families.indexOf(this.selectedFams[i].Id) === -1) equal = false;
                }
                return equal;
            },
            familiesChanged() {
                if (this.compareFams() || this.selectOpen) return;
                this.app.calculatorFilters.families = this.selectedFams.map(f => f.Id);

                dataFinanceMutations.loadDataFinance();
            },
        }
    }
</script>