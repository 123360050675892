<template>
    <div id="selectors">
        <!-- Filtres -->
        <div class="white-box filters-container" data-v-step="3">
            <section v-if="!app.self.isOdw && app.self.GPS[0] && app.self.GPS[1]" class="rep-section">
                <div class="header">
                    <h2>
                        Points de collecte REP
                    </h2>
                </div>
                <AdhREPFilter></AdhREPFilter>
                <div class="header">
                    <h2>
                        Cartographie Adhérent
                    </h2>
                </div>
            </section>

            <div v-else class="header">
                <h2>
                    Filtres
                </h2>
            </div>

            <div class="selector-filters-body" data-v-step="5">
                <div v-if="pdvsDisplayActive.length > 0">
                    <h3>Ma sélection de recherche</h3>
                    <div class="selector-selection-body">
                        <span class="badge badge-danger" v-on:click="removeFromSearch('0')">
                            TOUT SUPPRIMER &times;
                        </span>
                        <span v-for="pdv in pdvsDisplayActive" :key="pdv.Id" class="badge"
                            v-on:click="removeFromSearch(pdv.Id)">
                            {{ pdv.Nom }} &times;
                        </span>
                    </div>
                </div>

                <!-- Période -->
                <PeriodFilter></PeriodFilter>

                <GroupementFilter></GroupementFilter>
                <GammeFilter></GammeFilter>
                <AdvsFilter></AdvsFilter>
                <TrancheCaFilter></TrancheCaFilter>
                <ClientFilter></ClientFilter>
                <ClassificationFilter></ClassificationFilter>
                <FamilyFilter></FamilyFilter>
                <LnlFilter></LnlFilter>
                <PdvDateFilter></PdvDateFilter>
                <PdvDistanceFilter></PdvDistanceFilter>
                <span>Trouver un point de vente de CMEM</span>
            </div>
        </div>



    </div>
</template>

<script>
//import Vue from 'vue';
import { store, mutations } from "./store/store";
import { pointDeVenteMutations } from "./store/pointDeVente";

import GroupementFilter from "./Filters/GroupementFilter";
import GammeFilter from "./Filters/GammeFilter";
import AdvsFilter from "./Filters/AdvsFilter";
import PdvDateFilter from "./Filters/PdvDateFilter";
import PdvDistanceFilter from "./Filters/PdvDistanceFilter";
import AdhREPFilter from "./Filters/AdhREPFilter";
//import IndicatorFilter from "./Filters/IndicatorFilter";
import TrancheCaFilter from "./Filters/TrancheCaFilter";
import ClientFilter from "./Filters/ClientFilter";
import ClassificationFilter from "./Filters/ClassificationFilter";
import FamilyFilter from "./Filters/FamilyFilter";
import LnlFilter from "./Filters/LnlFilter";
import PeriodFilter from "./Filters/PeriodFilter";
//import AdminLogs from "./AdminLogs";

export default {
    name: 'MasterSelector',
    components: {
        GroupementFilter,
        GammeFilter,
        AdvsFilter,
        PdvDateFilter,
        PdvDistanceFilter,
        AdhREPFilter,
        //IndicatorFilter,
        TrancheCaFilter,
        ClientFilter,
        ClassificationFilter,
        FamilyFilter,
        LnlFilter,
        PeriodFilter,
        //AdminLogs,
    },
    data() {
        return {
            popupAcomptes: false,
            app: store,
            mutations,
            steps: [
                {
                    target: '[data-v-step="1"]',
                    header: {
                        title: 'Fil d\'Ariane',
                    },
                    content: `Retrouvez ici la sélection géographiqe effectuée`
                },
                {
                    target: '[data-v-step="2"]',
                    content: 'An awesome plugin made with Vue.js!'
                }
            ]
        }
    },
    methods: {
        // Gestion générique de l'affichage des filtres
        switchFilters(data) {
            mutations.switchFilters(data);
        },

        // Affiche les informations relatives aux "i" dans l'interface
        displayInfo(info) {
            mutations.displayInfo(info);
        },

        // Bascule carto/observatoire
        adminSwitchChanged(event) {
            mutations.adminSwitchChanged(event.target.value);
            this.initCentrales();
        },

        // Clic sur un élément de "Sélection de recherche"
        removeFromSearch: pointDeVenteMutations.removeFromSearch,

        // Substitution
        substitute(guid) {
            mutations.substitute(guid);
        },

        // Aide
        help() {
            mutations.help();
        },

    },
    computed: {
        pdvsDisplayActive() {
            return store.dataAdherents.pdvs.filter(_pdv => store.calculatorFilters.pdvs.indexOf(_pdv.Id) !== -1);
        }
    },
}
</script>

<style scoped>
#selectors {
    min-height: 100%;
    max-height: 100%;
    overflow-y: hidden;
}

.filters-container {
    max-height: 100%;
    overflow-y: auto;
}
</style>

<style>
.selector-filters-body {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding-bottom: 300px;
}

.selector-filters-body h3 {
    text-transform: uppercase;
    font-size: 1rem;
}

.rep-section>.header:nth-child(3) {
    margin-top: 32px;
}
</style>
