import { createApp } from "vue";
import "./plugins/fontawesome";
import App from "./App";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import axios from "axios";
import VueAxios from "vue-axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

// import VueTour from "vue-tour";
import JsonExcel from "vue-json-excel";
// import VueRouter from "vue-router";
// import { store } from "./components/store/store";

import Modal from "./components/Modal";

import "bootstrap/dist/css/bootstrap.min.css";
import "leaflet/dist/leaflet.css";
import "./assets/css/style.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

// require("vue-tour/dist/vue-tour.css");

import router from "./router.js";

// import {
//   Chart,
//   LineController,
//   PointElement,
//   ArcElement,
//   DoughnutController,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   BarController,
// } from "chart.js";

// Chart.register(
//   LineController,
//   PointElement,
//   ArcElement,
//   DoughnutController,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   BarController,
// );

import Chart from "chart.js/auto";

Chart.defaults.font.size = 10;
Chart.defaults.interaction.mode = "point";


export const VueApp = createApp(App);

VueApp.component("font-awesome-icon", FontAwesomeIcon);
VueApp.component("downloadExcel", JsonExcel);
VueApp.component("multiselect", Multiselect);
VueApp.component("Modal", Modal);

// Vue.config.productionTip = false;
VueApp.use(VueAxios, axios);
// VueApp.use(VueTour);
VueApp.use(router);
VueApp.$router = router;

VueApp.mount("#app");

VueApp.component("l-map", LMap);
VueApp.component("l-tile-layer", LTileLayer);
VueApp.component("l-marker", LMarker);
