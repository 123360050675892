<template>
    <div id="tranches-ca" v-if="app.self.isOdw && app.calculatorFilters.isClient == 1">
        <h3>
            CA réalisé par point de vente
        </h3>
        <div id="tranches-ca-choice">
            <div v-for="trancheCa in app.dataAdherents.tranchesCa" :key="trancheCa.Id" class="familles-choice-textbox">
                <label>
                    <input type="checkbox"
                           v-model="app.calculatorFilters.tranchesCa"
                           :value="trancheCa.Id"
                           :disabled="!app.self.isAdmin && app.calculatorFilters.isClient != 1"
                           class="checkbox-cmem"
                           @change="tranchesCaChanged()" />
                    {{ trancheCa.Libelle }}
                </label>
            </div>
        </div>
    </div>
</template>
<script>
    import { store } from "../store/store"
    import { dataFinanceMutations } from "../store/dataFinance";

    export default {
        name: "TrancheCaFilter",
        data() {
            return {
                app: store,
            }
        },
        methods: {
            tranchesCaChanged() {
                dataFinanceMutations.loadDataFinance();
            }
        }
    }
</script>

<style></style>