<template>
    <div class="loader" v-if="app.dataDisplay.loader > 0">
        <div>
            <img src="@/assets/images/loader.gif">
        </div>
    </div>
</template>

<script>
    import { store } from "./store/store";

    export default {
        name: 'Loader',
        data() {
            return {
                app: store
            }
        },
    }
</script>


<style>
.loader {
    position: fixed;
    z-index: 999999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(155, 155, 155, 0.8);
    display: grid;
    place-items: center;
}

    .loader > div {
        background: #FFFFFF;
        width: min-content;
        height: min-content;
    }

        .loader > div > img {
            display: block;
            margin: auto;
        }

</style>
