<template>
    <div id="ligneetlumiere" v-if="((!app.self.isOdw && app.self.isAdmin) || app.self.isLnL || app.self.isLS)">
        <h3>Univers</h3>
        <div id="classification-choice">
            <div class="clients-choice-textbox">
                <label>
                    <input type="checkbox"
                           :checked="app.calculatorFilters.lnlAll"
                           class="checkbox-cmem"
                           @click="lnlChanged(-1, $event)" />
                    Tous les points de vente
                </label>
            </div>
            <div class="clients-choice-textbox" v-if="(!app.self.isOdw && app.self.isAdmin) || app.self.isLnL">
                <label>
                    <input type="checkbox"
                           :checked="app.calculatorFilters.isLnl === 1"
                           class="checkbox-cmem"
                           @change="lnlChanged(0)" />
                    Ligne et Lumière
                </label>
            </div>
            <div class="clients-choice-textbox" v-if="(!app.self.isOdw && app.self.isAdmin) || app.self.isLnL">
                <label>
                    <input type="checkbox"
                           :checked="app.calculatorFilters.isByLnl === 1"
                           class="checkbox-cmem"
                           @change="lnlChanged(1)" />
                    By Ligne et Lumière
                </label>
            </div>
            <div class="clients-choice-textbox" v-if="(!app.self.isOdw && app.self.isAdmin) || app.self.isLS">
                <label>
                    <input type="checkbox"
                           v-model="app.calculatorFilters.libreService"
                           class="checkbox-cmem"
                           @change="libreServiceChanged()" />
                    Libre Service
                </label>
            </div>

        </div>
    </div>
</template>

<script>
    import { store } from "../store/store";
    import { dataFinanceMutations } from "../store/dataFinance";
    import { pointDeVenteMutations } from "../store/pointDeVente";

    export default {
        name: "LnlFilter",
        props: {
            open: Boolean
        },
        data() {
            return {
                app: store
            };
        },
        methods: {
            lnlChanged(newVal, e = null) { // Appelé avant tout changement
                if (newVal === -1) { // Coche "tous"
                    if (!store.calculatorFilters.lnlAll) { // Pas coché => on coche, et on décoche tous les autres
                        store.calculatorFilters.lnlAll = true
                        store.calculatorFilters.isByLnl = -1;
                        store.calculatorFilters.isLnl = -1;
                        store.calculatorFilters.libreService = false
                    }
                    else if (e && store.calculatorFilters.lnlAll) { // Déjà coché, et rien d'autre de coché => on ne fait rien
                        e.preventDefault()
                        return;
                    }
                }
                if (newVal === 0) { // Coche Lnl
                    store.calculatorFilters.lnlAll = false;
                    if (store.calculatorFilters.isLnl === 1) { // On désactive Lnl => On désactive ByLnl
                        store.calculatorFilters.isLnl = -1;
                        store.calculatorFilters.isByLnl = -1;
                        if (!store.calculatorFilters.libreService) // Si tout est décoché
                            store.calculatorFilters.lnlAll = true;
                    } else {
                        store.calculatorFilters.isLnl = 1;
                    }
                }
                else if (newVal === 1) { // Coche ByLnl
                    store.calculatorFilters.lnlAll = false;
                    if (store.calculatorFilters.isByLnl === -1) { // On active ByLnl => On active Lnl
                        store.calculatorFilters.isByLnl = 1;
                        store.calculatorFilters.isLnl = 1;
                    } else {
                        store.calculatorFilters.isByLnl = -1;
                        if (store.calculatorFilters.isLnl === -1 && !store.calculatorFilters.libreService) // Si tout est décoché
                            store.calculatorFilters.lnlAll = true;
                    }
                }
                if (this.app.self.isOdw)
                    dataFinanceMutations.loadDataFinance();
                else
                    pointDeVenteMutations.loadPdv();
            },
            libreServiceChanged() { // Appelé après changement
                if (store.calculatorFilters.libreService)
                    store.calculatorFilters.lnlAll = false;
                if (!store.calculatorFilters.libreService && store.calculatorFilters.isByLnl === -1 && store.calculatorFilters.isLnl === -1)
                    store.calculatorFilters.lnlAll = true
                if (this.app.self.isOdw)
                    dataFinanceMutations.loadDataFinance();
                else
                    pointDeVenteMutations.loadPdv();
            },
        }
    }
</script>