<template>
    <nav class="maps-nav">
        <a v-if="$route.path.startsWith('/chefdemarche')" class="navbar-brand" href="#" data-v-step="7" @click="resetMap('app', 0)">
            <img src="@/assets/images/logo.png" alt="CMEM">
        </a>
        <div class="actions-container" v-if="!$route.path.startsWith('/chefdemarche')">
            <router-link   :to="{ path: '/chefdemarche', query: $route.query }" class="icon" v-if="app.self.isAdmin">
                <font-awesome-icon icon="user-tie" />
            </router-link>
            <a class="icon px-0" :href="toggleCartoUrl" v-if="app.self.isAdmin || app.self.isSupplier">
                <font-awesome-icon icon="toggle-off" />
            </a>
            <form class="form-inline" v-if="!app.self.isOdw" data-v-step="1">
                <Autocomplete :items="app.dataAdherents.pdvs"></Autocomplete>
            </form>
            <label v-if="app.self.isAdmin" class="demo-checkbox">
                Démo 
                <input type="checkbox" class="checkbox-cmem" v-model="app.isDemo" @change="updateDatas" />
            </label>
        </div>
        <div class="fournisseur-infos">
            <img id="logoUser" src="@/assets/images/logo-user.png" class="mr-2">
            <span id="userInfos">{{ app.isDemo ? "Fournisseur" : app.self.nom }} - {{ app.self.firstname }} {{ app.self.name }}</span>
            <font-awesome-icon v-if="app.self.isAdmin" class="mx-2" icon="info-circle" style="font-size: 1em; vertical-align: top; color: white;" @click="app.dataDisplay.popupInfoContractFrs = true" />
            <span id="charge" v-if="app.self.isAdmin" @click="displayPopupSelectionFournisseur()"><font-awesome-icon icon="sync-alt" class="float-right" /></span>
        </div>
        <Modal :open="app.dataDisplay.popupInfoContractFrs" @close="app.dataDisplay.popupInfoContractFrs = false" title="Informations fournisseur">
            <dl>
                <dt>Statut contrat campagne {{app.self.contractPeriodLabel}}</dt>
                <dd>{{app.self.contractStatus}}</dd>
                <dt>ODW3 Souscrit</dt>
                <dd>{{app.self.accessOdw ? "Oui" : "Non"}}</dd>
                <dt>Accès validé sur la période sélectionnée</dt>
                <dd>{{app.self.periodAccess ? "Oui" : "Non"}}</dd>
            </dl>
        </Modal>
    </nav>
</template>

<script>

    import { store, mutations } from "./store/store";
    import { dataFinanceMutations } from "./store/dataFinance";
    import { advMutations } from "./store/adv";
    import Autocomplete from './Autocomplete';
    import Modal from "@/components/Modal";

    export default {
        name: 'HeaderMaster',
        components: { Autocomplete, Modal },
        data() {
            return {
                app: store,
            }
        },
        computed: {
            toggleCartoUrl() {
                const email = new URL(window.location.href).searchParams.get("email");
                const login = new URL(window.location.href).searchParams.get("login");
                const autologinHash = new URL(window.location.href).searchParams.get("autologinHash");
                const odw = new URL(window.location.href).searchParams.get("odw");
                return `${window.location.origin}/?autologinHash=${autologinHash}&login=${login}&email=${email}&odw=${odw == 1 ? 0 : 1}`;
            }
        },
        methods: {
            updateDatas() {
                advMutations.initAdvs().then(() => {
                    dataFinanceMutations.loadDataFinance();
                })
            },
            displayPopupSelectionFournisseur() {
                store.calculatorFilters.isLnl = -1;
                store.calculatorFilters.isByLnl = -1;
                mutations.switchFilters("messagePopupFournisseur");
            },

            // Centrage de la carte au clic sur un élément du fil d'ariane
            resetMap(scope, bounds) {
                mutations.resetMap(scope, bounds);
            },
        }
    }
</script>

<style>
    .maps-nav .actions-container form input {
        min-width: 550px;
    }
</style>

<style scoped>
    .maps-nav {
        position: relative;
        height: 40px;
        padding: 6px 12px;
        display: flex;
        justify-content: space-between;
        border-radius: 6px;
        box-shadow: none;
        background-color: #FFF;
    }

    .actions-container {
        height: 100%;
        display: flex;
        column-gap: 12px;
        flex-grow: 1;
        align-items: center;
    }

    .icon {
        cursor: pointer;
        background-color: var(--primary);
        border-radius: 50%;
        height: 100%;
        aspect-ratio: 1/1;
        display: grid;
        place-items: center;
    }

        .icon svg {
            font-size: 1.2rem;
            color: white;
        }

        .demo-checkbox {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            column-gap: 0.25rem;
        }
</style>
