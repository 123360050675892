import L from 'leaflet';
import 'leaflet.markercluster'
import 'leaflet/dist/leaflet.css';
import { store } from "./store";
import { fetchApi } from "@/utils";
import { selectedDeps } from "./map";

export const pointDeVenteMutations = {
    loadPdv() {
        // on supprime tous les pdvs qui s'affichent sur la carte
 
        store.dataAdherents.pdvs.forEach(p => {
            if (p.LeafletObject.removeFrom)
                p.LeafletObject.removeFrom(store.dataMap.map);
        });

        fetchApi("pdvs?geos=" + JSON.stringify(selectedDeps())
            + "&adhs=" + JSON.stringify(store.calculatorFilters.adhs)
            + "&idPdvs=" + JSON.stringify(store.calculatorFilters.pdvs)
            + "&isLnl=" + (store.calculatorFilters.lnlAll ? -1 : store.calculatorFilters.isLnl)
            + "&isLS=" + (store.calculatorFilters.lnlAll ? -1 : (store.calculatorFilters.libreService ? 1 : -1))
            + "&isByLnl=" + (store.calculatorFilters.lnlAll ? -1 : store.calculatorFilters.isByLnl)
            + "&isClient=" + (store.self.isSupplier ? store.calculatorFilters.isClient : -1)
            + "&distance=" + (store.calculatorFilters.pdvDistance.latlng ? store.calculatorFilters.pdvDistance.distance * 1000 : -1)
            + "&lat=" + (store.calculatorFilters.pdvDistance.latlng ? store.calculatorFilters.pdvDistance.latlng.lat : -1)
            + "&lng=" + (store.calculatorFilters.pdvDistance.latlng ? store.calculatorFilters.pdvDistance.latlng.lng : -1)
            + "&guid=" + store.self.guid, {}, "sdc")
            .then((response) => {
                store.dataAdherents.pdvs = this.populatePointsDeVente(response.data);
                store.dataAdherents.pdvsCount = response.data.length;
            })
    },

    // Met au bon format les PDV
    populatePointsDeVente(pdvs) {

        var returnArray = [];

        pdvs.forEach((pdv) => {
            var groupement = store.dataAdherents.centrales.find(e => e.Guid == pdv.GroupementId);
            let groupementParam = undefined;
            if (groupement)
                groupementParam = store.adherentsParam.find(e => e.GroupementGuid == groupement.Guid);
            //else
            //    console.error("Groupement inconnu: ", pdv);

            if (!groupementParam) {
                groupementParam = {
                    Logo: "logo/cmem.png",
                    Wrapper: "#212121"
                }
            }

            if (groupement && groupementParam) {
                // Afficher l'adresse proprement
                var adress = ""
                if (pdv.AdressLine1 != null) {
                    adress = pdv.AdressLine1 + " ";
                }
                if (pdv.AdressLine2 != null) {
                    adress = adress + pdv.AdressLine2;
                }

                try {
                    var _pdv = {
                        "Id": pdv.IdPdv,
                        "Code8": pdv.IdCompta,
                        "IdGroupement": pdv.GroupementId,
                        "Nom": store.isDemo ? "Bois mtx" : pdv.Name,
                        "NomGroupement": groupement.Name,
                        "Gps": JSON.parse(pdv.GpsCoordinates),
                        "Type": /*pdv.Type*/ "SIEGE OU DEPOT",
                        "Siret": pdv.Siret,
                        "Adresse": adress,
                        "CodePostal": pdv.ZipCode.toString().length == 4 ? "0" + pdv.ZipCode.toString() : pdv.ZipCode.toString(),
                        //"Departement": pdv.ZipCode.substring(0, 2),
                        "Departement": pdv.Departement == undefined ? pdv.IdDepartement : pdv.Departement,
                        "Ville": pdv.City,
                        "Ca": "0",
                        "LeafletObject": {},
                        "EstClient": pdv.IsClient,
                        "EstSpecialiste": pdv.IsSpecialiste,
                        "Advs": [],
                        "Logo": store.rootApi + "logo/" + groupementParam.Logo,
                        "WrapperColor": groupementParam.Wrapper,
                        "DateEntree": pdv.DateEntree.substring(0, 10),
                        "DateSortie": pdv.DateSortie.substring(0, 10),
                        "DateEntreeMme": pdv.DateEntreeMme.substring(0, 10),
                        "DateSortieMme": pdv.DateSortieMme.substring(0, 10),
                        "Email": store.isDemo ? "abcdefg@hij.fr" : pdv.Email,
                        "PhoneNumber": store.isDemo ? "06 00 00 00 00" : pdv.PhoneNumber,
                        "FaxNumber": store.isDemo ? "06 00 00 00 00" : pdv.FaxNumber,
                        "Portable": store.isDemo ? "06 00 00 00 00" : pdv.PortablePhoneNumber,
                        DetailSpe: pdv.DetailSpe,
                        IsLnl: pdv.IsLnl,
                        IsLS: pdv.IsLS,
                        IsByLnl: pdv.IsByLnl,
                        RespFirstName: store.isDemo ? "Jean" : pdv.RespFirstName,
                        RespLastName: store.isDemo ? "Dupont" : pdv.RespLastName,
                        RespCivility: pdv.RespCivility
                    };
                } catch (e) {
                    console.error("Error on pdv: ", pdv);
                }

                //// Ajout des ADVS
                store.self.commerciaux.forEach(commercial => {
                    var depFound = commercial.Departements.indexOf(_pdv.Departement);
                    if (depFound != -1) {
                        _pdv.Advs.push(commercial);
                    }
                });
                var icon = this.getStyleIcon(pdv.GroupementId);
                if (icon)
                    _pdv.LeafletObject = L.marker(JSON.parse(pdv.GpsCoordinates), { icon: icon }).bindPopup(this.customPopup(pdv.GroupementId, _pdv));

                returnArray.push(_pdv);
            }
        });

        return returnArray;
    },

    
    
    // Customise la popup à afficher pour un PDV selon son groupement
    customPopup(groupementGuid, pdv) {
        //if (pdv.RespFirstName) console.log("representant : ", pdv)
        var advToDisplay = "";
        if (pdv.Advs.length > 0) {
            pdv.Advs.forEach(adv => {
                advToDisplay = advToDisplay + adv.Civility + " " + adv.LastName + " " + adv.FirstName + "<br>";
            });

        }
        else {
            advToDisplay = "Il n'y a pas de commercial affecté à ce département";
        }
        var customPopup =
            "<div id='" + pdv.Id + "' class='wrapper-popup' style='background-color:" + this.setPopupColor(groupementGuid) + "''>" +
            "<img src='" + this.setPopupLogo(groupementGuid) + "' alt='' width='150px'/>" +
            "</div>" +
            "<div class='container-popup'>" +
            "<div class='infos-pdv'>" +
            "<h5>" + pdv.Nom + "</h5>" +
            "<p>" + pdv.Adresse + "</p>" +
            "<p>" + pdv.CodePostal + " - " + pdv.Ville + "</p><br>" +
            "<p><b>Adhésion</b> : " + pdv.DateEntree + "</p>" +
            ((new Date(pdv.DateSortie)).getFullYear() !== 1900 ? "<p><b>Sortie</b> : " + pdv.DateSortie + "</p>" : "") +
            (store.self.isOdw == 1 ? "<p><b>" + (pdv.EstSpecialiste ? ("Spécialiste (" + pdv.DetailSpe.trim() + ")") : "Généraliste") + "</b></p>" : "") +
            (store.self.idOdw == 1 || store.self.isSupplier == 1 ? "<p><b>" + (pdv.EstClient ? "Client" : "Non client") + "</b></p>" : "") +
            "</div>" +
            "<div class='infos-contact'>" +
            "<h5>Contact</h5>" +
            "<!--<p>#Nom du contact#</p>-->" +
            "<p>" + pdv.PhoneNumber + "</p>" +
            "<p>" + pdv.Email + "</p>" +
            "</div>" +
            "<div class='infos-adv'>" +
            "<h6>Commercial / Commerciaux attaché(s)</h6>" +
            advToDisplay +
            "</div>" +
            (pdv.RespFirstName ? "<h6>Responsable</h6><p>" + pdv.RespLastName + " " + pdv.RespFirstName + "</p>" : "") +
            "<div class='info-lnl'>" +
            (pdv.IsLnl ? ("<img src='logo-lnl.png' />") : "") +
            (pdv.IsLS ? ("<img src='logo-ls.png' />") : "") +
            (pdv.IsByLnl ? "<span>ByL&L</span>" : "") +
            "</div>" +
            "</div>";
        return customPopup;
    },

    // Récupère la couleur de la popup par GUID groupement
    setPopupColor(groupementGuid) {
        var g = store.adherentsParam.find(e => e.GroupementGuid == groupementGuid);
        var colorWrapper = '#D32F2F';

        if (g != undefined) {
            colorWrapper = g.Wrapper;
        }

        return colorWrapper;
    },

    // Récupère la logo de la popup par GUID groupement
    setPopupLogo(groupementGuid) {
        var g = store.adherentsParam.find(e => e.GroupementGuid == groupementGuid);
        var logo = 'logo-company.jpg';

        if (g != undefined) {
            logo = g.Logo;
        }

        return store.rootApi + 'logo/' + logo;
    },

    // Récupère les informations d'affichage pour un adhérent
    getStyleIcon(groupementGuid) {
        var g = store.adherentsParam.find(e => e.GroupementGuid == groupementGuid);
        let myIcon = undefined;
        if (g)
            myIcon = L.icon({
                iconUrl: store.rootApi + 'markers/' + g.Marker,
                iconSize: [40, 40],
                iconAnchor: [20, 40],
                popupAnchor: [0, -20],
                shadowUrl: store.rootApi + 'markers/marker-shadow.png',
                shadowSize: [40, 32],
                //shadowAnchor: [22, 94]
            });
        return myIcon;
    },

    // action au clic sur un élément de "Ma sélection de recherche"
    removeFromSearch(id) {
        if (id == "0")
            store.calculatorFilters.pdvs = [];
        else {
            const indexCalculatorPdvs = store.calculatorFilters.pdvs.indexOf(id);
            if (indexCalculatorPdvs != -1)
                store.calculatorFilters.pdvs.splice(indexCalculatorPdvs, 1);
        }
    }
};