import { reactive } from "vue";
import { dataFinanceMutations } from "./dataFinance";
import { pointDeVenteMutations } from "./pointDeVente";
import { advMutations } from "./adv";
import { render as renderMap } from "./map";
import { gammeMutations } from "./gamme";
import { fetchApi } from "@/utils";

// Variables partagées entre les différents composants
const odw = new URL(window.location.href).searchParams.get("odw");
export const store = reactive({
  // Route de l'API
  rootApi: process.env.VUE_APP_ROOT_API,

  // Indique si l'application est en affichage "démo" ou non
  isDemo: false,

  // Information sur l'utilisateur connecté
  self: {
    name: "",
    firstname: "",
    code: "",
    nom: "",
    guid: "",
    isSupplier: 0,
    isLnL: false,
    isLS: false,
    isMME: false,
    commerciaux: [],
    isAdmin: 0,
    accessOdw: false,
    accessDor: false,
    accessDonneesExternes: false,
    contractStatus: "Non défini",
    contractPeriodLabel: "",
    periodAccess: false,
    isOdw: odw && odw == 1 ? 1 : 0,
    fullScreen: false,
    token: "",
    family: "",
    GPS : ""
  },

  // Informations sur la carte
  dataMap: {
    token:
      "pk.eyJ1IjoianN0ZXBoYW4iLCJhIjoiY2syZGFpYWs5MHU0MjNvcDQ1d2NuNGc3YyJ9.JAJqPB8xPiww675oPn45EA",
    dataLoaded: false,
    map: null,
    tileMap: null,
    regions: [],
    departements: [],
    departementsDisplay: [],
    villes: [],
    layer: null,
    caSelection: null,
    markerCluster: [
      {
        idGroupement: null,
        MarkerClusterGroup: null,
        nomMarker: null,
      },
    ], //https://www.npmjs.com/package/leaflet.markercluster
  },

  // Informations d'affichage (oui/non)
  dataDisplay: {
    toggleFilters: false,
    loader: 0,
    groupements: true,
    gammes: true,
    commerciaux: true,
    tranchesCa: true,
    clients: true,
    theme: false,
    classification: true,
    families: false,
    gammeChecked: true,
    generaliste: false,
    specialiste: false,
    financier: true,
    projection: true,
    regionColoration: true,
    recherchePdv: false,
    admin: false,
    toggleDisplayMap: true,
    popupMessage: false,
    popupMessageFournisseur: false,
    logs: false,
    popupInformation: false,
    lnl: false,
    byLnl: false,
    libreService: false,
    popupInfoContractFrs: false,
  },

  // Informations sur les adhérents
  dataAdherents: {
    centrales: [],
    centralesCount: 0,
    centralesDisplay: [],
    pdvs: [],
    pdvsCount: 0,
    tranchesCa: [],
  },

  // Infos sur les fournisseurs (= admin seulement)
  dataFournisseurs: [],

  //
  families: [],

  // Fil d'ariane
  breadcrumb: {
    Pays: {
      Nom: "",
      Id: 0,
    },
    Region: {
      Nom: "",
      Id: 0,
      Bounds: null,
      DomTomDepId: "",
    },
    Departement: {
      Nom: "",
      Id: 0,
      Bounds: null,
    },
    Ville: {
      Nom: "",
      Id: 0,
      Bounds: null,
    },
  },

  // Elements financiers
  financier: {
    results: {},
    hierarchy: {},
    generatedDatas: {},
    projection: {
      defautPdm: 50,
      minPdm: 0,
      maxPdm: 0,
      caProjete: 0,
      caDifferece: 0,
      caConcurrents: 0,
      gain: 0,
    },
    pdvDatas: [],
    details: {
      loaded: false,
      full: undefined,
      region: undefined,
      self: {
        groupement: undefined,
        region: undefined,
        caTotal: undefined,
      },
    },
    evolutionCa: {
      loaded: false,
      data: [],
    },
    acomptes: [],
    pdvsGammes: [],
  },
  dorDatas: {
    advsCa: [],
    indicateurs: {},
    init: false,
  },

  // messages d'information
  infos: {
    messageInfo: "",
  },

  // Gammes de produits concernées
  gammes: [],

  // Liste des chiffres d'affaires
  cas: [],

  //Periodes disponibles pour le fournisseur
  perioFourni: {
    annees: [],
    periodes: [],
  },

  // Filtres sur le calculateur
  calculatorFilters: {
    advs: [],
    adhs: [],
    pdvs: [],
    gammes: [],
    families: [],
    libreService: false,
    tranchesCa: [],
    isClient: odw && odw == 1 ? 1 : -1,
    lnlAll: true,
    isLnl: -1,
    isByLnl: -1,
    isSpecialiste: -1,
    specialistYes: false,
    specialistNo: false,
    indicateur: "o",
    idPeriode: -1,
    periodeName: "Déclaration annuelle",
    selectedYear: -1,
    isBeforeDate: 2,
    dateEntree: "1900-01-01",
    pdvDistance: {
      latlng: null,
      distance: odw == 1 ? 50 : 5, // Distance à partir du point en kilomètres
      selecting: false, // Sélection du point central
      point: null, // Leaflet Marker du point central
      circle: null, // Cercle de filtrage
    },
  },

  // Graphiques
  charts: {},

  // Paramètres régions/départements
  mapParams: {
    region: {
      color: "#000000",
      fillColor: "#000000",
    },
    departement: {
      color: "#000000",
      fillColor: "#000000",
    },
    departementAdvs: {
      color: "#000000",
      fillColor: "#000000",
    },
  },

  // Paramètres des adhérents
  adherentsParam: [],

  // Logs de l'appli
  logs: {
    sdc: "Pas de connexion",
    drone: "Pas de connexion",
    maps: "Pas de connexion",
    history: [],
  },

  // Informations des tutos
  tour: {
    firstVisit: localStorage.firstVisit == undefined ? true : false,
    steps: [
      {
        target: '[data-v-step="1"]',
        content:
          "Vous pouvez rechercher directement un point de vente ou un groupement. Cliquez dessus pour l'afficher sur la carte.",
        header: {
          title: "Recherche",
        },
        params: {
          placement: "top",
        },
      },
      {
        target: '[data-v-step="2"]',
        content:
          "La sélection géographique que vous ferez apparaitra dans cet encadré.",
        header: {
          title: "Fil d'Ariane",
        },
        params: {
          placement: "top",
        },
      },
      {
        target: '[data-v-step="3"]',
        content: "Les filtres vous servriont à affiner votre recherche.",
        header: {
          title: "Filtres",
        },
        params: {
          placement: "top",
        },
      },
      {
        target: '[data-v-step="4"]',
        content:
          "Cliquez sur l'icone pour ouvrir (le principe sera le même pour tous les filtres).",
        header: {
          title: "Filtres",
        },
        params: {
          placement: "top",
        },
      },
      {
        target: '[data-v-step="5"]',
        content: "Plusieurs choix s'offrent à vous.",
        header: {
          title: "Filtres",
        },
        params: {
          placement: "top",
        },
      },
      {
        target: '[data-v-step="6"]',
        content:
          "Vous pouvez cliquer sur la carte pour actualiser votre sélection.",
        header: {
          title: "Carte",
        },
        params: {
          placement: "bottom",
        },
      },
      {
        target: '[data-v-step="7"]',
        content:
          "En cliquant sur cette icone, vous remettrez à zéro tous vos filtres.",
        header: {
          title: "Filtres",
        },
        params: {
          placement: "top",
        },
      },
    ],
    options: {
      useKeyboardNavigation: false,
      labels: {
        buttonSkip: "Masquer",
        buttonPrevious: "Précédent",
        buttonNext: "Suivant",
        buttonStop: "Terminer",
      },
    },
  },

  excel_export: {
    filename: "Export_datas_admin",
    json_fields: {
      Fournisseur: "SupplierName",
      Gamme: "GammeName",
      Groupe: "GroupeName",
      SousGroupe: "SousGroupeName",
      Societe: "SocieteName",
      Pdv: "PdvName",
      Siret: "Siret",
      IdCompta: "IdCompta",
      CodeIntranet: "CodeThirdParty",
      DateEntree: "DateEntree",
      CodePostal: "ZipCode",
      Ville: "City",
      Pays: "Country",
      ChiffreAffaire: "Ca",
      Siege: "IsSiege",
      Specialiste: "IsSpecialiste",
    },
  },
});

// Actions sur les variables
export const mutations = {
  fullScreen(val) {
    store.self.fullScreen = val;

    if (val) {
      if (!store.financier.details.loaded)
        dataFinanceMutations.loadDataFinanceDetails();
      if (!store.dorDatas.init && (store.self.isAdmin || store.self.accessDor))
        dataFinanceMutations.loadDorDatas();
    } else if (!val) {
      // On quitte le fullscreen
      renderMap();
    }
  },

  // tri des tableaux selon le code département
  compare(a, b) {
    return a.IdNational < b.IdNational
      ? -1
      : a.IdNational > b.IdNational
      ? 1
      : 0;
  },

  // tri des tableaux selon le nom de la grande région
  compareName(a, b) {
    return a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0;
  },

  // supprimer des éléments du tableau
  arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  },

  // Dédoublonnage des tableaux
  getUnique(arr, comp) {
    const keys = arr.map((e) => e[comp]);
    const toGet = keys.map((e, i) => keys.indexOf(e) === i);
    return arr.filter((e, i) => toGet[i]);
  },

  // Reset la map sur un élément du fil d'ariane
  resetMap(type, bounds) {
    if (type === "app") {
      store.calculatorFilters.pdvs = [];
      store.calculatorFilters.tranchesCa = [];
      store.calculatorFilters.adhs = [];
      store.calculatorFilters.gammes = [];
      store.calculatorFilters.advs = [];
      store.calculatorFilters.families = [];
      store.calculatorFilters.isClient = odw && odw == 1 ? 1 : -1;
      store.calculatorFilters.lnlAll = true;
      store.calculatorFilters.isLnl = -1;
      store.calculatorFilters.isByLnl = -1;
      store.calculatorFilters.isLS = false;
      store.calculatorFilters.isSpecialiste = -1;
      store.calculatorFilters.specialistYes = false;
      store.calculatorFilters.specialistNo = false;
      store.calculatorFilters.indicateur = "o";
      store.calculatorFilters.dateEntree = "1900-01-01";
      store.calculatorFilters.isBeforeDate = 2;
      store.calculatorFilters.pdvDistance.latlng = null;
    }
    if (store.dataMap.map != null) {
      if (type == "cmem" || type === "app") {
        // On reset le fil d'ariane ainsi que les commerciaux
        store.breadcrumb.Region.Id = 0;
        store.breadcrumb.Pays.Id = 0;
        store.calculatorFilters.advs = [];

        store.dataMap.map.flyTo([46.7890461, 1.7448433], 6);

        if (store.self.isOdw == 1) {
          dataFinanceMutations.loadDataFinance();
        } else {
          pointDeVenteMutations.loadPdv();
        }
      } else if (type == "pays") {
        // On reset le fil d'ariane ainsi que les commerciaux
        store.breadcrumb.Region.Id = 0;
        store.calculatorFilters.advs = [];

        // Si France
        if (store.breadcrumb.Pays.Nom == "France") {
          store.dataMap.map.flyTo([46.7890461, 1.7448433], 6);
        }
        // Belgique
        else {
          store.dataMap.map.flyTo([50.495697, 3.3451989], 8);
        }

        if (store.self.isOdw == 1) {
          dataFinanceMutations.loadDataFinance();
        } else {
          pointDeVenteMutations.loadPdv();
        }
      } else {
        store.dataMap.map.fitBounds(bounds);
      }
    }
  },

  // Gestion des conditionnelles d'affichage
  switchFilters(data) {
    if (
      localStorage.firstVisit == undefined ||
      localStorage.firstVisit == false
    ) {
      localStorage.firstVisit = true;
    }

    switch (data) {
      case "filters":
        store.dataDisplay.toggleFilters = !store.dataDisplay.toggleFilters;
        break;
      case "messagePopup":
        if (store.dataDisplay.popupMessage == false) {
          store.dataDisplay.popupMessage = true;
        } else {
          store.dataDisplay.popupMessage = false;
        }
        break;
      case "messagePopupFournisseur":
        if (store.dataDisplay.popupMessageFournisseur == false) {
          store.dataDisplay.popupMessageFournisseur = true;
        } else {
          store.dataDisplay.popupMessageFournisseur = false;
        }
        break;
      default:
        store.dataDisplay[data] = !store.dataDisplay[data];
        break;
    }
  },

  // Récupère les fournisseurs en base
  initFournisseurs() {
    return new Promise((resolve, reject) => {
      fetchApi("fournisseur", {}, "sdc")
        .then((response) => {
          store.dataFournisseurs = response.data;
          resolve();
        })
        .catch(reject);
    });
  },

  // Substitute fournisseurs
  substitute(guid) {
    if (!store.self.isAdmin) return;
    var frs = store.dataFournisseurs.find((f) => f.Guid == guid);
    if (!frs) return;
    store.self.code = frs.IdCompta;
    store.self.guid = frs.Guid;
    store.self.nom = frs.Name;
    store.self.isSupplier = 1;
    store.self.isLS =
      frs.N1 === "Agencement - Peinture - Décoration" ||
      frs.N1 === "Outillage" ||
      frs.N1 === "Quincaillerie";
    advMutations.initAdvs();
    store.self.family = frs.N1;
    if (store.self.isOdw)
      mutations.initPeriodes(guid).then(() => {
        fetchApi(
          "fournisseur/authorizations?supplierGuid=" +
            store.self.guid +
            "&idPeriod=" +
            store.calculatorFilters.idPeriode,
          {},
          "sdc",
        ).then((response) => {
          store.self.accessOdw = response.data.ODW3Right;
          store.self.accessDor = response.data.DORRight;
          store.self.isLnL = response.data.IsLnl;
          if (store.calculatorFilters.idPeriode != -1) {
            store.self.contractStatus = response.data.ContractStatusText;
            store.self.contractPeriodLabel = response.data.ContractPeriodLabel;
            store.self.periodAccess = response.data.MapsPeriodAccess;
            store.dataDisplay.popupInfoContractFrs = true;
          }
        });

        gammeMutations.initGammes().then(() => {
          dataFinanceMutations.loadDataFinance();
        });
      });
    else pointDeVenteMutations.loadPdv();
  },

  // Bascule carto/observatoire
  adminSwitchChanged(event) {
    if (store.self.isAdmin) {
      if (event == "odw") {
        store.self.isOdw = 1;
        store.calculatorFilters.isClient = 1;
      } else if (event == "carto") {
        store.self.isOdw = 0;
      }
    }
  },

  // Récupère les informations de visibilité des filtres du MasterSelector
  initVisibilityFiltersParameters() {
    fetchApi("params", {}, "maps").then((response) => {
      store.dataDisplay.toggleFilters = response.data.find(
        (e) => e.Id == 1,
      ).Value;
      store.dataDisplay.groupements = response.data.find(
        (e) => e.Id == 3,
      ).Value;
      store.dataDisplay.gammes = response.data.find((e) => e.Id == 4).Value;
      store.dataDisplay.commerciaux = response.data.find(
        (e) => e.Id == 5,
      ).Value;
      store.dataDisplay.tranchesCa = response.data.find((e) => e.Id == 6).Value;
      store.dataDisplay.clients = response.data.find((e) => e.Id == 7).Value;
      store.dataDisplay.theme = response.data.find((e) => e.Id == 8).Value;
      store.dataDisplay.classification = response.data.find(
        (e) => e.Id == 9,
      ).Value;
      store.dataDisplay.financier = response.data.find((e) => e.Id == 10).Value;
      store.dataDisplay.projection = response.data.find(
        (e) => e.Id == 11,
      ).Value;
      store.dataDisplay.regionColoration = response.data.find(
        (e) => e.Id == 12,
      ).Value;
      store.dataDisplay.admin = response.data.find((e) => e.Id == 13).Value;
    });
  },

  initPeriodes(guid) {
    //Initialise les périodes
    if (!guid) guid = store.self.guid;
    return new Promise((resolve, reject) => {
      try {
        fetchApi("periodes?guid=" + guid, {}, "maps")
          .then((response) => {
            store.calculatorFilters.idPeriode = -1;
            store.perioFourni.periodes = []; //Remise à 0 des periodes

            store.perioFourni.annees = response.data
              .map((period) => ({
                year: parseInt(period.annee),
                id: period.periodId,
              }))
              .sort((a, b) => (a.year < b.year ? 1 : -1));
            store.calculatorFilters.selectedYear = Math.max(
              -1,
              ...store.perioFourni.annees.map((period) => period.year),
            );

            this.setPeriodesByYear(store.calculatorFilters.selectedYear).then(
              () => {
                resolve();
              },
            );
          })
          .catch(reject);
      } catch (e) {
        console.log("error with: ", e);
        console.log("error with: ", e.response);
        reject(e);
      }
    });
  },
  setPeriodesByYear(annee) {
    //Permet de mettre en place les différentes périodes lorsque l'utilisateur selectionne une année
    return new Promise((resolve, reject) => {
      try {
        fetchApi(
          "periodes?guid=" + store.self.guid + "&year=" + annee,
          {},
          "maps",
        )
          .then((response) => {
            let periodes = response.data.sort((a, b) => b.month - a.month);
            let res = [];
            let annuel = periodes.find((p) => p.month === 12);
            if (annuel) res.push({ label: annuel.label, id: annuel.periodId });

            let acomptes = periodes.filter((p) => p.month !== 12);
            if (acomptes.length > 0) {
              res.push({ label: "Acomptes", id: acomptes[0].periodId });
            }

            store.perioFourni.periodes = res;

            if (store.perioFourni.periodes.length > 0)
              store.calculatorFilters.idPeriode =
                store.perioFourni.periodes[0].id;
            else store.calculatorFilters.idPeriode = -1;
            resolve();
          })
          .catch(reject);
      } catch (e) {
        console.log("error with: ", e);
        console.log("error with: ", e.response);
        reject(e);
      }
    });
  },

  // Gestion de l'état du loader
  displayLoader(state) {
    store.dataDisplay.loader = store.dataDisplay.loader + (state ? 1 : -1); // Met à jour le compte des requêtes en cours
  },

  // Met à jour les logs
  updateLogs(response, scope) {
    if (store.self.isAdmin) {
      var r =
        "Code : " + response.status + " - Message : " + response.statusText;
      if (scope == "sdc") {
        store.logs.sdc = r;
      } else if (scope == "drone") {
        store.logs.drone = r;
      } else if (scope == "maps") {
        store.logs.maps = r;
      }

      var today = new Date();

      var l = {
        index: store.logs.history.length + 1,
        datetime:
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate() +
          " " +
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds(),
        status: response.status,
        statusText: response.statusText,
        method: response.config.method,
        timeout: response.config.timeout,
        action: response.config.url.replace(store.rootApi, ""),
        scope: scope,
      };

      store.logs.history.push(l);
    }
  },

  // Aide
  help() {
    store.tour.firstVisit = true;
    localStorage.firstVisit = true;
  },

  // Redirection si catch
  redirectToConnect() {
    //window.location.href = "http://cmem-mat.info";
  },

  // Affiche les informations relatives aux "i" dans l'interface
  displayInfo(info) {
    if (info == "pdm") {
      store.infos.messageInfo =
        "<h3>Part de marché</h3><b>Ma part de marché :</b> Mon CA / CA marché CMEM<br /><b>CA marché CMEM : </b>Mes concurrents + mon résultat";
    } else if (info == "tp") {
      store.infos.messageInfo =
        "<h3>Taux de pénétration</h3><b>Rappel scoring :</b> Mes PDV facturés / PDV facturés marché CMEM";
    } else if (info == "pm") {
      store.infos.messageInfo =
        "<h3>Panier moyen</h3><b>Panier moyen marché : </b>CA du marché / Somme des PDV des FRS du marché<br/><b>Panier moyen concurrents : </b>CA du marché hors mon CA / Somme des PV des FRS concurrents <br/><b>Mon panier moyen : </b>Mon CA / Somme des PDV clients chez moi <br/><b>Panier moyen PDV : </b>CA du marché / PDV réalisant du CA avec 1 ou N FRS";
    } else if (info == "deptCol") {
      store.infos.messageInfo =
        "<h3>Colonne Départements</h3><b>Attention: </b>Les départements qui ne sont pas sous forme de code, ne seront pas pris en compte lors des calculs";
    } else if (info == "messagePopupGroupement") {
      var img = store.rootApi + "markers/" + store.adherentsParam[9].Marker;
      var imgCircle =
        store.rootApi + "markers/" + store.adherentsParam[9].MarkerCircle;
      store.infos.messageInfo =
        ' <div class="grp"><h3><img width="30px" title="Marqueur d\'un point de vente"' +
        'alt = "Image d\' un point de vente" src="' +
        img +
        '" >Représente les points de vente</h3></div>' +
        ' <div class="grp"><h3><img width="30px" title="Marqueur d\'un point de vente"' +
        'alt = "Image d\' un point de vente" src="' +
        imgCircle +
        '" > Représente une accumulation de points de vente au même endroit (zoomez ou cliquez dessus pour les afficher) </h3></div>';
    } else if (info == "acomptes") {
      store.infos.messageInfo =
        "<h3>Calcul des acomptes</h3><b>Attention: </b>Les valeurs de CA ne sont pas impactées par les différents filtres sélectionnés. Pour plus de détails, merci de sélectionner la période voulu dans le sélecteur associé";
    } else if (info == "indicators") {
      store.infos.messageInfo =
        "<h3>Indicateurs</h3>Rouge: 0% - 25%<br />Orange: 25% - 50%<br />Jaune: 50% - 75%<br />Vert: 75% - 100%";
    }

    mutations.switchFilters("popupInformation");
  },
};
