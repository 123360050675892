<template>
    <div id="menuCdm">
        <div class="cdm-menu-title">
            <p>Espace CDM</p>
        </div>
        <div v-for="route in routes" :key="route.id" class="cdm-menu" :class="{ active: $route.path === route.path }">
            <router-link :to="{ path: route.path, query: $route.query }"><i :class="route.icone"></i><span>{{ route.title
            }}</span></router-link>
        </div>
        <div class="period-select">
            <h5>Période</h5>
            <select v-model="store.calculatorFilters.idPeriode" class="form-control">
                <option v-for="period of store.perioFourni.annees" :key="period.periodId" :value="period.periodId">
                    {{ period.label }} ({{ period.annee }})</option>
            </select>
        </div>
    </div>
</template>

<script>
import { fetchApi } from "@/utils";
import { store } from "../store/store";

export default {
    name: 'MenuCdm',
    data() {
        return {
            store,
            routes: [],
        }
    },
    mounted() {
        this.generateListRoutes();
        this.getPeriodes();
    },
    methods: {
        generateListRoutes() {
            this.routes.push(
                ...this.$router.options.routes.find(r => r.name == 'CDM')
                    .children
                    .map(route => ({ ...route, path: `/chefdemarche/${route.path}` }))
            );
            this.routes.push(this.$router.options.routes.find(r => r.name == 'Maps'));
        },
        getPeriodes() {
            this.store.perioFourni.annees = []

            fetchApi("periodes/cdm-dashboard").then(res => {
                res.data = res.data.map(p => ({ ...p, label: p.month != 12 ? "Acomptes" : p.label }));
                this.store.perioFourni.annees.push(...res.data);
                this.store.calculatorFilters.idPeriode = res.data.length > 2 ? res.data[2].periodId : -1;
            })

        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#menuCdm {
    display: inline-block;
    width: 250px;
    vertical-align: top;
    background-color: white;
}

.period-select {
    margin-top: 46px;
    text-align: center;
    padding: 10px;
}

.cdm-menu-title {
    padding: 10px;
    text-align: center;
    background: var(--primary);
}

.cdm-menu-title>p {
    font-size: 20px;
    font-weight: 100;
    padding: 0;
    padding-top: 8px;
    color: #FFFFFF;
}

.cdm-menu {
    padding: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    border-left: solid 5px #DEDEDE;
    transition-duration: 0.8s;
    margin-top: 4px;
}

.cdm-menu:hover,
.cdm-menu.active {
    border-left-color: var(--primary);
}

.cdm-menu>a {
    color: #404040;
    font-weight: 100;
    transition-duration: 0.8s;
}

.cdm-menu>a:hover,
.cdm-menu.active>a {
    color: var(--primary);
    text-decoration: none;
}

.cdm-menu>a>span {
    padding-left: 12px;
}</style>
