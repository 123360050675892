<template>
    <div class="app">
        <Loader></Loader>
        <Popup></Popup>
        <FournisseurPopup></FournisseurPopup>
        <PopupInformation></PopupInformation>
        <div class="inner" :class="{maps: !$route.path.startsWith('/chefdemarche'), cdm: $route.path.startsWith('/chefdemarche')}" v-if="!loadingLog && logged">
            <Sidebar v-if="!$route.path.startsWith('/chefdemarche')"></Sidebar>
            <div class="app-content">
                <HeaderMaster></HeaderMaster>
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    // import Vue from "vue";
    import { VueApp as Vue } from "@/main.js";
    import Loader from './components/Loader';
    import Popup from './components/Popup';
    import FournisseurPopup from './components/FournisseurPopup';
    import PopupInformation from './components/PopupInformation';
    import Sidebar from './components/Sidebar';
    import { store, mutations } from "./components/store/store";

    import jwt_decode from "jwt-decode";

    import HeaderMaster from './components/HeaderMaster';

    export default {
        name: 'App',
        components: {
            Popup,
            FournisseurPopup,
            PopupInformation,
            Loader,
            HeaderMaster,
            Sidebar,
        },
        data() {
            return {
                app: store,
                loadingLog: true,
                logged: false,
                reqPending: false,
                reqInterceptor: null
            }
        },
        methods: {
            auth() {
                const login = new URL(window.location.href).searchParams.get("login");
                const email = new URL(window.location.href).searchParams.get("email");
                const autologinHash = new URL(window.location.href).searchParams.get("autologinHash");
                this.reqPending = true;
                mutations.displayLoader(true);
                Vue.axios.get(`${store.rootApi}auth?login=${login}&email=${email}&autologinHash=${autologinHash}`)
                    .then((res) => {
                        const { data: token } = res;
                        let jsonUser = jwt_decode(token);
                        jsonUser = jsonUser["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"];
                        const user = JSON.parse(jsonUser);
                        // V�rifie si fournisseur
                        store.self.isSupplier = user.TiersType == "FOU" ? 1 : 0;
                        store.self.isAdmin = (user.IsAdmin && user.TiersType == "CME") ? 1 : 0;
                        store.self.guid = user.TiersSdcGuid;
                        store.self.name = user.UserName;
                        store.self.firstname = user.UserFirstname;
                        store.self.code = user.IdCompta;
                        store.self.nom = user.TiersLibelle;
                        store.self.isLnL = user.IsLnL;
                        store.self.isLS = user.IsLS;
                        store.self.accessOdw = user.AccesOdw3;
                        store.self.accessDor = user.AccesOdw4;
                        store.self.accessDonneesExternes = user.ProfilDonneesExternes;
                        store.self.token = token;
                        store.self.family = user.Family; 
                        store.self.depFilter = user.RestrictedDeps;
                        store.self.isDepFilter = user.RestrictedDeps && user.RestrictedDeps.length > 0;
                        store.self.GPS = JSON.parse(user.GPS);
                        this.logged = true;
                        if (store.self.isOdw && !store.self.accessOdw && !store.self.isAdmin) {
                            window.location.href = "/?login=" + login + "&email=" + email + "&autologinHash=" + autologinHash + "&odw=0";
                            //mutations.redirectToConnect();
                        }
                        //if (this.app.tour.firstVisit == true) {
                        //    this.$tours['tour-odw'].start();
                        //}
                        Vue.axios.defaults.headers.common.crossDomain = true;
                    }).catch((err) => {
                        if (err.response && err.response.status === 400)
                            mutations.redirectToConnect();
                    }).finally(() => {
                        this.loadingLog = false;
                        this.reqPending = false;
                        mutations.displayLoader(false);
                    })

            }
        },
        mounted() {
            this.auth();
        },
    }
</script>

<style>

    .app {
        height: 100vh;
        background-color: #F2F3F4;
    }

    .app .inner {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .app .inner.maps {
        display: grid;
        grid-template-columns: 80px 1fr;
    }

    .app .inner .app-content {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
    }

        .app .inner.maps .app-content {
            row-gap: 24px;
            padding: 12px;
        }
</style>
