<template>
    <div class="dashboard-container">
        <h1>Espace chef de marché</h1><br />
        <div class="row indicator-line mb-4">
            <div class="col-sm">
                <div class="tuile shadow">
                    <h2>Nombre ODW3 vendus</h2>
                    <span class="indicator">{{ tuiles.Vendu }}</span>
                </div>
            </div>
            <div class="col-sm">
                <div class="tuile shadow">
                    <h2>Nombre ODW3 validés</h2>
                    <span class="indicator">{{ tuiles.Valide }}</span>
                </div>
            </div>
            <div class="col-sm">
                <div class="tuile shadow">
                    <h2>Nombre ODW3 ouverts</h2>
                    <span class="indicator">{{ tuiles.Ouvert }}</span>
                </div>
            </div>
        </div>
        <div class="row details-line">
            <div class="col-sm">
                <div class="tuile shadow mb-4">
                    <h2 class="text-left mt-2 mb-2"><i class="fas fa-table"></i> Détail des indicateurs</h2>
                    <vue-good-table ref="table" :columns="columns" :rows="rows" :sort-options="{ enabled: true }"
                        @on-column-filter="columnFiltered" :pagination-options="{
                            enabled: true,
                            rowsPerPageLabel: 'Lignes par page',
                            ofLabel: 'sur',
                            nextLabel: 'Suivant',
                            prevLabel: 'Précédent',
                            allLabel: 'Toutes'
                        }">
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { nextTick } from "vue";
import { store } from "../store/store";
import { VueGoodTable } from "vue-good-table-next";
import 'vue-good-table-next/dist/vue-good-table-next.css';
import { fetchApi } from "@/utils";
// import { VueApp as Vue } from "@/main.js";
// https://xaksis.github.io/vue-good-table/guide/configuration/

export default {
    name: 'Dashboard',
    components: {
        VueGoodTable
    },
    data: function () {
        return {
            app: store,
            rows: [],
            filteredRows: []
        };
    },
    watch: {
        "app.calculatorFilters.idPeriode": function () {
            this.initDashboard();
        }
    },
    methods: {
        // Les filtres sur la table ont été modifiés
        columnFiltered() {
            nextTick(() => {
                const table = this.$refs.table;
                if (table && table.filteredRows && table.filteredRows.length > 0 && table.filteredRows[0] && table.filteredRows[0].children) {
                    this.filteredRows = table.filteredRows[0].children;
                }
            })
        },


        // Chargement de la page = infos sur les indicateurs
        initDashboard() {
            //fetchApi("cdm/dashboard/infos")
            //    .then(response => {
            //        this.tuiles = response.data;
            //    })

            fetchApi("cdm/dashboard/details", {
                periodId: this.app.calculatorFilters.idPeriode
            })
                .then(response => {
                    this.rows = response.data;
                    this.filteredRows = response.data;
                })
        }
    },
    computed: {
        columns() {
            const dupelessFirstNames = this.rows
                .map(row => row.CdmFirstName)
                .filter((firstname, index, arr) => index === arr.indexOf(firstname))
                .sort();
            const dupelessLastNames = this.rows
                .map(row => row.CdmLastName)
                .filter((lastname, index, arr) => index === arr.indexOf(lastname))
                .sort();

            return [
                {
                    label: "Prénom",
                    field: "CdmFirstName",
                    filterOptions: {
                        enabled: true,
                        placeholder: " ",
                        filterDropdownItems: dupelessFirstNames
                    }
                },
                {
                    label: "Nom",
                    field: "CdmLastName", filterOptions:
                    {
                        enabled: true,
                        placeholder: " ",
                        filterDropdownItems: dupelessLastNames
                    }
                },
                { label: "Fournisseur", field: "SupplierName", filterOptions: { enabled: true } },
                { label: "Code Compta", field: "SupplierCodeCompta", filterOptions: { enabled: true } },
                { label: "Code Intranet", field: "SupplierCodeIntra", filterOptions: { enabled: true } },
                { label: "Famille", field: "SupplierFamily", filterOptions: { enabled: true } },
                { label: "Sous-Famille", field: "SupplierSubFamily", filterOptions: { enabled: true } },
                {
                    label: "Vendu",
                    field: "Vendu",
                    filterOptions: {
                        enabled: true,
                        placeholder: " ",
                        filterValue: "Oui",
                        filterDropdownItems: ["Oui", "(Vide)"],
                        filterFn: (data, filterString) => filterString === "(Vide)" ? !data : data === filterString
                    }
                },
                {
                    label: "Validé",
                    field: "Valide",
                    filterOptions: {
                        enabled: true,
                        placeholder: " ",
                        filterDropdownItems: ["Oui", "(Vide)"],
                        filterFn: (data, filterString) => filterString === "(Vide)" ? !data : data === filterString
                    }
                },
                {
                    label: "Ouvert",
                    field: "Vu",
                    filterOptions: {
                        enabled: true,
                        placeholder: " ",
                        filterDropdownItems: ["Oui", "(Vide)"],
                        filterFn: (data, filterString) => filterString === "(Vide)" ? !data : data === filterString
                    }
                }
            ]
        },
        tuiles() {
            const res = {
                Vendu: this.filteredRows.reduce((acc, el) => (el.Vendu ? acc + 1 : acc), 0),
                Valide: this.filteredRows.reduce((acc, el) => (el.Valide ? acc + 1 : acc), 0),
                Ouvert: this.filteredRows.reduce((acc, el) => (el.Vu ? acc + 1 : acc), 0)
            }

            return res;
        },
        idPeriode() {
            return this.app.calculatorFilters.idPeriode;
        }
    },

    mounted: function () {
        this.initDashboard();
    }
}
</script>

<style scoped>
.dashboard-container {
    display: inline-block;
    width: calc(100% - 250px);
    margin: 0 auto;
    padding: 12px 42px;
    background-color: #F3F3F3;
    overflow-y: scroll;
}

h1 {
    color: var(--primary);
    text-align: center;
    font-size: 2.5rem;
    font-weight: 100;
}

.tuile {
    border-radius: 5px;
    background: #FFFFFF;
    padding: 5px;
}

.tuile>h2 {
    font-weight: 100;
    text-align: center;
    padding: 3px;
}

.tuile>span.indicator {
    display: block;
    text-align: center;
    font-size: 4rem;
}

.indicator-line>div:nth-of-type(1)>.tuile {
    border-bottom: solid 7px #c42525;
}

.indicator-line>div:nth-of-type(2)>.tuile {
    border-bottom: solid 7px #2b941b;
}

.indicator-line>div:nth-of-type(3)>.tuile {
    border-bottom: solid 7px #125d82;
}
</style>