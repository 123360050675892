<template>
    <div>
        <div class="header">
            <h2>Distribution</h2>
        </div>
        <div>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Nb de PDV total sur la sélection</th>
                        <th>Nb de PDV client</th>
                        <th>% de PDV client</th>
                        <th>Nb de PDV client chez concurrents</th>
                        <th>% de PDV client chez concurrents</th>
                        <th>Nb de PDV non client/client chez concurrents</th>
                        <th>% de PDV non client/client chez concurrents</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="results">
                        <td>{{results.NbPdvFiltered}}</td>
                        <td class="color-primary">{{results.PdvSelfFactures}}</td>
                        <td class="color-primary">{{formatPercent(results.PdvSelfFactures, results.NbPdvFiltered)}}</td>
                        <td>{{results.PdvExceptSelfFactures}}</td>
                        <td>{{formatPercent(results.PdvExceptSelfFactures, results.NbPdvFiltered)}}</td>
                        <td>{{results.PdvConcurrentOnlyFactures}}</td>
                        <td>{{formatPercent(results.PdvConcurrentOnlyFactures, results.NbPdvFiltered)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { store } from "../store/store";
    export default {
        name: "Distribution",
        data() {
            return {
                store
            }
        },
        computed: {
            results() {
                if (Object.keys(store.financier.results).length > 0)
                    return store.financier.results;
                return null;
            }
        },
        methods: {
            formatPercent(val, total) {
                let res = 0;
                if (total) {
                    res = val / total;
                }
                return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(res);
            }
        }
    }
</script>

<style scoped>
    thead th {
        text-align: center;
    }
    tbody td {
        text-align: center;
        font-size: 2em;
    }

    .color-primary {
        color: var(--primary);
    }
</style>